import React from "react";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import { darken } from "@material-ui/core/styles";
import CircularProgress from "@mui/material/CircularProgress";

const CustomActionButton = ({
  color,
  icon,
  onClick,
  buttonTitle,
  sx,
  variant,
  type,
  className,
  disabled,
  endIcon,
  showLoader,
}) => {
  const theme = useTheme();

  return (
    <Button
      color={color || "primary"}
      startIcon={icon || null}
      onClick={onClick}
      sx={{
        backgroundColor: theme?.palette?.primary?.main,
        "&:hover": {
          backgroundColor: darken(theme?.palette?.primary?.main, 0.3),
        },
        borderRadius: "10px",
        fontFamily: "inherit",
        textTransform: "none",
        fontSize: "9px",
        ...sx,
      }}
      variant={variant || "contained"}
      type={type || "button"}
      className={className || ""}
      disabled={disabled || false || showLoader}
      justifyContent="center"
    >
      {showLoader ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        <>
          <span style={{ marginRight: icon ? "8px" : "0" }}>{buttonTitle}</span>
          {endIcon && <span style={{ marginLeft: "5px" }}>{endIcon}</span>}
        </>
      )}
    </Button>
  );
};

CustomActionButton.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.element,
  onClick: PropTypes.func.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  sx: PropTypes.object,
  variant: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  endIcon: PropTypes.element,
  showLoader: PropTypes.bool,
};

CustomActionButton.defaultProps = {
  showLoader: false,
};

export default CustomActionButton;
