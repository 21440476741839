import React, { useContext, useState, useRef } from "react";
import { GripVertical, Upload } from 'lucide-react';
import "./prescriptionPrint.css";
import { Interweave } from 'interweave';
 
import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import QRCode from "qrcode.react";
import moment from "moment";
import Api from "../../utils/api";
import {
  getFormattedDate,
  DEFAULT_ERROR_CALLBACK,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_INFO_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
} from "../../utils/app-util";
import { NotificationManager } from "react-notifications";
import Routes from "../../routes/routes";
import Axios from "axios";
import AuthService from "../../utils/services/auth-service";
import Loader from "../../components/misc/loader";
import { format } from "date-fns";
import { Button } from "@material-ui/core";
 
const Pres = (resp) => {
  const [pres, setPres] = useState();
  const [vital, setvital] = useState({});
  const [coMorbidities, setcoMorbidities] = useState({});
  const [prescriptionImages, setPrescriptionImages] = useState(null);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const slug = searchParams.get("slug");
  const printingDate = searchParams.get("date");
  const printStatus = searchParams.get("print");
  const [isDefiningArea, setIsDefiningArea] = useState(true);
  const [contentArea, setContentArea] = useState({
  });
  const [pages, setPages] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [dragHandle, setDragHandle] = useState(null);
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const fileInputRef = useRef(null);
  const [letterhead, setLetterhead] = useState({
    url: pres?.doctor?.letterHead?.data
    ? `data:${pres.doctor.letterHead.contenttype};base64,` + pres.doctor.letterHead.data.data
    : undefined,
    width: 800,
    height: 1100
  });
  const qrRef = useRef(null);
  const [qrImage, setQrImage] = useState("");
  const history = useHistory();
  const [nextAppointmentData, setNextAppointment] = useState();
  const [getwebsite, setGetWebsite] = useState();
  const token = AuthService.getToken();
  const [isPresLoaded, setPresLoaded] = useState(false)
 
  useEffect(() => {
    if (slug) {
      if (resp?.resp?.status === true) {
          setPres(resp?.resp?.data);
          if (resp?.resp?.data?.prescription?.vitals) {
            setvital(resp?.resp?.data?.prescription?.vitals);
          }
          if (resp?.resp?.data?.data?.prescription?.coMorbidities) {
            setcoMorbidities(resp?.resp?.data?.prescription?.coMorbidities);
          }
          if (resp?.resp?.data?.prescription?.images) {
            setPrescriptionImages(resp?.resp?.data?.prescription?.diagrams);
          }
          if (resp?.resp?.data?.doctor?.contentArea) {
            const contentArray = resp?.resp?.data?.doctor?.contentArea;
            const contentObject = {
              top: contentArray[0],
              left: contentArray[1],
              width: contentArray[2],
              height: contentArray[3],
            };
            setContentArea(contentObject);
            setPresLoaded(true);
          } else {
            const contentObject = {
              top: 200,
              left: 100,
              width: 500,
              height: 700
            };
            setContentArea(contentObject);
            setPresLoaded(true);
          }
         
          const dat = new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(resp?.resp?.data?.prescription?.nextAppointmentDate);
          const vad = format(new Date(dat), "dd-MM-yyyy,  hh:mm a");
          if (resp?.resp?.data?.prescription?.nextAppointmentDate != null) {
            setNextAppointment(vad);
          } else {
            //setNextAppointment();
            // setNextAppointment(new Date(resp.data.data.prescription?.nextAppointmentDate).toLocaleString())
          }

          // onPrint();
        } else {
          SHOW_ERROR_NOTIFICATION(resp?.resp?.data?.message)
        }
      
    } else {
    }
  }, [resp]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getwebsite`).then((resp1) => {
      if (resp1) {
        setGetWebsite(resp1.data[0].website_url);
      }
    });
  }, [slug]);

  useEffect(() => {

    if (qrRef.current) {
      const canvas = qrRef.current.querySelector("canvas");
      if (canvas) {
        const dataURL = canvas.toDataURL("image/png");

        const imgHTML = `<img src="${dataURL}" alt="QR Code" />`;
        setQrImage(imgHTML);
      }
    }
  }, []);

  useEffect(() => {
    if (contentArea) {
      handleFinishDefining();
      setIsDefiningArea(false);
    }
  }, [isPresLoaded]);

  const onPrint = () => {
    window.print();
  };

  const handleFinishDefining = () => {
    const pageHeight = contentArea.height;
    const tempContainer = document.createElement("div");
    tempContainer.style.width = `${contentArea.width}px`;
    tempContainer.style.position = "absolute";
    tempContainer.style.visibility = "hidden";
    document.body.appendChild(tempContainer);

    const patientInfo = `
    <section class="patient-info">
        <div class="patient-info-container" style="display: flex; flex-direction: row; justify-content: space-between;">
          <div class="patient-info-text">
            <div class="patient-name-heading">
              <h5>
                <b>
                  <span> 
                    ${pres?.patient?.gender === "MALE" ? "Mr. " : ""}
                    ${pres?.patient?.gender === "FEMALE" ? "Ms. " : ""}
                    ${pres?.patient?.name} 
                  </span>
                  (${pres?.patient?.age ? `${pres?.patient?.age}/` : ""}<span class="text-capitalize">${pres?.patient?.gender === "MALE" ? "M" : ""}${pres?.patient?.gender === "FEMALE" ? "F" : ""}</span>)
                </b>
              </h5>
            </div>
            <div class="pit-container-1">
              <div class="pit-uhid">
                <b>UHID: </b>
                ${pres?.patient?.uhid}
              </div>
            </div>
            <div class="pit-address">
              <b>Address:</b> ${pres?.patient?.address}
            </div>
            <div class="pit-allergy">
              <b>Allergy:</b> ${pres?.patient?.allergies}
            </div>
          </div>
          <div class="prescription-qr">
          <div class="pit-phone">
                <b>Ph:</b> ${pres?.patient?.mobileNumber}
              </div>
              <div class="pit-date">
                <b>Date:</b> 
                ${printingDate === null
        ? moment(pres?.prescription?.date).format("Do, MMMM YYYY")
        : printingDate}
              </div>
            <div class="prescription-qr-image">
              ${qrImage}
            </div>
            <div class="prescription-qr-text">
            Scan QR to view this prescription
            </div>
            </div>
            </div>
        </section>
    <hr/>
    `

    const prescriptionInfo = `
      <div class="main-new">
      ${printStatus === "0" || printStatus === null ? `
        <section class="prescription-info">
          <div class="prescription-info-container">
            <div class="pic-text">
              ${pres?.prescription?.fieldList?.map((field) => {
      return `
                 ${
                    (field?.fieldType === "text" ||
                    field?.fieldType === "investigation" ||
                    field?.fieldType === "surgeryDone") &&
                    field?.fieldValue
                      ? `
                  <b>${field?.fieldName}:</b>
                  <div>${field?.fieldValue}</div>
                  `
                      : ""
                  }
                  ${field?.fieldType === "coMorbidities" && field?.fieldValue && Object.keys(field?.fieldValue)?.length > 0 ? `
                    <div>
                      <b style="font-size: 1.1em">Co Morbidities:</b> 
                      ${Object.keys(field?.fieldValue || {}).map((key, index, arr) => `
                        <span style="font-weight: 200">${key}</span> - ${field?.fieldValue[key]}
                        ${index !== arr.length - 1 ? ", " : ""}
                      `).join('')}
                    </div>
                  ` : ""}
                  ${field?.fieldType === "vitals" && field?.fieldValue && Object.keys(field?.fieldValue)?.length > 0 ? `
                    <div>
                      <b style="font-size: 1.1em">Vitals:</b> 
                      ${Object.keys(field?.fieldValue || {}).map((key, index, arr) => `
                        <span style="font-weight: 200">${key}</span> - ${field?.fieldValue[key]}
                        ${index !== arr.length - 1 ? ", " : ""}
                      `).join('')}
                    </div>
                  ` : ""}
                  ${field?.fieldType === "medications" && field?.fieldValue?.length > 0 ? `
                    <div>
                      <b>${field?.fieldName}</b> 
                      <ul>
                        ${field?.fieldValue.map((v) => `
                          <li>${v?.name} ${v?.dose} ${v?.frequency} x ${v?.num} ${v?.interval} (${v?.remarks})</li>
                        `).join('')}
                      </ul>
                    </div>
                  ` : ""}
                  ${field?.fieldType === "dietaryRestriction" && field?.fieldValue ? `
                    <div>
                      <b>${field.fieldName}</b>: 
                      <div>${field?.fieldValue ? field?.fieldValue : ""}</div>
                    </div>
                  ` : ""}
                `;
    }).join('')}
            </div>
            <div class="prescription-diagrams">
              ${pres?.prescription?.diagrams?.length > 0 ? `
                ${prescriptionImages?.map((im) => `
                  <div style="width: 100%">
                    <img class="prescription-diagrams-img" src="data:${im?.contenttype};base64,${im?.data?.data}" alt="diagram"/>
                  </div>
                `).join('')}
              ` : ''}
            </div>
            <br/>
            <small>
              ${pres?.prescription?.isFollowUpRequired || true
          ? `Follow Up After ${pres?.prescription?.followUp} As per Appointment`
          : ''}
              ${pres?.prescription?.isFollowUpRequired && nextAppointmentData
          ? ` on ${nextAppointmentData}`
          : ''}
            </small>
          </div>
        </section>
      ` : ''}

      <div class="doctor-undersigned">
        <br />
        ${ pres?.doctor?.showSignatureOnPrescription 
          ? `<img 
          src="${pres?.doctor?.signature?.content}" 
          alt="doctor-sign" 
          class="img-fluid bg-white" 
          style="object-fit: contain; height: 30px;" 
        />` : ""
        }
        <br />
        ${pres?.doctor?.name}
        ${pres?.doctor?.registrationNo ? `<br/>${pres?.doctor?.registrationNo}` : ""}
        <hr />
        ${pres?.doctor?.appointmentUrl ? `Book your next appointment at ${pres?.doctor?.appointmentUrl}<br/>` : ""}
        ${pres?.doctor?.email}
        ${pres?.doctor?.booking_number ? ` | ${pres?.doctor?.booking_number}` : ""}
      </div>
  </div>
    `;

    const splitContentIntoPages = (prescriptionInfo, patientInfo) => {
      const pages = [];
      tempContainer.innerHTML = "";
    
      const combinedContent = prescriptionInfo;
      const contentArray = combinedContent.split(/(<[^>]+>)/g);
    
      tempContainer.innerHTML = patientInfo;
    
      let currentPageContent = patientInfo;
    
      const addContentToPage = (content) => {
        tempContainer.innerHTML = currentPageContent + content;

        return tempContainer.scrollHeight > pageHeight;
      };
    
      const doesImageFit = (container, pageHeight) => {
        const images = container.querySelectorAll("img");
        for (let img of images) {
          const rect = img.getBoundingClientRect();
          if (rect.bottom > pageHeight) {
            return img;
          }
        }
        return null;
      };


      for (let i = 0; i < contentArray.length; i++) {
        const element = contentArray[i];

        if (addContentToPage(element)) {
          pages.push(currentPageContent);

          currentPageContent = patientInfo + element;
        } else {
          currentPageContent += element;
        }

        const overflowingImage = doesImageFit(tempContainer, pageHeight);
        if (overflowingImage) {
          const imgIndex = combinedContent.indexOf(overflowingImage.outerHTML);
          if (imgIndex !== -1 && imgIndex < i) {
            pages.push(currentPageContent);
            currentPageContent = patientInfo + contentArray.slice(i).join('');
          }
        }
      }

      if (currentPageContent) {
        pages.push(currentPageContent);
      }

      return pages;
    };
    


    const pages = splitContentIntoPages(prescriptionInfo, patientInfo);

    document.body.removeChild(tempContainer);
    setPages(pages.map((pageContent) => ({ content: pageContent })));
    setIsDefiningArea(false);
    if(isPresLoaded) {handleSaveContentArea()};
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const maxWidth = 800;
          const maxHeight = 1100;
          let width = img.width;
          let height = img.height;

          const ratio = Math.min(maxWidth / width, maxHeight / height);
          width = width * ratio;
          height = height * ratio;

          setLetterhead({
            url: img.src,
            width,
            height
          });
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDragStart = (handle) => (e) => {
    e.preventDefault();
    setIsDragging(true);
    setDragHandle(handle);
  };

  const handleDragMove = (e) => {
    if (!isDragging || !containerRef.current) return;

    const containerRect = containerRef.current.getBoundingClientRect();
    const x = e.clientX - containerRect.left;
    const y = e.clientY - containerRect.top;

    setContentArea(prev => {
      const newArea = { ...prev };

      switch (dragHandle) {
        case 'top':
          newArea.top = Math.min(prev.top + prev.height - 100, Math.max(0, y));
          newArea.height = prev.height + (prev.top - newArea.top);
          break;
        case 'bottom':
          newArea.height = Math.max(100, y - prev.top);
          break;
        case 'left':
          newArea.left = Math.min(prev.left + prev.width - 100, Math.max(0, x));
          newArea.width = prev.width + (prev.left - newArea.left);
          break;
        case 'right':
          newArea.width = Math.max(100, x - prev.left);
          break;
        default:
      }

      return newArea;
    });
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    setDragHandle(null);
  };

  const renderContent = (pageContent) => {
    return (
      <div className="content-container" style={{
        wordWrap: "break-word",
        whiteSpace: "normal",
      }}>
        <Interweave content={pageContent} />
      </div>
    );
  };

  const handleSaveContentArea = async () => {
    try {
      const formData = new FormData();
      formData.append("id", pres?.doctor.id);
      const contentAreaArray = [
        contentArea.top,
        contentArea.left,
        contentArea.width,
        contentArea.height
      ];
      contentAreaArray.forEach((value, index) => {
        formData.append(`contentArea`, value);
      });
      const response = await Axios.post(
        `${process.env.REACT_APP_API2_HOST}update_letter_heads`,
        formData
      );

      if (response && response?.data?.message === "Updated Successfully!") {
        
      } else {
        NotificationManager.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error("Error updating the content area:", error);
      NotificationManager.error("Failed to update the content area");
    }
  }

  return (
    <>
      <div ref={qrRef} style={{ display: "none" }}>
        <QRCode
          id="123456"
          value={`${getwebsite}/pres?id=${slug}`}
          size={80}
          width={"100%"}
          level={"H"}
          includeMargin={false}
        />
      </div>
      
        <div style={{ whiteSpace: "normal", position: "absolute", top: 0, left: 0, width: "100%", backgroundColor: "#F6F7FC" }}>
          {pres?.prescription?.images?.length > 0 ? (
            prescriptionImages?.map((im) => {
              return (
                <div style={{ width: "100%" }}>
                  <img
                    src={`data:${im?.contenttype};base64,${im?.data?.data}`}
                    alt="prescription-handwritten-images"
                    width="100%"
                  />
                </div>
              );
            })
          ) : (
            <>
              <div className="container-global">
                <div className="controls">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onPrint}
                    id="printPageButton"
                    style={{ margin: "0px" }}
                  >
                    Print
                  </Button>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="file-input"
                    id="letterhead-upload"
                  />
                  {isDefiningArea ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleFinishDefining}
                    className="finish-button"
                  >
                    Save Printable Area
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setIsDefiningArea(true);
                      setPages([]);
                    }}
                    className="edit-area-button"
                  >
                    Edit Area
                  </Button>
                )}
                </div>
                <div className="pages">
                  {isDefiningArea ? (
                    <div
                      ref={containerRef}
                      className="page"
                      style={{ width: letterhead.width, height: letterhead.height }}
                      onMouseMove={isDragging ? handleDragMove : undefined}
                      onMouseUp={handleDragEnd}
                      onMouseLeave={handleDragEnd}
                    >
                      <img src={pres?.doctor?.letterHead?.data
                            ? `data:${pres.doctor.letterHead.contenttype};base64,` + pres.doctor.letterHead.data.data
                            : undefined} alt="Letterhead" className="letterhead" />
                      <div
                        ref={contentRef}
                        className="content-area"
                        style={{
                          top: contentArea.top,
                          left: contentArea.left,
                          width: contentArea.width,
                          height: contentArea.height,
                          border: "2px dashed blue",
                        }}
                      >
                        <div
                          className="handle top"
                          onMouseDown={handleDragStart("top")}
                        >
                          <GripVertical className="handle-icon" />
                        </div>
                        <div
                          className="handle bottom"
                          onMouseDown={handleDragStart("bottom")}
                        >
                          <GripVertical className="handle-icon" />
                        </div>
                        <div
                          className="handle left"
                          onMouseDown={handleDragStart("left")}
                        >
                          <GripVertical className="handle-icon" />
                        </div>
                        <div
                          className="handle right"
                          onMouseDown={handleDragStart("right")}
                        >
                          <GripVertical className="handle-icon" />
                        </div>
                      </div>
                    </div>

                  ) : (
                    pages.map((page, pageIndex) => (
                      <div
                        key={pageIndex}
                        className="page"
                        style={{ width: letterhead.width, height: letterhead.height }}
                      >
                        <img src={
                          pres?.doctor?.letterHead?.data
                            ? `data:${pres.doctor.letterHead.contenttype};base64,` + pres.doctor.letterHead.data.data
                            : undefined
                        } alt="Letterhead" className="letterhead" />
                        <div
                          className="content-area"
                          style={{
                            top: contentArea.top,
                            left: contentArea.left,
                            width: contentArea.width,
                            height: contentArea.height,
                          }}
                        >
                          {renderContent(page.content)}
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </>
          )}
        </div>
    </>
  );
};

export default Pres;