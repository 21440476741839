import Api from "../api";
import { map } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import AppConstants from "../app-constants";
import store from "../../redux/store";
import { addPrimaryProfile, setTenant, userLogin, userLogout } from "../../redux/action/action";



const loginSubject = new BehaviorSubject(
  !!localStorage.getItem(AppConstants.AUTH_TOKEN_KEY)
);

const AuthService = {
  config: {},
  get isAuthenticated() {
    return loginSubject.value;
  },

  register(data) {
    // this.clearLocalStorage();
    return Api.post("auth/register", data);
  },

  login(data) {
    // this.clearLocalStorage();
    return Api.post("auth/doctor/login", data).pipe(
      map((response) => {
        this.setToken(response.data.data.token);
        this.setUser(response.data.data.data);
        // push logged-in user data to the store
        store.dispatch(userLogin(response?.data?.data?.data))
        store.dispatch(addPrimaryProfile(response?.data?.data?.data));
        loginSubject.next(true);
      })
    );
  },

  login2(data) {
    // this.clearLocalStorage();
    return Api.post("auth/doctor/signup", data).pipe(
      map((response) => {
        this.setToken(response.data.data.token);
        this.setUser(response.data.data.data);
        // push logged-in user data to the store
        store.dispatch(userLogin(response?.data?.data))
        store.dispatch(addPrimaryProfile(response?.data?.data));
        loginSubject.next(true);
      })
    );
  },
  

  verifyForgetPassword(data) {
    return Api.post("auth/patient/validate_otp", data).pipe(
      map((response) => response["data"])
    );
  },

  changePassword(data) {
    return Api.post("/change_user_password", data).pipe(
      map((response) => response["data"])
    );
  },

  fetchConfig() {
    return Api.get("/config").pipe(map((response) => response["data"]));
  },

  sendOtp(data) {
    return Api.post("auth/doctor/send-auth-otp", data).pipe(
      map((response) => response["data"])
    )
  },

  resetPassword(data) {
    return Api.post("auth/reset-password", data).pipe(
      map((response) => response["data"])
    );
  },

  validateToken() {
    return Api.post("auth/doctor/validate-token").pipe(
      map((response) => {
        this.setUser(response.data.data);
        // push logged-in user data to the store
        store.dispatch(userLogin(response?.data?.data));
        store.dispatch(addPrimaryProfile(response?.data?.data));
      })
    );
  },

  logout() {
    return Api.post("auth/doctor/logout").pipe(
      map((data) => {
        store.dispatch(userLogout());
        this.clearLocalStorage();
      })
    );
  },

  clearLocalStorage() {
    localStorage.removeItem(AppConstants.USER_KEY);
    localStorage.removeItem(AppConstants.AUTH_TOKEN_KEY);
    localStorage.removeItem('activeTabIndex');
    // localStorage.clear();
    loginSubject.next(false);

  },

  setActiveTab(tab) {
    localStorage.setItem('activeTabIndex', tab)
  },

  getActiveTab() {
    return Number(localStorage.getItem('activeTabIndex'))
  },

  setUser(user) {
    delete user?.bottomLetterHead;
    delete user?.profilePic;
    delete user?.topLetterHead;
    localStorage.setItem(AppConstants.USER_KEY, JSON.stringify(user));
  },

  getUser() {
    return JSON.parse(localStorage.getItem(AppConstants.USER_KEY));
  },
  
  setConfig(data) {
    localStorage.setItem(AppConstants.CONFIG_KEY, JSON.stringify(data));
  },

  getConfig() {
    return JSON.parse(localStorage.getItem(AppConstants.CONFIG_KEY));
  },

  setTenant(data) {
    delete data?.loginBgFrontEnd;
    delete data?.loginBgBackEnd;
    delete data?.banner;
    store.dispatch(setTenant(data));
    // localStorage.setItem(AppConstants.TENANT_KEY, JSON.stringify(data));
  },

  getTenant() {
    return store.getState()?.tenant;
    // return JSON.parse(localStorage.getItem(AppConstants.TENANT_KEY));
  },

  setToken(token) {
    localStorage.setItem(AppConstants.AUTH_TOKEN_KEY, token);
  },

  getToken() {
    return localStorage.getItem(AppConstants.AUTH_TOKEN_KEY);
  },

  hasToken() {
    return !!localStorage.getItem(AppConstants.AUTH_TOKEN_KEY);
  },
};

export default AuthService;
