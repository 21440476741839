import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

// Define styles to center the checkbox and align items
const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  label: {
    marginLeft: '8px', // Space between checkbox and label
    fontWeight: 'bold',
  },
});

const GridHeaderCheckbox = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [checked, setChecked] = useState(false);

  const onCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    props.api.forEachNode((node) => node.setSelected(isChecked));
  };

  useEffect(() => {
    const resetCheckbox = () => setChecked(false);
    props.api.addEventListener('paginationChanged', resetCheckbox);

    return () => {
      props.api.removeEventListener('paginationChanged', resetCheckbox);
    };
  }, [props.api]);

  return (
    <div className={classes.container}>
      <Checkbox
        checked={checked}
        onChange={onCheckboxChange}
        color={theme?.palette?.primary?.main}
        size="small"
      />
      <span className={classes.label}>{props.displayName}</span>
    </div>
  );
};

// Define PropTypes for better type checking
GridHeaderCheckbox.propTypes = {
  api: PropTypes.object.isRequired,
  displayName: PropTypes.string,
};

GridHeaderCheckbox.defaultProps = {
  displayName: '',
};

export default GridHeaderCheckbox;