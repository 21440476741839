import React, { useEffect } from "react";
import Header from "../../components/layout/header";
import { Form } from "informed";
import Api from "../../utils/api";
import { NotificationManager } from "react-notifications";
import AppConstants from "../../utils/app-constants";


// import ToggleButton from "@mui/lab/ToggleButton";
// import ToggleButtonGroup from "@mui/lab/ToggleButtonGroup";

import {
  Box,
  makeStyles,
  Grid,
  Typography,
  Button
} from "@material-ui/core";
import useGlobalStyles from "../../utils/global-style";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid red",
  },
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "5px",
  },

  prescriptionTitleHeading: {
    fontSize: "1rem",
    fontWeight: "900",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "10px",
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  style_2: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
    // padding: "1.8%",
    border: "1px solid #a4a0a0",
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
  select: {
    border: "1px solid black",
    padding: "10px",
    maxWidth: "200px",
    width: "100%",
    borderRadius: "10px",
  },
  colorContainer: {
    width: "80%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    paddingRight: "10%",
  },
  colorInput: {
    minWidth: "50px",
    height: "50px",
    width: "100%",
  },

}));

const ThemeSetting = (props) => {
  const { respo } = props;
  const classes = useStyles();
  const globalClass = useGlobalStyles();

  const [alignment, setAlignment] = React.useState("web");
  // const handleChange = (event, newAlignment) => {
  //   setAlignment(newAlignment);
  // };

  const [fontFamily, setFontFamily] = useState();
  const [fontFamilyBackend, setFontFamilyBackend] = useState();

  const [defaultTheme, setDefaultTheme] = useState();
  const [defaultThemeBackend, setDefaultThemeBackend] = useState();

  const [themeData, setThemeData] = useState({
    pMain: "",
    pMainText: "",
    pmainpContrast: "",
    pmainpContrastText: "",
    pHighlightButtonText: "",
    pHighlightButtonBackground: "",

    sMain: "",
    sMainText: "",
    smainpContrast: "",
    smainpContrastText: "",
    sHighlightButtonText: "",
    sHighlightButtonBackground: "",


    theme: "",
    id: "",
  });


  const [themeDataBackend, setThemeDataBackend] = useState({
    pMain: "",
    pMainText: "",
    pmainpContrast: "",
    pmainpContrastText: "",
    pHighlightButtonText: "",
    pHighlightButtonBackground: "",

    sMain: "",
    sMainText: "",
    smainpContrast: "",
    smainpContrastText: "",
    sHighlightButtonText: "",
    sHighlightButtonBackground: "",


    theme: "",
    id: "",
  });

  const handleChange = (event) => {
    setThemeData({ ...themeData, [event.target.name]: event.target.value });
  };

  const handleChangeBackend = (event) => {
    setThemeDataBackend({ ...themeDataBackend, [event.target.name]: event.target.value });
  };


  const fontfamilyArr = ["Serif", "Sans-serif", "Monospace", "Cursive", "Helvetica", "Arial", "Arial Black", "Verdana", "Tahoma", "Trebuchet", "Impact", "Gill Sans", "Times New Roman", "Georgia", "Palatino", "Baskerville", "Andalé Mono", "Courier", "Lucida", "Monaco", "Bradley Hand", "Brush Script MT", "Luminari", "Comic Sans MS"];



  useEffect(() => {
    setThemeData({
      pMain: respo?.theme_light_1,
      pMainText: respo?.theme_light_2,
      pmainpContrast: respo?.theme_light_3,
      pmainpContrastText: respo?.theme_light_4,
      pHighlightButtonText: respo?.theme_light_5,
      pHighlightButtonBackground: respo?.theme_light_6,

      sMain: respo?.theme_dark_1,
      sMainText: respo?.theme_dark_2,
      smainpContrast: respo?.theme_dark_3,
      smainpContrastText: respo?.theme_dark_4,
      sHighlightButtonText: respo?.theme_dark_5,
      sHighlightButtonBackground: respo?.theme_dark_6,


      fontfamily: [respo?.font_family, ...fontfamilyArr],
      theme: respo?.theme,
      id: respo?.id,
    });

    setThemeDataBackend({
      pMain: respo?.backend_theme_light_1,
      pMainText: respo?.backend_theme_light_2,
      pmainpContrast: respo?.backend_theme_light_3,
      pmainpContrastText: respo?.backend_theme_light_4,
      pHighlightButtonText: respo?.backend_theme_light_5,
      pHighlightButtonBackground: respo?.backend_theme_light_6,

      sMain: respo?.backend_theme_dark_1,
      sMainText: respo?.backend_theme_dark_2,
      smainpContrast: respo?.backend_theme_dark_3,
      smainpContrastText: respo?.backend_theme_dark_4,
      sHighlightButtonText: respo?.backend_theme_dark_5,
      sHighlightButtonBackground: respo?.backend_theme_dark_6,

      
    })

    setFontFamily(respo?.font_family);
    setDefaultTheme(respo?.theme);

    setFontFamilyBackend(respo?.backend_font_family)
    setDefaultThemeBackend(respo?.backend_theme);


  }, []);

  const handleChangeFont = (e) => {
    setFontFamily(e.target.value);
  };


  const handleChangeFontBackend = (e) => {
    setFontFamilyBackend(e.target.value);
  };

  const handleChangetheme = (e) => {
    setDefaultTheme(e.target.value)
  }

  const handleChangethemeBackend = (e) => {
    setDefaultThemeBackend(e.target.value)
  }



  const handleSubmit = () => {
    const req = {
      theme_light_1: themeData.pMain,
      theme_light_2: themeData.pMainText,
      theme_light_3: themeData.pmainpContrast,
      theme_light_4: themeData.pmainpContrastText,
      theme_light_5: themeData.pHighlightButtonText,
      theme_light_6: themeData.pHighlightButtonBackground,

      theme_dark_1: themeData.sMain,
      theme_dark_2: themeData.sMainText,
      theme_dark_3: themeData.smainpContrast,
      theme_dark_4: themeData.smainpContrastText,
      theme_dark_5: themeData.sHighlightButtonText,
      theme_dark_6: themeData.sHighlightButtonBackground,

      backend_theme_light_1: themeDataBackend.pMain,
      backend_theme_light_2: themeDataBackend.pMainText,
      backend_theme_light_3: themeDataBackend.pmainpContrast,
      backend_theme_light_4: themeDataBackend.pmainpContrastText,
      backend_theme_light_5: themeDataBackend.pHighlightButtonText,
      backend_theme_light_6: themeDataBackend.pHighlightButtonBackground,

      backend_theme_dark_1: themeDataBackend.sMain,
      backend_theme_dark_2: themeDataBackend.sMainText,
      backend_theme_dark_3: themeDataBackend.smainpContrast,
      backend_theme_dark_4: themeDataBackend.smainpContrastText,
      backend_theme_dark_5: themeDataBackend.sHighlightButtonText,
      backend_theme_dark_6: themeDataBackend.sHighlightButtonBackground,

      backend_font_family: fontFamilyBackend,
      backend_theme: defaultThemeBackend,

      theme: defaultTheme,
      font_family: fontFamily,

      type: "theme",
      id: themeData.id,
    };

    Api.post(
      `${process.env.REACT_APP_API2_HOST}/websiteupdate`,
      req
    ).subscribe((resp) => {
      if (resp) {
        NotificationManager.success(AppConstants.MSG.UPDATE_SUCCESS)
      }

    });
  };

  return (
    <>
      <Header title="Theme Setting" />

      <Box >



        <Form style={{ display: "flex", flexDirection: "column" }} onSubmit={handleSubmit}>


          <Typography variant="h2" className={classes.prescriptionTitleHeading} >
            Frontend Theme Settings
          </Typography>
          <Box>
            <div style={{ width: "100%", marginLeft: "2%", paddingBottom: "3%" }}>
              <Typography variant="h3" className={classes.prescriptionTitle}>
                Light theme
              </Typography>

              <Box className={classes.colorContainer}>
                <Box>
                  <Box mt="2rem">
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Main Color
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="pMain"
                        value={themeData.pMain}
                        onChange={handleChange}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeData.pMain}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                <Box>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Main Text Color
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="pMainText"
                        value={themeData.pMainText}
                        onChange={handleChange}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeData.pMainText}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                <Box>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Contrast Color
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="pmainpContrast"
                        value={themeData.pmainpContrast}
                        onChange={handleChange}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeData.pmainpContrast}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                <Box>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Contrast Text
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="pmainpContrastText"
                        value={themeData.pmainpContrastText}
                        onChange={handleChange}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeData.pmainpContrastText}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                <Box>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Highlight Button Text 
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="pHighlightButtonBackground"
                        name="pHighlightButtonBackground"
                        value={themeData.pHighlightButtonBackground}
                        onChange={handleChange}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeData.pHighlightButtonBackground}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                <Box>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Highlight Button Background
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="pHighlightButtonText"
                        name="pHighlightButtonText"
                        value={themeData.pHighlightButtonText}
                        onChange={handleChange}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeData.pHighlightButtonText}
                      </Typography>
                    </div>
                  </Grid>
                </Box>
              </Box>
            </div>

            <div style={{ width: "100%", marginLeft: "2%", paddingBottom: "3%" }}>
              <Typography variant="h3" className={classes.prescriptionTitle}>
                Dark theme
              </Typography>

              <Box className={classes.colorContainer}>
                <Box>
                  <Box mt="2rem">
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Main Color
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="sMain"
                        value={themeData.sMain}
                        onChange={handleChange}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeData.sMain}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                <Box>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Main Text Color
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="sMainText"
                        value={themeData.sMainText}
                        onChange={handleChange}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeData.sMainText}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                <Box>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Contrast Color
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="smainpContrast"
                        value={themeData.smainpContrast}
                        onChange={handleChange}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeData.smainpContrast}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                <Box>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Contrast Text
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="smainpContrastText"
                        name="smainpContrastText"
                        value={themeData.smainpContrastText}
                        onChange={handleChange}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeData.smainpContrastText}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                <Box>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Highlight Button Text
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="sHighlightButtonBackground"
                        name="sHighlightButtonBackground"
                        value={themeData.sHighlightButtonBackground}
                        onChange={handleChange}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeData.sHighlightButtonBackground}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                <Box>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Highlight Button Background
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="sHighlightButtonText"
                        value={themeData.sHighlightButtonText}
                        onChange={handleChange}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeData.sHighlightButtonText}
                      </Typography>
                    </div>
                  </Grid>
                </Box>
              </Box>
            </div>

            <Box className={classes.prescriptionSection}>
              <Box>
                <Box
                  mt="2rem"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6" className={classes.prescriptionTitle}>
                    Default Theme
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <select
                  className={classes.select}
                  name="defaulttheme"
                  onChange={handleChangetheme}
                  value={defaultTheme}
                >

                  <option value="primary">Light Theme</option>
                  <option value="secondary">Dark Theme</option>


                </select>

              </Box>


            </Box>

            <Box className={classes.prescriptionSection}>
              <Box>
                <Box
                  mt="2rem"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6" className={classes.prescriptionTitle}>
                    FontFamily
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <select
                  className={classes.select}
                  name=""
                  onChange={handleChangeFont}
                  value={fontFamily}
                >
                  {fontfamilyArr.map((fontItem) => (
                    <option value={fontItem}>{fontItem}</option>
                  ))}
                </select>

              </Box>




            </Box>
          </Box>


          <Box style={{ marginTop: "5%" }}>
            <Typography variant="h2" className={classes.prescriptionTitleHeading} style={{ marginTop: "5%" }} >
              Backend Theme Settings
            </Typography>

            <div style={{ width: "100%", marginLeft: "2%", paddingBottom: "3%" }}>
              <Typography variant="h3" className={classes.prescriptionTitle}>
                Light theme
              </Typography>

              <Box className={classes.colorContainer}>
                <Box>
                  <Box mt="2rem">
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Main Color
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="pMain"
                        value={themeDataBackend?.pMain}
                        onChange={handleChangeBackend}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeDataBackend?.pMain}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                <Box>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Main Text Color
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="pMainText"
                        value={themeDataBackend?.pMainText}
                        onChange={handleChangeBackend}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeDataBackend?.pMainText}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                <Box>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Contrast Color
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="pmainpContrast"
                        value={themeDataBackend?.pmainpContrast}
                        onChange={handleChangeBackend}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeDataBackend?.pmainpContrast}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                <Box>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Contrast Text
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="pmainpContrastText"
                        value={themeDataBackend?.pmainpContrastText}
                        onChange={handleChangeBackend}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeDataBackend?.pmainpContrastText}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                {/* <Box >
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Highlight Button Background
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="pHighlightButtonBackground"
                        value={themeDataBackend?.pHighlightButtonBackground}
                        onChange={handleChangeBackend}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeDataBackend?.pHighlightButtonBackground}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                <Box>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Highlight Button Text
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="pHighlightButtonText"
                        value={themeDataBackend?.pHighlightButtonText}
                        onChange={handleChangeBackend}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeDataBackend?.pHighlightButtonText}
                      </Typography>
                    </div>
                  </Grid>
                </Box> */}
              </Box>
            </div>

            <div style={{ width: "100%", marginLeft: "2%", paddingBottom: "3%" }}>
              <Typography variant="h3" className={classes.prescriptionTitle}>
                Dark theme
              </Typography>

              <Box className={classes.colorContainer}>
                <Box>
                  <Box mt="2rem">
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Main Color
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="sMain"
                        value={themeDataBackend?.sMain}
                        onChange={handleChangeBackend}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeDataBackend?.sMain}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                <Box>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Main Text Color
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="sMainText"
                        value={themeDataBackend?.sMainText}
                        onChange={handleChangeBackend}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeDataBackend?.sMainText}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                <Box>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Contrast Color
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="smainpContrast"
                        value={themeDataBackend?.smainpContrast}
                        onChange={handleChangeBackend}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeDataBackend?.smainpContrast}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                <Box>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Contrast Text
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="smainpContrastText"
                        value={themeDataBackend?.smainpContrastText}
                        onChange={handleChangeBackend}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeDataBackend?.smainpContrastText}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                {/* <Box>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Highlight Button Background
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="sHighlightButtonBackground"
                        value={themeDataBackend?.sHighlightButtonBackground}
                        onChange={handleChangeBackend}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeDataBackend?.sHighlightButtonBackground}
                      </Typography>
                    </div>
                  </Grid>
                </Box>

                <Box>
                  <Box
                    mt="2rem"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6" className={classes.prescriptionTitle}>
                      Highlight Button Text
                    </Typography>
                  </Box>
                  <Grid item xs={12} sm={6} md={3}>
                    <div
                      style={{ display: "flex", alignItems: "center", gap: "2%" }}
                    >
                      <input
                        type="color"
                        id="favcolor"
                        name="sHighlightButtonText"
                        value={themeDataBackend.sHighlightButtonText}
                        onChange={handleChangeBackend}
                        className={classes.colorInput}
                      />
                      <Typography
                        variant="h6"
                        className={classes.prescriptionTitle}
                      >
                        {themeDataBackend.sHighlightButtonText}
                      </Typography>
                    </div>
                  </Grid>
                </Box> */}
              </Box>
            </div>

            <Box className={classes.prescriptionSection}>
              <Box>
                <Box
                  mt="2rem"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6" className={classes.prescriptionTitle}>
                    Default Theme
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <select
                  className={classes.select}
                  name="defaulttheme"
                  onChange={handleChangethemeBackend}
                  value={defaultThemeBackend}
                >

                  <option value="primary">Light Theme</option>
                  <option value="secondary">Dark Theme</option>


                </select>

              </Box>


            </Box>

            <Box className={classes.prescriptionSection}>
              <Box>
                <Box
                  mt="2rem"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6" className={classes.prescriptionTitle}>
                    FontFamily
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <select
                  className={classes.select}
                  name=""
                  onChange={handleChangeFontBackend}
                  value={fontFamilyBackend}
                >
                  {fontfamilyArr.map((fontItem) => (
                    <option value={fontItem}>{fontItem}</option>
                  ))}
                </select>

              </Box>




            </Box>
          </Box>



        </Form>

        <Box mb={3} style={{ marginTop: "2%" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={globalClass.largeBtnContainer}
            onClick={handleSubmit}
          >
            SAVE
          </Button>
        </Box>

      </Box>

    </>
  );
};

export default ThemeSetting;
