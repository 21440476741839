import React from "react";
import {
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Form } from "informed";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Autocomplete from "../../components/misc/autocomplete";
import { TextInput } from "sixsprints-react-ui";
import { CustomDialogBox } from "../../components/common/CustomDialogBox";
import CustomActionButton from "../../components/common/CustomActionButton";

const AddActivityItemDialog = ({
  open,
  handleClose,
  classes,
  globalClass,
  searchList,
  handleClicktext,
  handletypetext,
  formbillingData,
  handlechangebillingdata,
  receiptDate,
  onReceiptDateSelected,
  defaultTime,
  onSelectPrintingTIme,
  handlefinalAmount,
  isLoading,
  onactivityFormClicked,
}) => {
  const dialogContent = (
    <Form>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="my-autocomplete"
              options={searchList}
              getOptionLabel={(option) => option?.name || ""}
              onChange={(event, selectedOption) =>
                handleClicktext(selectedOption)
              }
              freeSolo={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Activity Name"
                  variant="outlined"
                  className={classes.style_1}
                  sx={{ width: "100%" }}
                  inputProps={{
                    ...params.inputProps,
                    maxLength:120
                  }}
                />
              )}
              onInputChange={handletypetext}
              spanFullWidth={true}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <TextField
              className={classes.style_1}
              type="text"
              label="Provider Name"
              name="providerName"
              value={formbillingData.providerName}
              onChange={handlechangebillingdata}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.style_1}
              type="text"
              label="Remarks"
              name="remarks"
              value={formbillingData.remarks}
              onChange={handlechangebillingdata}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <DatePicker
              label=" Date (DD/MM/YYYY)"
              value={receiptDate}
              name="date"
              onChange={(newValue) => {
                const timestamp = newValue ? newValue.valueOf() : null;
                onReceiptDateSelected(timestamp);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              inputFormat="DD/MM/YYYY"
              className={globalClass.roundWhiteBg}
            />
          </Grid>
          <Grid item xs={6}>
            <TimePicker
              label=" Time"
              value={defaultTime}
              name="time"
              onChange={(newValue) => {
                onSelectPrintingTIme(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} error={false} variant="outlined" />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item xs={3}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Discount Type"
              name="discounttype"
              value={formbillingData.discounttype}
              onChange={handlefinalAmount}
              sx={{ width: "100%" }}
            >
              <MenuItem value="percentage">Percentage</MenuItem>
              <MenuItem value="fix">Fix</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={3}>
            <TextField
              className={classes.style_1}
              type="number"
              label="Price"
              name="price"
              value={formbillingData.price}
              onChange={handlefinalAmount}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className={classes.style_1}
              type="number"
              label="Discount"
              name="discount"
              value={formbillingData.discount}
              onChange={handlefinalAmount}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className={classes.style_1}
              type="number"
              label="Number"
              name="number"
              value={formbillingData.number}
              onChange={handlefinalAmount}
              fullWidth
            />
          </Grid>
        </Grid>
        <Typography variant="h5" align="right">
          Final Amount: {formbillingData.finalAmount}
        </Typography>
      </Box>
    </Form>
  );

  const dialogActions = (
    <CustomActionButton
      onClick={onactivityFormClicked}
      disabled={isLoading}
      color="primary"
      buttonTitle={isLoading ? "Loading" : "Submit"}
      icon={isLoading ? <CircularProgress size={20} /> : null}
    />
  );

  return (
    <CustomDialogBox
      open={open}
      handleDialogOnClose={handleClose}
      dialogTitle="Add Activity/Item"
      dialogActions={dialogActions}
      showDialogActions={true}
      dialogContentInlineStyle={{ padding: "24px" }}
    >
      {dialogContent}
    </CustomDialogBox>
  );
};

export default AddActivityItemDialog;
