import React from 'react';
import { CustomDialogBox } from '../../../components/common/CustomDialogBox';
import { Typography, Box, List, ListItem, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(2, 0),
  },
  medicineName: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    fontSize: '1.2rem',
  },
  medicineDetails: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  detailItem: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    fontSize: '1rem', // Increased font size
  },
}));

const MedicineListDialog = ({ open, handleClose, medicinesList }) => {
  const classes = useStyles();

  return (
    <CustomDialogBox
      open={open}
      handleDialogOnClose={handleClose}
      dialogTitle="Prescribed Medicines"
      showCloseButton={true}
      showDialogActions={false}
      dialogContentInlineStyle={{ padding: "0px 24px 24px" }}
    >
      <List>
        {medicinesList.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem className={classes.listItem}>
              <Typography variant="subtitle1" className={classes.medicineName}>
                {item.name}
              </Typography>
              <Box className={classes.medicineDetails}>
                <Typography variant="body1" className={classes.detailItem}>
                  Dose: {item.dose}
                </Typography>
                <Typography variant="body1" className={classes.detailItem}>
                  Frequency: {item.frequency}
                </Typography>
                <Typography variant="body1" className={classes.detailItem}>
                  Duration: {item.num} {item.interval}
                </Typography>
                {item.remarks && (
                  <Typography variant="body1" className={classes.detailItem}>
                    Remarks: {item.remarks}
                  </Typography>
                )}
              </Box>
            </ListItem>
            {index < medicinesList.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </CustomDialogBox>
  );
};

export default MedicineListDialog;