import React from "react";
import GenericGrid from "../../components/grid/generic-grid";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import MedicationColumn from "./medication-column";
import useGlobalStyles from "../../utils/global-style";

const Medications = () => {
  // set document title
  useDocumentTitle("Medication");

  const globalClass = useGlobalStyles();
  return (
    <div className={globalClass.addSpacing}>
      <GenericGrid
        hideToggleEditMode={false}
        baseUrl="medication"
        csvName="medication"
        addText={"Add Medication"}
        hideOnUpload={false}
        hideOnCopy={true}
        hideExport={false}
        columnDefGenerator={MedicationColumn}
        title="Medications"
      />
    </div>
  );
};

export default Medications;
