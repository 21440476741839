import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Close";
import {
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
  useGridApiRef,
} from "@mui/x-data-grid";
import { CustomDataGrid } from "../../components/grid/mui/CustomDataGrid";
import { CustomDialogBox } from "../../components/common/CustomDialogBox";
import { CustomSelect, MultiSelectWithCheckbox } from "../../components/common";
import { AccessLevelAPI } from "../../apis/AccessLevelAPI";
import { DoctorAPI } from "../../apis/DoctorAPI";
import { SHOW_SUCCESS_NOTIFICATION } from "../../utils/app-util";
import { useSelector } from "react-redux";

const ShareAccessDialogBody = ({
  doctorsList,
  allAccessLevelsList,
  handleDoctorSelect,
  handleAccessLevelSelect,
}) => {
  return (
    <div className="sa-dialog-body-wrapper">
      <Grid container direction="column" rowSpacing={1}>
        <Grid item>
          <Grid container direction="row" alignItems="center" columnGap={2}>
            <Grid item>
              <CustomSelect
                options={doctorsList}
                label={"Share With"}
                labelKey="name"
                onChange={handleDoctorSelect}
                valueKey="slug"
                idKey="slug"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <MultiSelectWithCheckbox
                optionsList={allAccessLevelsList}
                valueKey="accessLevel"
                onSelectionChange={handleAccessLevelSelect}
                labelText="Select Access Levels"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export const AccessSharingGrid = ({ rows, handleShareAccessDialogSubmit }) => {
  const primaryProfileInfo = useSelector(
    (state) => state.switchProfile?.primaryProfileInfo
  );
  // -------- local states --------------------
  const [showShareAccessModal, setShowAccessModal] = useState(false);
  const [allAccessLevels, setAllAccessLevels] = useState([]);
  const [doctorsList, setDoctorsList] = useState([]);
  const [selectedDoctorSlugInDialog, setSelectedDoctorSlugInDialog] =
    useState("");
  const [selectedAccessLevelsInDialog, setSelectedAccessLevelsInDialog] =
    useState([]);
  const [presentGridRows, setPresentGridRows] = useState(rows); // -> state which holds rows at any point of time in the grid

  // -------- side-effects --------------------
  useEffect(() => {
    DoctorAPI.getAllDoctors().then((data) => {
      // to remove the logged in doctor
      data = data?.filter((doctor) => doctor?.slug !== primaryProfileInfo?.slug);
      setDoctorsList(data);
    });

    AccessLevelAPI.getAllAccessLevels().then((data) => {
      setAllAccessLevels(data);
    });
  }, []);

  // -------- event-listeners -----------------
  const handleShareAccessDialogOnClose = () => {
    setShowAccessModal(false);
  };

  const handleShareAccessBtnOnClick = () => {
    setShowAccessModal(true);
  };

  const handleDoctorSelect = (val) => {
    setSelectedDoctorSlugInDialog(val);
  };

  const handleAccessLevelSelect = (val) => {
    setSelectedAccessLevelsInDialog(val);
  };

  const handleOnShareBtnSubmit = () => {
    handleShareAccessDialogSubmit(
      selectedDoctorSlugInDialog,
      selectedAccessLevelsInDialog
    );
    setShowAccessModal(false);
  };

  // callback to handle data sync b/w grid and share-access dialog
  const handleDataUpdateInGrid = (updatedRows) => {
    setPresentGridRows(updatedRows);
  };

  return (
    <div className="access-sharing-grid-wrapper">
      <FullFeaturedCrudGrid
        initialRows={rows}
        handleShareAccessBtnOnClick={handleShareAccessBtnOnClick}
        allAccessLevels={allAccessLevels}
        handleDataUpdateInGrid={handleDataUpdateInGrid}
      />
      <CustomDialogBox
        dialogTitle={"Share Access"}
        open={showShareAccessModal}
        handleDialogOnClose={handleShareAccessDialogOnClose}
        children={
          <ShareAccessDialogBody
            allAccessLevelsList={allAccessLevels}
            doctorsList={filterDoctorsWithSharedAccess(
              doctorsList,
              presentGridRows
            )}
            handleDoctorSelect={handleDoctorSelect}
            handleAccessLevelSelect={handleAccessLevelSelect}
          />
        }
        onConfirm={handleOnShareBtnSubmit}
        actionBtnText="Share"
      />
    </div>
  );
};

function FullFeaturedCrudGrid({
  initialRows,
  handleShareAccessBtnOnClick,
  allAccessLevels,
  handleDataUpdateInGrid,
}) {
  // ------------- local state ---------------
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const apiRef = useGridApiRef();

  // ------------ side effects --------------

  useEffect(() => {
    setRows(initialRows?.length === 0 ? [] : initialRows);
  }, [initialRows]);

  // to update list of doctors in share dialog
  useEffect(() => {
    handleDataUpdateInGrid(rows);
  }, [rows])

  // ---------- event handlers ------------------

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (doctorSlug) => () => {
    setRowModesModel({
      ...rowModesModel,
      [doctorSlug]: { mode: GridRowModes.Edit },
    });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (doctorSlug) => () => {
    AccessLevelAPI.deleteShareAccessForADoctor(doctorSlug).then((response) => {
      setRows(rows.filter((row) => row?.doctorSlug !== doctorSlug));
      SHOW_SUCCESS_NOTIFICATION(`Shared access deleted for ${doctorSlug}`);
    });
  };

  const handleCancelClick = (doctorSlug) => () => {
    apiRef.current.stopRowEditMode({
      id: doctorSlug,
      ignoreModifications: true,
    });
    setRowModesModel({
      ...rowModesModel,
      [doctorSlug]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    const editedRow = rows.find((row) => row.doctorSlug === doctorSlug);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.doctorSlug !== doctorSlug));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    // if all access levels are unchecked, delete that row rather than updating
    if (newRow?.accessLevels.length === 0) {
      AccessLevelAPI.deleteShareAccessForADoctor(newRow?.doctorSlug).then(
        (_) => {
          setRows(rows.filter((row) => row?.doctorSlug !== newRow?.doctorSlug));
          SHOW_SUCCESS_NOTIFICATION(
            `Shared access deleted for ${newRow?.doctorSlug}`
          );
        }
      );
      return updatedRow;
    } else {
      AccessLevelAPI.updateSharedAccessLevels(
        newRow?.doctorSlug,
        newRow?.accessLevels
      ).then((_) => {
        setRows(
          rows?.map((row) =>
            row?.doctorSlug === newRow?.doctorSlug ? updatedRow : row
          )
        );
        SHOW_SUCCESS_NOTIFICATION("Updated successfully");
      });
      return updatedRow;
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "lineNo",
      headerName: "#",
      width: 40,
      type: "number",
      renderCell: (params) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.doctorSlug) + 1,
    },
    {
      field: "doctorName",
      headerName: "Doctor Name",
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "doctorSpecialityName",
      headerName: "Department",
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
      valueGetter: (speciality) => {
        return speciality;
      },
    },
    {
      field: "accessLevels",
      headerName: "Access Level",
      display: "flex",
      type: "custom",
      flex: 1,
      editable: true,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderEditCell: (params) => {
        const onChange = (updatedValue) => {
          params.api.setEditCellValue({
            id: params.id,
            field: params.field,
            value: updatedValue,
          });
        };

        return (
          <MultiSelectWithCheckbox
            optionsList={allAccessLevels}
            defaultSelected={params?.row?.accessLevels}
            labelKey="accessLevels"
            valueKey="accessLevel"
            onSelectionChange={onChange}
          />
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      align: "center",
      headerAlign: "center",
      flex: 1,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon style={{ transform: "scale(1.2)" }} />}
              label="Save"
              sx={{
                color: "primary.main",
                mx: 0.5,
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon style={{ transform: "scale(1.2)" }} />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
              sx={{
                mx: 0.5,
              }}
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon style={{ transform: "scale(1.2)" }} />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
            sx={{
              mx: 0.5,
            }}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon style={{ transform: "scale(1.2)" }} />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
            sx={{
              mx: 0.5,
            }}
          />,
        ];
      },
    },
  ];

  return (
    <CustomDataGrid
      getRowId={(row) => row?.doctorSlug}
      rows={rows}
      columns={columns}
      showEditToolbar={true}
      toolBarButtonTitle={"Share Access"}
      handleToolBarButtonClick={handleShareAccessBtnOnClick}
      editMode="row"
      onRowModesModelChange={handleRowModesModelChange}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      rowModesModel={rowModesModel}
      apiRef={apiRef}
    />
  );
}

// ----------- utility functions -----------
const filterDoctorsWithSharedAccess = (doctorsList, sharedAccessRows) => {
  return doctorsList?.filter(
    (doctor) => !sharedAccessRows?.some((row) => row?.doctorSlug === doctor?.slug)
  );
};