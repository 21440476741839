import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  Checkbox,
  useMediaQuery,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import './chat.scss';

const ChatScreenBox = ({
  user,
  patient,
  messages,
  setResolvedStatus,
  questionsRemaining,
  setQuestionsRemaining,
  handleLoadMoreMessages,
  totalMessages,
  scrollToBottom,
}) => {
  const chatBoxRef = useRef(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [prevScrollHeight, setPrevScrollHeight] = useState(0);
  const [currentPatientSlug, setCurrentPatientSlug] = useState(patient?.slug);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [resolvedState, setResolvedState] = useState(() =>
    messages.reduce((acc, message) => {
      const key = JSON.stringify(message.dateCreated);
      acc[key] = message.resolved;
      return acc;
    }, {})
  );

  // Utility Functions
  const isFromUser = (item) => user?.slug === item?.fromUserSlug;

  const isImage = (fileType) => {
    return fileType.startsWith("image/");
  };

  const isPdf = (fileType) => {
    return fileType === "application/pdf";
  };

  // ============================================================================================================================
  // Scrolling logic for different scenarios
  useEffect(() => {
    if (patient?.slug !== currentPatientSlug) {
      setCurrentPatientSlug(patient?.slug);
      setIsInitialLoad(true);
      setPrevScrollHeight(0);
      const newResolvedState = messages.reduce((acc, message) => {
        const key = JSON.stringify(message.dateCreated);
        acc[key] = message.resolved;
        return acc;
      }, {});
      setResolvedState(newResolvedState);
    }
  }, [patient?.slug, messages]);

  useEffect(() => {
    if (isInitialLoad && messages.length > 0) {
      if (chatBoxRef?.current) {
        chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
      }
      setIsInitialLoad(false);
      const newResolvedState = messages.reduce((acc, message) => {
        const key = JSON.stringify(message.dateCreated);
        if (!(key in resolvedState)) {
          acc[key] = message.resolved;
        }
        return acc;
      }, {});
      setResolvedState((prevState) => ({
        ...prevState,
        ...newResolvedState,
      }));
    }
  }, [messages, isInitialLoad]);

  useEffect(() => {
    if (scrollToBottom && chatBoxRef?.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [scrollToBottom, messages]);

  useEffect(() => {
    if (!isInitialLoad && chatBoxRef.current && prevScrollHeight > 0) {
      const newScrollHeight = chatBoxRef.current.scrollHeight;
      const scrollDiff = newScrollHeight - prevScrollHeight;
      chatBoxRef.current.scrollTop = scrollDiff;
    }
    const newResolvedState = messages.reduce((acc, message) => {
      const key = JSON.stringify(message.dateCreated);
      if (!(key in resolvedState)) {
        acc[key] = message.resolved;
      }
      return acc;
    }, {});
    setResolvedState((prevState) => ({
      ...prevState,
      ...newResolvedState,
    }));
  }, [messages.length, isInitialLoad]);

  const handleScroll = async () => {
    if (
      chatBoxRef.current &&
      chatBoxRef.current.scrollTop === 0 &&
      messages.length < totalMessages
    ) {
      setPrevScrollHeight(chatBoxRef.current.scrollHeight);
      await handleLoadMoreMessages();
    }
  };

  // ============================================================================================================================
  // Checkbox logic to mark as resolved or unresolved
  const handleCheckboxChange = (event, message) => {
    const isChecked = event.target.checked;
    const key = JSON.stringify(message.dateCreated);
    const wasCheckedBefore = resolvedState[key] || false;
    if (isChecked && !wasCheckedBefore && questionsRemaining <= 0) {
      alert("You cannot resolve more questions than allowed.");
      return;
    }
    if (isChecked !== wasCheckedBefore) {
      setQuestionsRemaining((prev) =>
        isChecked ? Math.max(0, prev - 1) : prev + 1
      );
    }
    setResolvedState((prevState) => ({
      ...prevState,
      [key]: isChecked,
    }));

    setResolvedStatus((prevResolvedStatus) => {
      const existingIndex = prevResolvedStatus.findIndex(
        (msg) => JSON.stringify(msg.dateCreated) === key
      );

      if (existingIndex !== -1) {
        const updatedStatus = [...prevResolvedStatus];
        updatedStatus[existingIndex] = {
          ...prevResolvedStatus[existingIndex],
          resolved: isChecked,
        };
        return updatedStatus;
      } else {
        return [...prevResolvedStatus, { ...message, resolved: isChecked }];
      }
    });
  };

  return (
    <Box
      ref={chatBoxRef}
      onScroll={handleScroll}
      sx={{
        flex: 1,
        padding: 1,
        backgroundColor: "#ececed",
        overflowY: "auto",
        "&::-webkit-scrollbar": { width: "8px" },
        "&::-webkit-scrollbar-track": { backgroundColor: "#e9ecef" },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#092e54",
          borderRadius: "10px",
          "&:hover": { backgroundColor: "#4c4ccf" },
        },
      }}
    >
      {messages.map((item, index) => (
        <React.Fragment key={index}>
          {item?.type === "AUTOMATED" ? (
            !item.text.includes("You have exhausted all your questions.") &&
            item.text.includes(
              "Chat package top-up successful"
            ) ? null : item?.text.includes("Buy now") ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  backgroundColor: "rgba(186, 189, 192, 0.5)",
                  border: "1px solid #dfe0e1",
                  borderRadius: "8px",
                  marginX: { md: "80px", xs: "20px" },
                  marginY: "20px",
                  padding: "15px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  {item.text}
                </Typography>
              </Box>
            ) : null
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: isFromUser(item) ? "flex-end" : "flex-start",
                marginBottom: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isFromUser(item) ? "row-reverse" : "row",
                  gap: "5px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar
                  src={
                    isFromUser(item)
                      ? user.profilePicString
                      : patient?.patientDetails?.profilePicContentType
                      ? `data:${patient?.patientDetails?.profilePicContentType};base64,${patient?.patientDetails?.profilePicData}`
                      : ""
                  }
                  alt={patient?.name}
                  sx={{
                    width: { md: 48, xs: 35 },
                    height: { md: 48, xs: 35 },
                  }}
                />
                <Typography
                  className={`typography-container ${isFromUser(item) ? 'from-user' : 'from-other'}`}
                >
                  {!item.attachment && <span className="text-message">{item.text}</span>}
            
                  {item.fileType && isImage(item.fileType) && (
                    <Box className="image-message-wrapper">
                      <img className="image-message" 
                      src={item.attachment} 
                      alt="Attachment" 
                      />
                      <IconButton
                        className={`image-download-icon ${isFromUser(item) ? 'from-user' : 'from-other'}`}
                        component="a"
                         href={item.attachment}
                        download={`image-${index}.jpeg`}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </Box>
                  )}
            
                  {item.fileType && isPdf(item.fileType) && (
                    <Box className={`pdf-message-wrapper ${isFromUser(item) ? 'from-user' : 'from-other'}`}>
                    <IconButton
                      className={`pdf-download-icon ${isFromUser(item) ? 'from-user' : 'from-other'}`}
                        component="a"
                        href={item.attachment}
                        download={`document-${index}.pdf`}
                      >
                        <DownloadIcon />
                      </IconButton>
                      <Box>
                      <PictureAsPdfIcon className={`pdf-icon ${isFromUser(item) ? 'from-user' : 'from-other'}`}/>
                      <Typography className="pdf-text">
                        <span>{item.fileName}</span>
                      </Typography>
                      </Box>
                    </Box>
                  )}
                </Typography>
                    
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "left",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { md: "13px", xs: "12px" },
                      fontWeight: "700",             
                    }}
                  >
                    {dayjs(item?.dateCreated).format("HH:mm")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { md: "12px", xs: "11px" }
                    }}
                  >
                    {dayjs(item?.dateCreated).format("DD/MM/YY")}
                  </Typography>
                </Box>
                {!isFromUser(item) && (
                  <Checkbox
                    checked={
                      resolvedState[JSON.stringify(item.dateCreated)] || false
                    }
                    onChange={(event) => handleCheckboxChange(event, item)}
                  />
                )}
              </Box>
            </Box>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

ChatScreenBox.propTypes = {
  user: PropTypes.object,
  patient: PropTypes.object,
  messages: PropTypes.array,
  setResolvedStatus: PropTypes.func.isRequired,
  questionsRemaining: PropTypes.number,
  setQuestionsRemaining: PropTypes.func,
  handleLoadMoreMessages: PropTypes.func,
  totalMessages: PropTypes.func,
  scrollToBottom: PropTypes.func,
};

export default ChatScreenBox;
