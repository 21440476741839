import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { SHOW_ERROR_NOTIFICATION } from "../../utils/app-util";
import { CustomDialogBox } from "../common/CustomDialogBox";
import { useTheme } from '@material-ui/core/styles';
import CustomActionButton from "../common/CustomActionButton";

const DeleteReportConfirmationModal = ({ open, onClose, reportToDelete, onConfirm }) => {
  const theme = useTheme();

  const [inputValue, setInputValue] = useState('');

  const closeModal = () => {
    setInputValue("");
    onClose();
  }

  const handleConfirm = () => {
    if (inputValue !== reportToDelete?.reportName) {
      SHOW_ERROR_NOTIFICATION("File name does not match. Please type the correct file name to delete.");
      return;
    }

    onConfirm(reportToDelete?.slug);
    closeModal();
  };

  return (
    <CustomDialogBox
      open={open}
      dialogTitle="Confirm Delete"
      handleDialogOnClose={closeModal}
      onConfirm={handleConfirm}
      actionBtnText="Delete"
      showCloseButton={true}
      dialogActions={
        <CustomActionButton
          variant="contained"
          onClick={handleConfirm}
          disabled={inputValue !== reportToDelete?.reportName}
          buttonTitle="Delete"
        />
      }
    >
      <Typography sx={{ fontSize: 16 }}>
        Are you sure you want to delete this file?
      </Typography>
      <Typography sx={{ fontSize: 16 }}>
        File Name: <span style={{ fontWeight: "600" }}>{reportToDelete?.reportName}</span>
      </Typography>
      <TextField
        sx={{
            marginTop: "20px",
            '& .MuiOutlinedInput-root': {
                height: "40px",
            },
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                    borderColor: theme?.palette?.primary?.main,
                },
            },
            '& .MuiInputLabel-root': {
                color: theme.palette.text.secondary,
                '&.Mui-focused': {
                    color: theme?.palette?.primary?.main,
                },
            },
        }}
        label="Type file name to confirm"
        fullWidth
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        required
      />
    </CustomDialogBox>
  );
};

export default DeleteReportConfirmationModal;