import React, { useState, useRef, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import PlusIcon from "@material-ui/icons/Add";

import {
  Divider,
  FormControlLabel,
  Checkbox,
  DialogContent,
  Grid,
  Box,
  IconButton,
  Chip,
  TextField,
} from "@material-ui/core";
import { Form } from "informed";
import { TextInput } from "sixsprints-react-ui";
import useGlobalStyles from "../../../utils/global-style";
import TextItem from "../../../components/layout/text-item";
import {
  SHOW_ERROR_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
} from "../../../utils/app-util";
import Api from "../../../utils/api";
import AuthService from "../../../utils/services/auth-service";



const VitalsDialog = ({
  open = false,
  handleClose,
  selectedVitals,
  type,
  updateVitalsCallback,
}) => {
  const globalClass = useGlobalStyles();
  const formApiRef = useRef();
  const [showField, setShowField] = useState(false);

  const [vitalField , setVitalField] = useState("");

  const [vitals, setVitals] = useState([]);
  useEffect(() => {
    setVitals(selectedVitals);
  }, [selectedVitals, open]);

  const onAddBtnClicked = () => setShowField(true);

  const handleVitalCheck = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    const index = vitals && vitals.findIndex(
      ({ label = "" }) => label?.toUpperCase() === name?.toUpperCase()
    );

    if (index !== -1) {
      setVitals((prevArr) => {
        const tempArr = [...prevArr];
        const vital = { ...tempArr[index] };

        vital.checked = checked;

        tempArr[index] = vital;

        return tempArr;
      });
    }
  };

  const handleSetVitalField = (e)=>{
    setVitalField(e.target.value)
  }

  const addNewVital = ({ vitalField }) => {
  
    const label = vitalField;

    const alreadyExist = vitals && vitals.find((vital) => {
      return vital.label === label;
    });

    if (alreadyExist) {
      SHOW_ERROR_NOTIFICATION("vital name already exist!");
    } else {
      setVitals((prev) => [...prev, { label, val: "", checked: true }]);
      setVitalField("")
    }
  };

  
  const saveVitals = () => {
    if (type === "vitals") {
      Api.post("/hospital-vitals", { type: "VITAL", vitals }).subscribe(
        (resp) => {
          const { vitals: configVitals } = (resp.data || {}).data || {};

          const config = AuthService.getConfig();
          const tempConfig = { ...config };
          tempConfig.vitals = configVitals;          
          AuthService.setConfig(tempConfig);

          SHOW_SUCCESS_NOTIFICATION("vitals updated!");

          const updatedVitals = configVitals.map(({ label }) => {
            const existingVital = vitals.find((vital) => vital.label === label);

            return existingVital
              ? existingVital
              : {
                  label,
                  val: "",
                  checked: false,
                };
          });

          updateVitalsCallback(updatedVitals);
          handleClose();
        }
      );
    } else {
      Api.post("/hospital-vitals/co-morbidities", { vitals }).subscribe(
        (resp) => {
          const { vitals: configVitals } = (resp.data || {}).data || {};

          const config = AuthService.getConfig();
          const tempConfig = { ...config };
          tempConfig.coMorbidities = configVitals;
          AuthService.setConfig(tempConfig);

          SHOW_SUCCESS_NOTIFICATION("Vitals updated!");

          const updatedVitals = configVitals.map(({ label }) => {
            const existingVital = vitals.find((vital) => vital.label === label);

            return existingVital
              ? existingVital
              : {
                  label,
                  val: "",
                  checked: false,
                };
          });

          updateVitalsCallback(updatedVitals);
          handleClose();
        }
      );
    }
  };

  const handleDelete = (chipToDelete) => {
    setVitals((vitals) =>
      vitals.filter((vital) => vital.label !== chipToDelete.label)
    );
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Add More Fields"}</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={3}>
            {  vitals && vitals?.map((item, index) => {
              return (
                <Grid key={index} item xs={4}>
                  <Chip
                    variant="outlined"
                    label={
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.checked}
                            name={item.label}
                            onChange={handleVitalCheck}
                          />
                        }
                        label={item.label}
                      />
                    }
                    onDelete={() => handleDelete(item)}
                  />
                </Grid>
              );
            })}
          </Grid>
          {!showField && (
            <Box my="2rem" display="flex" justifyContent="space-between">
              <TextItem
                value="Add your custom field"
                labelVariant="h6"
                valueVariant="caption"
                label="Custom Field"
              />
              <IconButton onClick={onAddBtnClicked}>
                <PlusIcon />
              </IconButton>
            </Box>
          )}  
          {showField && (
            <Box my="2rem">
              <Form apiRef={formApiRef} onSubmit={addNewVital}>
                <div>Custom Field</div>

                <Box display="flex">
                <TextField
                    required
                    label="Label"
                    field="vitalField"
                    size="small"
                    onChange={handleSetVitalField}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    style={{ margin: "16px 0 8px 15px" }}
                    onClick={addNewVital}
                  >
                    Add
                  </Button>
                </Box>
              </Form>
            </Box>
          )}
          <Button
            className={globalClass.largeBtnContainer}
            type="submit"
            variant="contained"
            color="primary"
            onClick={saveVitals}
            disabled={vitals.length <= 0}
          >
            Save
          </Button>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>




    </div>
  );
};

export default VitalsDialog;
