import "./PrintComponent.scss";
export default function PrintComponent({ Header, Footer, Content, reference }) {
  return (
    <div className="print-wrapper" ref={reference}>
      <table>
        <thead>
          <tr>
            <td>
              <div className="header-space">&nbsp;</div>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <div className="content">
                <Content />
              </div>
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div className="footer-space">&nbsp;</div>
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="header">
        <Header />
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}
