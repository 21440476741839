import React, { useState } from "react";
import OtpInput from "react-otp-input";
import Loader from "../../../components/misc/loader";
import { useRecoilValue } from "recoil";
import {LoginState} from '../../../states'
import AuthService from '../../../utils/services/auth-service'
import { useHistory } from "react-router-dom";
import Routes from "../../../routes/routes";
import { DEFAULT_ERROR_CALLBACK } from "../../../utils/app-util";

const OtpPage = ({ setOpenOtpPage , mobileNumberforget }) => {
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidOTP, setIsInvalidOTP] = useState(false);
  
  const {mobileNumber, email, countryCode} = useRecoilValue(LoginState);

  const history = useHistory()

  const handleGoBack = () => {
    setOpenOtpPage(false);
  };

  const handleVerifyOTP = (event) => {
    event.preventDefault();
    const payload = {
      mobileNumber,
      email,
      countryCode,
      password: Number(otp)
    }
    setIsLoading(true);
    if (otp.length === 4) {
      AuthService.login2(payload).subscribe(
        () => {
          history.replace(Routes.MAIN);
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
      setIsLoading(false)
    } else {
      setIsInvalidOTP(true);
      setIsLoading(false)
    }
  };


  const handleVerifyOTPForget = (event) => {
    event.preventDefault();
    const payload = {
      mobileNumber : mobileNumber,
      password: Number(otp)
    }
    setIsLoading(true);
    if (otp.length === 4) {
      AuthService.verifyForgetPassword(payload).subscribe(
        () => {
          
          history.replace(Routes.MAIN);
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
      setIsLoading(false)
    } else {
      setIsInvalidOTP(true);
      setIsLoading(false)
    }
  };


  const handleChangeOtp = (enteredOtp) => {
    setOtp(enteredOtp);
    
    if (!/^\d*$/.test(enteredOtp)) {
      setIsInvalidOTP(true); 
    } else {
      setIsInvalidOTP(false); 
    }
  };
  
  return (
    <>
      <div className='login-page__login-box'>
        <img
          className='login-page__logo'
          src='https://storage.googleapis.com/eclinic_assets/tenants/jyotihospital/logo.png'
          alt=''
        />
      </div>
      <div className='login-page__login-box'>
        <div className='login-page__headline'>Log in to your account</div>
        <div className='login-page__text-content'>
          Enter the OTP you recieved mobile or email to login.
        </div>
      </div>
      <form
        className='login-page__login-box login-page__login-form'
        onSubmit={handleVerifyOTP}
      >
        <div className='login-page__form-row'>
          <label className='login-page__label' htmlFor=''>
            Enter OTP
          </label>
          <OtpInput
            value={otp}
            onChange={handleChangeOtp}
            inputStyle='login-page__otp'
            containerStyle='login-page__otp-container'
            isInputNum={true}
            shouldAutoFocus
            renderInput={(props) => <input {...props} />}
          />
          {isInvalidOTP && (
            <span className='login-page__validation-error'>
              Please enter a valid OTP
            </span>
          )}
        </div>
        <button
          className='login-page__login-btn login-page__form-row'
          type='submit'
        >
          {isLoading ? <Loader size={20} color={"#fff"} /> : "Submit"}
        </button>
        <div className='login-page__switch-btn' onClick={handleGoBack}>
          Back
        </div>
      </form>
    </>
  );
};
export default OtpPage;
