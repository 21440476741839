import React from "react";
import { asField } from "informed";
import TextField from "@material-ui/core/TextField";

export const TextInput = asField(({ fieldState, fieldApi, ...props }) => {
  const {
    onChange: _onChange,
    onBlur: _onBlur,
    onFocus: _onFocus,
    type,
    initialValue,
    dontUpdate,
    className,
    containerClassName,
    variant,
    format,
    label,
    ...rest
  } = props;

  const { error, value } = fieldState;
  const { setValue, setTouched } = fieldApi;

  return (
    <div className={containerClassName}>
      <div style={{ position: "relative", width: "100%" }}>
        <TextField
          className={className}
          ref={(refer) => {}}
          defaultValue={initialValue}
          variant={variant || "outlined"}
          label={label}
          helperText={error}
          value={value || ""}
          type={type}
          InputLabelProps={{
            style: { backgroundColor: 'white' }
          }}
          {...rest}
          onChange={(e) => {
            let val = e.target.value;
            if (format) {
              val = format(val);
            }
            if (_onChange) {
              _onChange(e, val);
            }
            if (!dontUpdate) {
              setValue(val);
            }
          }}
          onBlur={(e) => {
            setTouched();
            if (_onBlur) {
              _onBlur(e);
            }
          }}
          onFocus={(e) => {
            setTouched(false);
            if (_onFocus) {
              _onFocus(e);
            }
          }}
        />
      </div>
    </div>
  );
});
