import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Avatar,
  Typography,
  TextField,
  Fab,
  Divider,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import CenteredTypography from "../../components/common/CenteredTypography.jsx";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import { Refresh, HourglassTop } from "@mui/icons-material";
import { LoadingOverlay } from "../common/LoadingOverlay.jsx";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import PropTypes from "prop-types";
import ChatScreen from "./ChatScreen.jsx";
import Api from "../../utils/api";
import { NotificationManager } from "react-notifications";
import { SHOW_SUCCESS_NOTIFICATION } from "../../utils/app-util";
import { SocketEvents } from "../socket/socketEvents";
import { useDispatch } from 'react-redux';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const ChatListMobile = ({ socket, chatList, loading, handleRefreshClick }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showChatScreen, setShowChatScreen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState("");
  const [newChatUsers, setNewChatUsers] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [chatPackages, setChatPackages] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const unreadCount = chatList?.filter(
      chat => chat.latestMessageBy === "PATIENT" && chat.readStatus === "UNREAD"
    ).length || 0;
    
    dispatch({ type: 'SET_UNREAD_CHAT_COUNT', payload: unreadCount });
  }, [chatList, dispatch]);

  useEffect(() => {
    const payload = {
      filterModel: {},
      page: 0,
      size: 200,
      sortModel: [],
    };
    Api.post("chat-package/search", payload).subscribe((resp) => {
      setChatPackages(resp.data.data.content);
    });
  }, []);

  useEffect(() => {
    setFilteredPatients(
      (chatList || []).sort(
        (a, b) => new Date(b.dateModified) - new Date(a.dateModified)
      )
    );
  }, [chatList]);

  const handleSearch = useCallback(
    (event) => {
      const { value } = event.target;
      setSearchQuery(value);
      const existingUhids = new Set();

      const filtered = chatList?.filter((patient) => {
        const nameMatch = patient?.patientDetails?.name
          ?.toLowerCase()
          .includes(value.toLowerCase());
        if (patient?.patientDetails?.uhid) {
          existingUhids.add(patient?.patientDetails?.uhid);
        }
        return nameMatch;
      });

      setFilteredPatients(filtered || []);

      if (!value) {
        setNewChatUsers([]);
      } else {
        const payload = {
          page: 0,
          size: 20,
          filterModel: {
            _: {
              filterType: "text",
              type: "",
              filter: value,
            },
          },
          sortModel: [],
        };
        Api.post(`patient/search`, payload).subscribe((resp) => {
          const newUsers = resp.data.data.content;
          const pendingNewUsers = newUsers.filter(
            (user) => !existingUhids.has(user.uhid)
          );
          setNewChatUsers(pendingNewUsers);
        });
      }
    },
    [chatList]
  );

  const handleStartNewChat = useCallback(
    (patient) => {
      const freeChatPackage = chatPackages.find(
        (chatPackage) => chatPackage.amount === 0
      );

      if (freeChatPackage) {
        const payload = {
          patientSlug: patient.slug,
          chatPackageSlug: freeChatPackage.slug,
        };

        Api.post(
          `/order/initiate/chat-package/doctor?patientSlug=${patient.slug}&chatPackageSlug=${freeChatPackage.slug}`,
          payload
        ).subscribe(() => {
          SHOW_SUCCESS_NOTIFICATION(
            "Free Chat Package Enabled for New Patient"
          );

          const waitForChatListUpdate = new Promise((resolve) => {
            const checkForNewPatient = (updatedChatList) => {
              const newPatient = updatedChatList.find(
                (p) => p.patientDetails.uhid === patient.uhid
              );
              if (newPatient) {
                setSelectedPatient(newPatient);
                setShowChatScreen(true);
              }
              resolve();
            };

            socket.once(SocketEvents.POST_CHAT_LIST, checkForNewPatient);
          });

          setTimeout(async () => {
            await handleRefreshClick();
            await waitForChatListUpdate;
          }, 1000);
        });
      } else {
        NotificationManager.error("Please Create a Free chat Package");
      }
    },
    [chatPackages, handleRefreshClick, socket]
  );

  const handleChatClick = async (patientData) => {
    socket.emit(SocketEvents.UPDATE_MESSAGE_READ_STATUS, patientData?.slug);
    setShowChatScreen(true);
    setSelectedPatient(patientData);
    await handleRefreshClick();
  };

  return (
    <Box>
      <Box sx={{ display: "flex" }}></Box>
      {showChatScreen ? (
        <ChatScreen
          setShowChatScreen={setShowChatScreen}
          selectedPatient={selectedPatient}
          socket={socket}
          handleRefreshClick={handleRefreshClick}
        />
      ) : (
        <Box
          sx={{
            mb: 10,
            overflowY: "scroll",
            p: 0,
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              width: "100%",
              mt: 1,
            }}
          >
            <TextField
              label="Search Patient"
              value={searchQuery}
              onChange={handleSearch}
              variant="outlined"
              size="small"
              fullWidth
            />
            <Fab
              variant="circular"
              size="small"
              color="primary"
              onClick={handleRefreshClick}
              sx={{ p: 2 }}
            >
              {loading ? (
                <>
                  <LoadingOverlay />
                  <HourglassTop />
                </>
              ) : (
                <Tooltip title="Refresh">
                  <Refresh sx={{ fontSize: "20px" }} />
                </Tooltip>
              )}
            </Fab>
          </Box>
          {filteredPatients?.length > 0 && (
            <Box>
              <Typography variant="subtitle1" sx={{ p: 2 }}>
                Chat History
              </Typography>
              {filteredPatients?.map((patient, index) => (
                <Box key={index}>
                  <Box
                    sx={{
                      padding: "12px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => handleChatClick(patient)}
                  >
                    <Avatar
                      src={
                        patient?.patientDetails?.profilePicData
                          ? "data:image/jpeg;base64," +
                          patient?.patientDetails?.profilePicData
                          : undefined
                      }
                      alt={patient?.patientDetails?.name}
                      sx={{ width: 42, height: 42, marginRight: "12px" }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 500,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: {
                            xs: "200px",
                            sm: "100px",
                            md: "130px",
                          },
                        }}
                      >
                        {patient?.patientDetails?.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        noWrap
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "200px",
                        }}
                      >
                        {patient?.latestMessagePreview}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography variant="body2">
                        <Typography
                          sx={{
                            fontSize: { md: "13px", xs: "12px" },
                            fontWeight: "700",
                          }}
                        >
                          {dayjs(patient?.dateModified).format("HH:mm")}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { md: "12px", xs: "11px" }
                          }}
                        >
                          {dayjs(patient?.dateModified).format("DD/MM/YY")}
                        </Typography>
                      </Typography>
                      {patient?.latestMessageBy === "PATIENT" &&
                        patient?.readStatus === "UNREAD" ? (
                        <MarkChatUnreadIcon
                          sx={{
                            color: "#092e54",
                          }}
                        />
                      ) : null}
                    </Box>
                  </Box>
                  <Divider />
                </Box>
              ))}
            </Box>
          )}
          {newChatUsers.length > 0 && (
            <Box>
              <Typography variant="subtitle1" sx={{ p: 2 }}>
                New Chat
              </Typography>
              {newChatUsers.map((patient, index) => (
                <Box key={index}>
                  <Box
                    sx={{
                      padding: "12px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => handleStartNewChat(patient)}
                  >
                    <Avatar
                      src={
                        patient?.profilePicData
                          ? "data:image/jpeg;base64," + patient?.profilePicData
                          : undefined
                      }
                      alt={patient.name}
                      sx={{ width: 42, height: 42, marginRight: "12px" }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 500,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "150px",
                        }}
                      >
                        {patient?.name || patient?.mobileNumber}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        noWrap
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "200px",
                        }}
                      >
                        {patient?.uhid || patient?.slug}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                </Box>
              ))}
            </Box>
          )}

          {!filteredPatients?.length && !newChatUsers.length && (
            <CenteredTypography text="No Results Found" />
          )}
        </Box>
      )}
    </Box>
  );
};

ChatListMobile.propTypes = {
  socket: PropTypes.object.isRequired,
  chatList: PropTypes.array,
  loading: PropTypes.bool,
  handleRefreshClick: PropTypes.func,
};

export default ChatListMobile;
