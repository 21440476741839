import FilterOptions from "../../utils/filter-options";
import AuthService from "../../utils/services/auth-service";

class PendingColumn {
  constructor(isEditMode) {
    this.allColumns = [
      {
        field: "_",
        colId: "_",
        hide: true,
        lockVisible: true,
        filter: "agTextColumnFilter",
        filterParams: {
          newRowsAction: "keep",
        },
      },
    ].concat(
      allFields()?.map((field) => {
        return {
          field: field.name,
          colId: field.name,
          headerName: field.displayName,
          editable: isEditMode && !field.locked,
          sortable: true,
          ...paymentFilters.chooseAptFilter(
            field.dataType,
            null,
            field.name.indexOf("patient.") !== -1
          ),
          pinned: field.pinned ? "left" : "",
          width: field.pinned ? (field.dataType === "DATE" ? 160 : 120) : null,
          cellRendererSelector: function (params) {
            return {
              component: "customRenderer",
              params: {
                headerName: field.displayName,
                dataType: field.dataType,
                currentValue: params.value,
                context: params.context,
              },
            };
          },
          cellEditorSelector: function (params) {
            return {
              component: "customEditor",
              params: {
                dataType: field.dataType,
                currentValue: params.value,
                validation: field.validation,
                collectionName: field.collectionName,
                columnName: field.columnName,
                allValues: field.allValues,
              },
            };
          },
        };
      })
    );
  }

  generateColumns() {

    this.allColumns.splice(9, 1);
    this.allColumns.splice(13, 0, {
      field: "Bill_DETAILS",
      colId: "Bill_DETAILS",
      headerName: "View Details",
      editable: false,
      sortable: false,
      cellRendererSelector: function (params) {
        return {
          component: "customRenderer",
          params: {
            headerName: "View",
            dataType: "Bill_DETAILS",
            currentValue: params.value,
            context: params.context,
          },
        };
      },

    });

    this.allColumns.splice(10, 1, {
      field: "Bill_amount",
      colId: "Bill_amount",
      headerName: "Amount",
      editable: false,
      sortable: false,
      cellRendererSelector: function (params) {
        return {
          component: "customRenderer",
          params: {
            headerName: "Amount",
            dataType: "Bill_amount",
            currentValue: params.value,
            context: params.context,
          },
        };
      },

    });



    return this.allColumns;
  }
}

const allFields = () => {
  const config = AuthService.getConfig();
  return config["orderField"];
};

const paymentFilters = new FilterOptions({ collection: "order" });

export default PendingColumn;
