import OldPrescriptionPrint from "./OldPrescriptionPrint"
import NewPrescriptionPrint from "./precriptionPrint"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import CustomCircularLoader from "../../components/common/CustomCircularLoader";

import Api from "../../utils/api";

const PrescriptionPrintWrapper = () => {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const slug = searchParams.get("slug");
    const [respData, setRespData] = useState();

    useEffect(() => {
        if (slug) {
            Api.get(`appointment/print-new/${slug}`).subscribe((resp) => {
                if (resp?.data?.status === true) {
                    setRespData(resp);
                }
            });
        }
    }, [slug])

    if (respData?.data?.data?.doctor?.showLetterHeadOnPrescription) {
        return <NewPrescriptionPrint resp={respData?.data} />
    } else if (respData) {
        return <OldPrescriptionPrint />
    } else {
        <CustomCircularLoader />
    }
}

export default PrescriptionPrintWrapper;