import React, { useState, useEffect } from "react";
import { CustomDialogBox } from "../../components/common/CustomDialogBox";
import { Form } from "informed";
import { Box, Grid, CircularProgress, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CustomActionButton from "../../components/common/CustomActionButton";
import dayjs from "dayjs";

const AddReceivingDialog = ({
  open,
  handleClose,
  classes,
  globalClass,
  formbillingData_r,
  handlechangebillingdata_r,
  onReceiptDateSelected_r,
  defaultTime_r,
  onSelectPrintingTIme_r,
  isLoading,
  receivingFormClicked,
}) => {
  const [currentDate, setCurrentDate] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);

  useEffect(() => {
    if (open) {
      const now = dayjs();
      setCurrentDate(now);
      setCurrentTime(now);
      onReceiptDateSelected_r(now.valueOf());
      onSelectPrintingTIme_r(now);
    }
  }, [open, onReceiptDateSelected_r, onSelectPrintingTIme_r]);

  return (
    <CustomDialogBox
      open={open}
      handleDialogOnClose={handleClose}
      dialogTitle="Add Receiving"
      showDialogActions={true}
      dialogActions={
        <CustomActionButton
          onClick={receivingFormClicked}
          buttonTitle={isLoading ? "Submitting..." : "Submit"}
          disabled={isLoading}
          icon={isLoading ? <CircularProgress size={24} /> : null}
          sx={{ minWidth: "100px" }}
        />
      }
    >
      <Form>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Provider Name"
                name="providerName"
                value={formbillingData_r.providerName}
                onChange={handlechangebillingdata_r}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                label="Date (DD/MM/YYYY)"
                name="date"
                value={currentDate}
                onChange={(newValue) => {
                  setCurrentDate(newValue);
                  onReceiptDateSelected_r(newValue ? newValue.valueOf() : null);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" fullWidth />
                )}
                inputFormat="DD/MM/YYYY"
                className={globalClass.roundWhiteBg}
              />
            </Grid>
            <Grid item xs={6}>
              <TimePicker
                label="Time"
                value={currentTime}
                name="time"
                onChange={(newValue) => {
                  setCurrentTime(newValue);
                  onSelectPrintingTIme_r(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} error={false} variant="outlined" fullWidth />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                fullWidth
                type="number"
                label="Amount"
                name="finalAmount"
                value={formbillingData_r.finalAmount}
                onChange={handlechangebillingdata_r}
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                label="Remarks"
                name="remarks"
                value={formbillingData_r.remarks}
                onChange={handlechangebillingdata_r}
              />
            </Grid>
          </Grid>
        </Box>
      </Form>
    </CustomDialogBox>
  );
};

export default AddReceivingDialog;
