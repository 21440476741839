/**
 * Custom React hook to set the document title with a specific prefix.
 *
 * @param {string} suffix - The suffix to append current page name in the document title.
 *
 * This hook retrieves the tenant information using the AuthService and updates
 * the document title to include the tenant's name followed by the provided suffix.
 * It updates the title whenever the suffix changes.
 */
import { useEffect } from "react";
import AuthService from "../utils/services/auth-service";

const useDocumentTitle = (suffix) => {
  const tenant = AuthService.getTenant();
  useEffect(() => {
    document.title = `${tenant?.name} | ${suffix}`;
  }, [suffix]);
};

export default useDocumentTitle;