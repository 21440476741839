import { combineReducers } from "redux";
import inputList from "./inputList";
import inputFormData from "./forminput";
import inputFormSubmit from "./formData";
import { userReducer } from "./userReducer";
import { profileSwitchReducer } from "./profileSwitchReducer";
import { tenantReducer } from "./tenantReducer";
import chatNotificationReducer from "./chatNotificationReducer";

export default combineReducers({
  inputList,
  inputFormData,
  inputFormSubmit,
  user: userReducer,
  switchProfile: profileSwitchReducer,
  tenant: tenantReducer,
  chatNotification: chatNotificationReducer,
});
