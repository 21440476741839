import React from 'react';
import { Modal, Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 400,
        minWidth: 300,
        width: '100%',
        background: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        borderRadius: '10px',
    },
    button: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
}));

const DeleteJobModal = ({ open, handleClose, onDelete, id }) => {
    const classes = useStyles();

    const handleConfirmDelete = () => {
        onDelete(id);
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box className={classes.modalStyle}>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography variant="h6" component="h2">
                        Confirm Deletion
                    </Typography>
                    <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
                </Box>
                <Typography variant="body1" style={{ marginTop: '16px' }}>
                    Are you sure you want to delete this job?
                </Typography>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={handleConfirmDelete}
                    >
                        Yes, Delete
                    </Button>
                    <Button
                        variant="outlined"
                        className={classes.button}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default DeleteJobModal;