import React, { useContext, useState } from "react";
// import { SampleContext } from "../../contexts/SampleContext";
import "./pres.css";

import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import QRCode from "qrcode.react";
import moment from "moment";
import Api from "../../utils/api";
import {
  getFormattedDate,
  DEFAULT_ERROR_CALLBACK,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_INFO_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
} from "../../utils/app-util";
import Routes from "../../routes/routes";
import Axios from "axios";
import AuthService from "../../utils/services/auth-service";
import Loader from "../../components/misc/loader";
import { format } from "date-fns";

const OldPrescriptionPrint = () => {
  // const { back1, back2 } = useContext(SampleContext);
  const [pres, setPres] = useState();
  const [vital, setvital] = useState({});
  const [coMorbidities, setcoMorbidities] = useState({});
  const [prescriptionImages, setPrescriptionImages] = useState(null);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const slug = searchParams.get("slug");
  const printingDate = searchParams.get("date");
  const printStatus = searchParams.get("print");

  const history = useHistory();

  const onPrint = () => {
    window.print();
  };

  const [nextAppointmentData, setNextAppointment] = useState();

  useEffect(() => {
    if (slug?.length > 0) {
      Api.get(`appointment/print-new/${slug}`).subscribe((resp) => {
        if (resp.data.status === true) {
          setPres(resp.data.data);
          if (resp.data.data.prescription.vitals) {
            setvital(resp.data.data.prescription.vitals);
          }
          if (resp.data.data.prescription.coMorbidities) {
            setcoMorbidities(resp.data.data.prescription.coMorbidities);
          }
          if (resp.data.data.prescription.images) {
            setPrescriptionImages(resp.data.data.prescription.images);
          }
          const dat = new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }).format(resp.data.data.prescription?.nextAppointmentDate);
          const vad = format(new Date(dat), "dd-MM-yyyy,  hh:mm a");
          if (resp.data.data.prescription?.nextAppointmentDate != null) {
            setNextAppointment(vad);
          } else {
            //setNextAppointment();
            // setNextAppointment(new Date(resp.data.data.prescription?.nextAppointmentDate).toLocaleString())
          }

          // onPrint();
        } else {
          SHOW_ERROR_NOTIFICATION(resp.data.message)
          window.location.replace("/");
        }
      });
    } else {
      window.location.replace("/");
    }
  }, []);

  const [getwebsite, setGetWebsite] = useState();
  const token = AuthService.getToken();

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getwebsite`).then((resp1) => {
      if (resp1) {
        setGetWebsite(resp1.data[0].website_url);
      }
    });
  }, [slug]);

  return (
    <>
        
          {/* // style={back1}
          // style={{ position: "absolute", top: 0, left: 0, width: "100%", backgroundColor: "#F6F7FC", overflowX: "hidden" }} */}
          

        
          <div style={{ whiteSpace: "normal", position: "absolute", top: 0, left: 0, width: "100%",backgroundColor: "#F6F7FC" }}>
            {pres?.prescription?.images?.length > 0 ? (
              prescriptionImages?.map((im) => {
                return (
                  <div style={{ width: "100%" }}>
                    <img
                      src={`data:${im?.contenttype};base64,${im?.data?.data}`}
                      alt="image"
                      width="100%"
                    />
                  </div>
                );
              })
            ) : (
              
                
                  <table
                    className="report-container"
                    style={{
                      fontFamily: "Comfortaa, cursive",
                      fontSize: "9px",
                      width: "100%",
                    }}
                  >
                    <thead className="report-header">
                      <tr>
                        <th className="report-header-cell">
                          <div className="header-info">
                            <img
                              src={pres?.doctor?.topLetterHead?.content}
                              alt=""
                              style={{ width: "100%" }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tfoot className="report-footer">
                      <tr>
                        <td className="report-footer-cell">
                          <div className="footer-info footer_image">
                            <img
                              className=""
                              src={pres?.doctor?.bottomLetterHead?.content}
                              alt=""
                              style={{
                                position: "static",
                                bottom: "0px",
                                width: "100%",

                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tfoot>

                    <tbody className="report-content">
                      <tr>
                        <td className="report-content-cell">
                          <div className="main-newone">
                            <main class="overflow-hidden">
                              <section class="pt-4">
                                <div class="container">
                                  <div class="row">
                                    <div
                                      class="col-12"
                                      style={{ fontSize: "12px" }}
                                    >
                                      <div class="row">
                                        <div class="col-4">
                                          <b>
                                            <span style={{ fontSize: "14px " }}>
                                              {" "}
                                              {pres?.patient?.gender === "MALE"
                                                ? "Mr. "
                                                : ""}
                                              {pres?.patient?.gender === "FEMALE"
                                                ? "Ms. "
                                                : ""}
                                              {pres?.patient?.name}{" "}
                                            </span>{" "}
                                            ({pres?.patient?.age}/
                                            <span className="text-capitalize">
                                              {pres?.patient?.gender === "MALE"
                                                ? "M"
                                                : ""}
                                              {pres?.patient?.gender === "FEMALE"
                                                ? "F"
                                                : ""}
                                            </span>
                                            )
                                          </b>
                                        </div>
                                        <div class="col-4">
                                          <b>UHID: </b>
                                          {pres?.patient?.uhid}
                                        </div>

                                        <div class="col-4">
                                          <b>Ph:</b> {pres?.patient?.mobileNumber}
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-4">
                                          <b>Date:</b>{" "}
                                          {printingDate === null
                                            ? moment(
                                              pres?.prescription?.date
                                            ).format("Do, MMMM YYYY")
                                            : printingDate}
                                        </div>
                                        <div class="col-8">
                                          <b>Address:</b> {pres?.patient?.address}
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="">
                                          <b>Allergy:</b> {pres?.patient?.allergies}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                </div>
                              </section>

                              {printStatus === "0" || printStatus === null ? (
                                <>
                                  <section class="code">
                                    <div class="container" >
                                      <div class="row">
                                        <div className="col-8">
                                          {pres?.prescription?.fieldList.map(
                                            (field) => {
                                              return (
                                                <div >
                                                  {(field?.fieldType == "text" || field?.fieldType == "investigation" || field?.fieldType == "surgeryDone") &&
                                                    field?.fieldValue != null && field?.fieldValue && (
                                                      <>

                                                        <b>{field?.fieldName}:</b>{" "}
                                                        <div dangerouslySetInnerHTML={{ __html: field?.fieldValue }} />

                                                        <br />
                                                        <br />

                                                      </>
                                                    )}


                                                  {field?.fieldType ==
                                                    "coMorbidities" &&
                                                    field?.fieldValue && Object.keys(field?.fieldValue)?.length > 0 && (
                                                      <>
                                                        {<b>Co Morbidities:</b>}{" "}
                                                        &nbsp;&nbsp;
                                                        {Object.keys(
                                                          field?.fieldValue
                                                        )?.map((key, index) => {
                                                          return (
                                                            <>
                                                              ● <b>{key}</b> :{" "}
                                                              {
                                                                field?.fieldValue[
                                                                key
                                                                ]
                                                              }{" "}
                                                              &nbsp;
                                                            </>
                                                          );
                                                        })}
                                                        <br />
                                                        <br />
                                                      </>
                                                    )}

                                                  {field?.fieldType ==
                                                    "vitals" && field?.fieldValue && Object.keys(field?.fieldValue)?.length > 0 && (
                                                      <>
                                                        {<b>Vitals:</b>}{" "}
                                                        &nbsp;&nbsp;
                                                        {Object.keys(
                                                          field?.fieldValue
                                                        )?.map((key, index) => {
                                                          return (
                                                            <>
                                                              ● <b>{key}</b> :{" "}
                                                              {
                                                                field?.fieldValue[
                                                                key
                                                                ]
                                                              }{" "}
                                                              &nbsp;
                                                            </>
                                                          );
                                                        })}
                                                        <br />
                                                        <br />
                                                      </>
                                                    )}

                                                  {field?.fieldType ==
                                                    "medications" && field?.fieldValue?.length >
                                                    0 && (
                                                      <>
                                                        <b>
                                                          {field?.fieldName}
                                                        </b>{" "}
                                                        <ul>
                                                          {field?.fieldValue.map(
                                                            (v, i) => {
                                                              return (
                                                                <>
                                                                  <li>
                                                                    {v?.name}{" "}
                                                                    {v?.dose}{" "}
                                                                    {v?.frequency} x{" "}
                                                                    {v?.num}{" "}
                                                                    {v?.interval} (
                                                                    {v?.remarks})
                                                                  </li>
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </ul>

                                                      </>
                                                    )}


                                                  {field?.fieldType ==
                                                    "dietaryRestriction" && field?.fieldValue && (

                                                      <>
                                                        <b>{field.fieldName}</b>{" "}
                                                        {field?.fieldValue && (
                                                          <ul >
                                                            <li dangerouslySetInnerHTML={{ __html: field?.fieldValue }} />
                                                          </ul>
                                                        )}{" "}
                                                        <br />
                                                      </>
                                                    )}

                                                </div>
                                              );
                                            }
                                          )}

                                          <small>
                                            {pres?.prescription
                                              ?.isFollowUpRequired &&
                                              `Follow Up After ${pres?.prescription?.followUp} As per Appointment`}
                                            {(pres?.prescription
                                              ?.isFollowUpRequired && nextAppointmentData) &&
                                              ` on ${nextAppointmentData}`}
                                            { }
                                          </small>
                                        </div>
                                        <div class="col-4 text-center">
                                          <small>
                                            {" "}
                                            Scan QR to view
                                            <br />
                                            this prescription
                                          </small>
                                          <br />
                                          <br />
                                          <QRCode
                                            id="123456"
                                            value={`${getwebsite}/pres?id=${slug}`}
                                            size={200}
                                            width={"100%"}
                                            level={"H"}
                                            includeMargin={false}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </section>

                                  <section>
                                    <div class="container mt-4">
                                      <div class="row">
                                        <div class="col-8"></div>
                                      </div>
                                    </div>
                                  </section>
                                  <div class="container my-2">
                                    <div class="row">
                                      <div class="col-12">
                                        <br />
                                        <img
                                          src={pres?.doctor?.signature?.content}
                                          className="img-fluid bg-white"
                                          style={{
                                            objectFit: "contain",
                                            height: "30px",
                                          }}
                                        />
                                        <br />
                                        {pres?.doctor?.name}
                                        <br />
                                        {pres?.doctor?.registrationNo}
                                        <hr />
                                        Book your next appointment at{" "}
                                        {pres?.doctor?.appointmentUrl?.length > 0
                                          ? `${pres?.doctor?.appointmentUrl}`
                                          : null}
                                        <br />
                                        {pres?.doctor?.email} |{" "}
                                        {pres?.doctor?.booking_number}
                                      </div>
                                      <div class="col-7"></div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </main>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                
              
            )}

            <div class="mt-2" style={{ textAlign: "center" }}>
              <button
                class="btn btn-primary"
                onClick={onPrint}
                id="printPageButton"
              >
                Print
              </button>
            </div>
          </div>
    </>
  );
};

export default OldPrescriptionPrint;