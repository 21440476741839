import React, { useState, useEffect, createContext } from "react";
import { Tab, makeStyles } from "@material-ui/core";
import { Box } from "@material-ui/core";
import TabContext from "@mui/lab/TabContext";
import { TabList } from "@mui/lab";
import TabPanel from "@mui/lab/TabPanel";
import Sidesetting from "../website/website";
import Aboutus from "../aboutus/aboutus";
import Specialists from "../specialist/specialist";
import AppoinmentTypeSetting from "../appointment_typeSetting/appoinmentTypeSetting";
import SeoSetting from "../seo-setting/seoSetting";
import ThemeSetting from "../theme-setting/themeSetting";
import Axios from "axios";
import Loader from '../../components/misc/loader';
import Other from "../other/other";
import Sms from "../sms/sms";
import PaymentSetting from "../paymentSetting/Payment";
import Email from "../email/email";
import Rooms from "../rooms/rooms";
import IpdStatus from "../ipdStatus/ipdStatus";
import QueueForm from "./waiting-screen/QueueForm";
import useDocumentTitle from "../../hooks/useDocumentTitle";


const useStyles = makeStyles((theme) => ({
  tab: {
    fontSize: "0.9rem",
    textTransform: "none"
  },
}));

const Settings = () => {
  // set document title
  useDocumentTitle("Settings");

  const UserContext = createContext()
  const classes = useStyles();
  const [value, setValue] = React.useState("1");
  const handleChange = (event = React.SyntheticEvent, newValue = Number) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [resp, setResp] = useState(null);

  const [loading, setLoading] = useState(false);


  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}/getwebsite`).then((resp) => {
      if (resp) {
        setResp(resp.data[0]);
        setLoading(true)
      }
    });
  },
    []);

  const [respe, setRespe] = useState(null)

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}get_email_settings?id=6347b1284028b200c6af7b51`).then((respe) => {
      if (respe) {
        setRespe(respe.data.mail_settings
        );
        setLoading(true)

      }
    });
  },
    []);

  const [respon, setRespon] = useState(null)

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}get_sms_settings?id=6347b1284028b200c6af7b51`).then((respon) => {
      if (respon) {
        setRespon(respon.data.sms_settings);
        setLoading(true)
      }
    });
  },
    []);

  const [response, setResponse] = useState(null)

  // useEffect(() => {
  //   Axios.get(`${process.env.REACT_APP_API2_HOST}get_que`).then((response) => {
  //     if (response) {
  //       setResponse(response.data.data.que);
  //       setLoading(true)
  //     }
  //   });
  // }, []);

  const [resp1, setResp1] = useState(null)
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}get_other_settings`).then((resp1) => {
      if (resp1) {
        setResp1(resp1.data.data.other_settings);
        setLoading(true)
      }

    });
  }, []);


  return (
    <>
      <UserContext.Provider value={resp}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  className={classes.tab}
                  label="Website Setting"
                  value="1"
                  {...a11yProps(0)}
                />
                <Tab
                  className={classes.tab}
                  label="SEO Setting"
                  value="2"
                  {...a11yProps(1)}
                />
                <Tab
                  className={classes.tab}
                  label="Theme"
                  value="3"
                  {...a11yProps(2)}
                />
                <Tab
                  className={classes.tab}
                  label="About Us"
                  value="4"
                  {...a11yProps(3)}
                />
                <Tab
                className={classes.tab}
                label="Notifications"
                value="17"
                {...a11yProps(17)}
                />
                <Tab
                  className={classes.tab}
                  label="Departments" s
                  value="5"
                  {...a11yProps(4)}
                />
                <Tab
                  className={classes.tab}
                  label="Rooms"
                  value="15"
                  {...a11yProps(14)}
                />
                <Tab
                  className={classes.tab}
                  label="IPD"
                  value="16"
                  {...a11yProps(15)}
                />
                <Tab
                  className={classes.tab}
                  label="Appointment Type"
                  value="6"
                  {...a11yProps(5)}
                />
                <Tab
                  className={classes.tab}
                  label="Email"
                  value="8"
                  {...a11yProps(6)}
                />
                <Tab
                  className={classes.tab}
                  label="Waiting Screen"
                  value="9"
                  {...a11yProps(6)}
                />
                <Tab
                  className={classes.tab}
                  label="SMS"
                  value="10"
                  {...a11yProps(6)}
                />
                 <Tab
                  className={classes.tab}
                  label="Payment Gateway"
                  value="11"
                  {...a11yProps(6)}
                />
                {/* <Tab
                className={classes.tab}
                label="Terms Condition"
                value="11"
                {...a11yProps(6)}
              />
              <Tab
                className={classes.tab}
                label="Privacy Policy"
                value="12"
                {...a11yProps(6)}
              /> */}
                <Tab
                  className={classes.tab}
                  label="Other"
                  value="13"
                  {...a11yProps(6)}
                />
              </TabList>
            </Box>
            {
              loading ?
                <>
                  <TabPanel value="1">
                    <Sidesetting respo={resp} />
                  </TabPanel>
                  <TabPanel value="2">
                    <SeoSetting respo={resp} />
                  </TabPanel>
                  <TabPanel value="3">
                    <ThemeSetting respo={resp} />
                  </TabPanel>
                  <TabPanel value="4">
                    <Aboutus respo={resp} />
                  </TabPanel>
                  <TabPanel value="5">
                    <Specialists respo={resp} />
                  </TabPanel>
                  <TabPanel value="15">
                    <Rooms respo={resp} />
                  </TabPanel>
                  <TabPanel value="16">
                    <IpdStatus respo={resp} />
                  </TabPanel>
                  <TabPanel value="6">
                    <AppoinmentTypeSetting respo={resp} />
                  </TabPanel>
                  {/* <TabPanel value="7">
                <Template />
              </TabPanel> */}
                  <TabPanel value="8">
                    <Email respo={respe} />
                  </TabPanel>
                  {/* WAITING SCREEN SETTINGS TAB */}
                  <TabPanel value="9">
                    {/* <Que respo={response} /> */}
                    <QueueForm />
                  </TabPanel>
                  <TabPanel value="10">
                    <Sms respo={respon} />
                  </TabPanel>
                  <TabPanel value="11">
                    {/* <Sms />  */}
                    <PaymentSetting/>
                  </TabPanel>
                  {/* <TabPanel value="11">
               <Termscondition respo={resp}/>
              </TabPanel>
              <TabPanel value="12">
               <PrivacyPolicy respo={resp}/>
              </TabPanel> */}
                  <TabPanel value="13">
                    <Other respo={resp1} />
                  </TabPanel>
                </>
                :
                <Loader />
            }
          </TabContext>
        </Box>
      </UserContext.Provider>
    </>
  );
};

export default Settings;