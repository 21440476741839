import React, { useState } from "react";
import GenericGrid from "../../components/grid/generic-grid";
import useGlobalStyles from "../../utils/global-style";
import UpdateAppointmentDateDialog from "./update-appointment-date";
import UpdateAppointmentColumn from "./update-appointment-column";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const UpdateAppointment = () => {
  // set document title
  useDocumentTitle("Update Appointments");

  const globalClasses = useGlobalStyles();
  const [rowCount, setRowCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [gridApiInfo, setGridApiInfo] = useState(null);

//{ getUser.roleName ===  "ADMIN" || getUser.roleName ===  "DOCTOR_ADMIN" ?   ( 
  const user = JSON.parse(localStorage.getItem("USER"));

  // let filter = moment().startOf('isoWeek').valueOf();
    // let filterTo = moment(filter).add(7, 'days').valueOf();

  let defaultFilter = "" ;

  if((user.roleName === "DOCTOR" || user.roleName === "DOCTOR_ADMIN")){
      defaultFilter = {doctorSlug: { filterType: 'set', values: [`${user.slug}`]} }
  }
  else{
    defaultFilter = {doctorSlug: { filterType: 'set', values: []} }
  }

  return (
    <div className={globalClasses.addSpacing}>
      <GenericGrid
        baseUrl="appointment"
        hideToggleEditMode
        hideGlobalSearch
        hideDelete
        hideExport={false}
        hideOnCopy={true}
        hideAddNew
        hideOnUpload
        columnDefGenerator={UpdateAppointmentColumn}
        title="Update Appointments"
        csvName="appointments"
        suppressAutoFit
        defaultFilter={defaultFilter}
        bulkOperationOnRows={[
          {
            onClick: (rowCount, gridApiInfo) => {
              setOpen(true);
              setGridApiInfo(gridApiInfo);
              setRowCount(rowCount);
            },
            label: "Update Appointment Date",
          },
        ]}
      />
      <UpdateAppointmentDateDialog
        open={open}
        rowCount={rowCount}
        gridApiInfo={gridApiInfo}
        handleClose={() => {
          setOpen(false);
        }}
      />
    </div>
  );
};

export default UpdateAppointment;
