import React, { useEffect, useMemo, useRef, useState } from "react";
import { WaitingScreenSideInfoPanel } from "./WaitingScreenSideInfoPanel";
import { WaitingScreenCardsPanel } from "./WaitingScreenCardsPanel";
import { Grid, IconButton } from "@mui/material";
import "./WaitingScreen.scss";
import { useSelector } from "react-redux";
import { DoctorAPI } from "../../apis/DoctorAPI";
import { SettingsAPI } from "../../apis/SettingsAPI";
import { AppointmentAPI } from "../../apis/AppointmentAPI";
import AuthService from "../../utils/services/auth-service";
import Routes from "../../routes/routes";
import { useHistory } from "react-router-dom";
import soundFileDoorBell from "../../assets/doorbell.wav";
import SettingsIcon from "@material-ui/icons/Settings";

export const WaitingScreen = () => {
  // ----- global states -----
  const tenantInfo = useSelector((state) => state?.tenant);
  const config = AuthService.getConfig();
  let history = useHistory();

  // ----- local states -----
  const [allDoctorsList, setAllDoctorsList] = useState([]);
  const [allAppointmentsForToday, setAllAppointmentsForToday] = useState([]);
  const [checkedDoctorsList, setCheckedDoctorsList] = useState([]);
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [bgImage, setBgImage] = useState("");

  // ----- local variables -----
  const appointmentsMapRef = useRef({});

  // ----- side effects -----
  useEffect(() => {
    // Retrieve checked doctors from localStorage on mount
    const savedCheckedDoctors = localStorage.getItem("checkedDoctorsList");
    if (savedCheckedDoctors) {
      setCheckedDoctorsList(JSON.parse(savedCheckedDoctors));
    }

    SettingsAPI.getWaitingScreenSettingsInfo().then((response) => {
      setBgImage(response?.bgImage);
    });
    // Fetch doctors first, then fetch appointments
    DoctorAPI.getAllDoctors().then((response) => {
      setAllDoctorsList(response);
      const doctorSlugs = response?.map((doctor) => doctor?.slug);
      if (savedCheckedDoctors === null) {
        setCheckedDoctorsList(doctorSlugs);
      }

      AppointmentAPI.getAllAppointmentsForToday(doctorSlugs).then(
        (appointmentsResponse) => {
          setAllAppointmentsForToday(appointmentsResponse);
        }
      );
    });
  }, []);

  useEffect(() => {
    // Poll every 5 seconds for appointments only if doctors are loaded
    if (allDoctorsList.length > 0) {
      const intervalId = setInterval(() => {
        AppointmentAPI.getAllAppointmentsForToday(checkedDoctorsList).then(
          (response) => {
            setAllAppointmentsForToday(response);
          }
        );
        DoctorAPI.getAllDoctors().then((response) => {
          setAllDoctorsList(response);
        });
      }, 5000);

      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDoctorsList, checkedDoctorsList]);

  useEffect(() => {
    // Save checkedDoctorsList to localStorage on change
    localStorage.setItem(
      "checkedDoctorsList",
      JSON.stringify(checkedDoctorsList)
    );
  }, [checkedDoctorsList]);

  useEffect(() => {
    let shouldPlaySound = false;
    const appointmentsMap = appointmentsMapRef.current;
    if (allAppointmentsForToday !== null) {
      allAppointmentsForToday.forEach((appointment) => {
        if (appointment?.slug in appointmentsMap) {
          if (
            appointment?.called !== appointmentsMap[appointment?.slug] &&
            appointment?.called === true
          ) {
            shouldPlaySound = true;
          }
        } else {
          if (appointment?.called) {
            shouldPlaySound = true;
          }
        }
      });
    }
    appointmentsMapRef.current = {};
    if (allAppointmentsForToday !== null) {
      allAppointmentsForToday?.forEach((appointment) => {
        appointmentsMapRef.current[appointment?.slug] = appointment?.called;
      });
    }
    if (shouldPlaySound) handlePlayAudio();
  }, [allAppointmentsForToday]);

  // ----- memoized values -----
  const filteredDoctorsList = useMemo(
    () =>
      allDoctorsList.filter((doctor) =>
        checkedDoctorsList.includes(doctor.slug)
      ),
    [allDoctorsList, checkedDoctorsList]
  );

  // ----- event handlers -----
  const handleOnCloseSettingsMenu = () => {
    setSettingsMenuOpen(false);
    setAnchorEl(null);
  };

  const handleOnSettingsOnClick = (event) => {
    setSettingsMenuOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleLogOut = () => {
    AuthService.logout().subscribe((resp) => {
      history.replace(Routes.LOGIN);
    });
  };

  const handleDoctorsListOnClick = (doctorSlug, _) => {
    const isPresent = checkedDoctorsList.includes(doctorSlug);

    if (isPresent) {
      // If doctorSlug is found, remove it
      setCheckedDoctorsList((prev) =>
        prev.filter((doctor) => doctor !== doctorSlug)
      );
    } else {
      // If doctorSlug is not found, add it
      setCheckedDoctorsList((prev) => [...prev, doctorSlug]);
    }
  };

  const handlePlayAudio = () => {
    const audio = new Audio(soundFileDoorBell);
    audio.play();
  };

  return (
    <div className="waiting-screen__wrapper">
      <div className="waiting-screen__sideinfo-panel-wrapper">
        <div className="sideinfo-panel__settings">
          <IconButton sx={{ color: "white" }} onClick={handleOnSettingsOnClick}>
            <SettingsIcon fontSize="large" />
          </IconButton>
        </div>
        <WaitingScreenSideInfoPanel
          tenantLogoUrl={tenantInfo?.logo}
          youtubeVideoUrlList={config?.que?.videosOnLoop ?? []}
          updatesList={config?.que?.customUpdates ?? []}
          settingsMenuOpen={settingsMenuOpen}
          handleOnCloseSettingsMenu={handleOnCloseSettingsMenu}
          doctorsList={allDoctorsList}
          handleOnSettingsOnClick={handleOnSettingsOnClick}
          checkedState={checkedDoctorsList}
          handleLogOut={handleLogOut}
          handleDoctorsListOnClick={handleDoctorsListOnClick}
          anchorEl={anchorEl}
        />
      </div>
      <div
        className="waiting-screen__card-panel-wrapper"
        style={{
          backgroundImage: `linear-gradient(rgba(255,255,255,.3), rgba(255,255,255,.3)), url(${bgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <WaitingScreenCardsPanel
          allDoctorsList={filteredDoctorsList}
          todaysAppointmentDetails={allAppointmentsForToday}
          bgImage={bgImage}
        />
      </div>
    </div>
  );
};
