import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import Api from "../../utils/api";
import "./receipt.css";
import moment from "moment";
import Loader from "../../components/misc/loader";
import Routes from "../../routes/routes";
import AuthService from "../../utils/services/auth-service";
import PrintComponent from "../../components/common/PrintComponent";
import { useReactToPrint } from "react-to-print";
import DuplicatePrintComponent from "../../components/common/DuplicatePrintComponent";

const Receipt = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const slug = searchParams.get('slug');

  const data = location.state || {};
  const printDuplicate = data?.copy;
  const { isIpdBill, custom } = data;

  const [invoice, setInvoice] = useState(null);
  const [billingData, setBillingData] = useState([]);
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [totalBill, setTotalBill] = useState(0);
  const [pendingAmount, setPendingAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
 

  const printRef = useRef();

  useEffect(() => {
    fetchInvoiceData();
  }, []);

  const fetchInvoiceData = async () => {
    try {
      if (!data) {
        window.open(`/#!${Routes.APPOINTMENT}`);
        return;
      }

      if (custom) {
        if (slug) {
          const resp = await Api.get(`appointment/receipt/print/${slug}`).toPromise();
          const total = resp.data.data?.appointment?.fees - resp.data.data?.appointment?.discount;
          setTotalBill(total);
          setInvoice(resp?.data?.data);
        } else {
          window.open(`/#!${Routes.APPOINTMENT}`);
        }
      } else {
        const [resp1, resp2] = await Promise.all([
          Api.get(`appointment/receipt/print/${data?.slug}`).toPromise(),
          Api.get(`order/appointment/${data?.slug}`).toPromise()
        ]);

        setInvoice(resp1?.data?.data);
        setBillingData(resp2?.data?.data?.billings);
        setReceivedAmount(resp2?.data?.data?.receivedAmount);
        setTotalBill(resp2?.data?.data?.totalBill.toFixed(2));
        setPendingAmount(resp2?.data?.data?.pendingAmount);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });

  const Header = () => (
    <div className="invoice-header">
      <img
        src={
          isIpdBill
            ? AuthService.getConfig().ipdConfig.receiptHeaderUrl
            : `data:${invoice?.appointment?.doctor?.topLetterHead?.contenttype};base64,${invoice?.appointment?.doctor?.topLetterHead?.data.data}`
        }
        alt="header"
        style={{ width: "100%", height: "80%" }}
      />
    </div>
  );

  const Footer = () => (
    <div className="invoice-footer">
      {isIpdBill && (
        <div className="invoice-header">
          <img
            src={AuthService.getConfig().ipdConfig.receiptFooterUrl}
            alt="footer"
            style={{ width: "100%", height: "80%" }}
          />
        </div>
      )}
    </div>
  );

  const Content = () => {
    return (
      <div className="invoice-container">
        <div className="invoice-content">
          <div className="invoice-details">
            <div className="patient-info">
              <h5>INVOICE TO</h5>
              <p>
                <b>{invoice?.patient?.name}</b> <br />
                <small>UHID: {invoice?.patient?.uhid}</small>
              </p>
              {data.custom && (
                <p>Payment Method: {invoice?.appointment?.mode}</p>
              )}
          </div>
          <div className="invoice-summary">
            <div className="invoice-summary-row">
              <div>
                <p><b>NO.</b></p>
                <p>#{data.slug}</p>
              </div>
              <div>
                <p><b>Date:</b></p>
                <p>{data.receiptDate.format("DD-MM-YYYY")}</p>
              </div>
            </div>
            <div className="total-price">
              TOTAL INR: {data?.receiptData?.fee - data?.receiptData?.discount}
            </div>
          </div>
        </div>
        <div className="invoice-items">
          <div className="invoice-item">
            <div>S.No</div>
            <div style={{ flex: 8 }}>ITEM DESCRIPTION</div>
            <div>DATE</div>
            <div>QTY</div>
            <div>PRICE</div>
            <div>TOTAL</div>
          </div>
          {data.custom ? (
            <>
              <div className="invoice-item">
                <div>1</div>
                <div style={{ flex: 8 }}>{data?.receiptData?.itemDescription}</div>
                <div>{data.receiptDate.format("DD-MM-YYYY")}</div>
                <div>1</div>
                <div>INR {data.receiptData?.fee}</div>
                <div>INR {data.receiptData?.fee}</div>
              </div>
              {data.receiptData.discount && data.receiptData?.discount > 0 && (
                <div className="invoice-item">
                  <div>2</div>
                  <div style={{ flex: 8 }}>Discount</div>
                  <div>{data.receiptDate.format("DD-MM-YYYY")}</div>
                  <div>1</div>
                  <div>INR {data.receiptData?.discount}</div>
                  <div>INR {data.receiptData?.discount}</div>
                </div>
              )}
            </>
          ) : (
            billingData?.map((row, index) => (
              <div className="invoice-item" key={index}>
                <div>{index + 1}</div>
                <div style={{ flex: 8 }}>
                  {row.activityName}
                  {row?.discountValue > 0 && (
                    <>
                      <br />
                      Discount: {row.discountType === "percentage" 
                        ? `${row.discount} (${row?.discountValue}%)`
                        : row?.discountValue}
                    </>
                  )}
                </div>
                <div>{moment(row?.billingDateTime).format("DD-MM-YYYY")}</div>
                <div>{row.type === "credit" ? `${row.number}x` : ""}</div>
                <div>{row.type === "credit" ? row.price : ""}</div>
                <div>
                  ({row.type === "credit" ? "+" : "-"}) {row.totalAmount}
                </div>
              </div>
            ))
          )}
        </div>
        {!data.custom && (
          <div className="invoice-total">
            {pendingAmount != 0 && (
              <div className="pending-amount">
                <h6 className="total-price">
                  {pendingAmount > 0 ? "Pending" : "Advance"} INR: {pendingAmount}
                </h6>
              </div>
            )}
            {receivedAmount != 0 && (
              <div className="received-amount">
                <h6 className="total-price">
                  RECEIVING INR: {receivedAmount}
                </h6>
              </div>
            )}
          </div>
        )}
        <div className="print-date">
          <small>
            This is computer generated receipt no signature required | Print Date and time:
            {data.printDateSelected.format("DD-MM-YYYY")} | {data.PrintingTime}
          </small>
          </div>
        </div>
      </div>
    );
  };

  const PrintContent = () => {
    const hasManyItems = billingData?.length > 6;

    return (
      <div>
        {printDuplicate ? (
          hasManyItems ? (
            <>
              <Header />
              <Content />
              <Footer />
              <div style={{ pageBreakBefore: 'always' }}>
                <Header />
                <Content />
                <Footer />
              </div>
            </>
          ) : (
            <div>
              <Header />
              <Content />
              <Footer />
              <hr style={{ borderStyle: 'dashed' }} />
              <Header />
              <Content />
              <Footer />
            </div>
          )
        ) : (
          <>
            <Header />
            <Content />
            <Footer />
          </>
        )}
      </div>
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <DuplicatePrintComponent
        Content={PrintContent}
        reference={printRef}
      />
      <div className="no-print">
        <div>
          <Header />
          <Content />
          <Footer />
          {printDuplicate && (
            <>
              <hr style={{ borderStyle: 'dashed' }} />
              <Header />
              <Content />
              <Footer />
            </>
          )}
        </div>
        <center>
          <button
            className="btn btn-primary"
            onClick={handlePrint}
            id="printPageButton"
          >
            Print
          </button>
        </center>
      </div>
    </>
  );
};

export default Receipt;
