import { useState, useCallback, useEffect } from "react";
import {
  Box,
  Typography,
  Avatar,
  Divider,
  TextField,
  Fab,
  Tooltip,
} from "@mui/material";
import { LoadingOverlay } from "../common/LoadingOverlay";
import dayjs from "dayjs";
import ChatScreen from "./ChatScreen";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import { Refresh, HourglassTop } from "@mui/icons-material";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import PropTypes from "prop-types";
import { SocketEvents } from "../socket/socketEvents";
import CenteredTypography from "../common/CenteredTypography";
import { NotificationManager } from "react-notifications";
import Api from "../../utils/api";
import { SHOW_SUCCESS_NOTIFICATION } from "../../utils/app-util";
import { useDispatch } from 'react-redux';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const ChatListDesktop = ({ socket, chatList, loading, handleRefreshClick }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [searchedKey, setSearchedKey] = useState("");
  const [newChatUsers, setNewChatUsers] = useState([]);
  const [chatPackages, setChatPackages] = useState([]);
  const [filteredPatients, setFilteredPatients] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const unreadCount = chatList?.filter(
      chat => chat.latestMessageBy === "PATIENT" && chat.readStatus === "UNREAD"
    ).length || 0;
    
    dispatch({ type: 'SET_UNREAD_CHAT_COUNT', payload: unreadCount });
  }, [chatList, dispatch]);

  useEffect(() => {
    const payload = {
      filterModel: {},
      page: 0,
      size: 200,
      sortModel: [],
    };
    Api.post("chat-package/search", payload).subscribe((resp) => {
      setChatPackages(resp.data.data.content);
    });
  }, []);

  useEffect(() => {
    setFilteredPatients(
      (chatList || []).sort(
        (a, b) => new Date(b.dateModified) - new Date(a.dateModified)
      )
    );
  }, [chatList]);

  const handlePatientClick = async (patient) => {
    socket.emit(SocketEvents.UPDATE_MESSAGE_READ_STATUS, patient?.slug);
    await setSelectedPatient(patient);
    await handleRefreshClick();
  };

  const handleSearch = useCallback(
    (event) => {
      const { value } = event.target;
      setSearchQuery(value);
      setSearchedKey(value);
      const existingUhids = new Set();

      const filtered = chatList?.filter((patient) => {
        const nameMatch = patient?.patientDetails?.name
          ?.toLowerCase()
          .includes(value.toLowerCase());
        if (patient?.patientDetails?.uhid) {
          existingUhids.add(patient?.patientDetails?.uhid);
        }
        return nameMatch;
      });

      setFilteredPatients(filtered || []);

      if (!value) {
        setNewChatUsers([]);
      } else {
        const payload = {
          page: 0,
          size: 20,
          filterModel: {
            _: {
              filterType: "text",
              type: "",
              filter: value,
            },
          },
          sortModel: [],
        };
        Api.post(`patient/search`, payload).subscribe((resp) => {
          const newUsers = resp.data.data.content;
          const pendingNewUsers = newUsers.filter(
            (user) => !existingUhids.has(user.uhid)
          );
          setNewChatUsers(pendingNewUsers);
        });
      }
    },
    [chatList]
  );

  const handleStartNewChat = useCallback(
    (patient) => {
      const freeChatPackage = chatPackages.find(
        (chatPackage) => chatPackage.amount === 0
      );

      if (freeChatPackage) {
        const payload = {
          patientSlug: patient.slug,
          chatPackageSlug: freeChatPackage.slug,
        };

        Api.post(
          `/order/initiate/chat-package/doctor?patientSlug=${patient.slug}&chatPackageSlug=${freeChatPackage.slug}`,
          payload
        ).subscribe(() => {
          SHOW_SUCCESS_NOTIFICATION(
            "Free Chat Package Enabled for New Patient"
          );
          const waitForChatListUpdate = new Promise((resolve) => {
            const checkForNewPatient = (updatedChatList) => {
              const newPatient = updatedChatList.find(
                (p) => p.patientDetails.uhid === patient.uhid
              );
              if (newPatient) {
                setSelectedPatient(newPatient);
              }
              resolve();
            };

            socket.once(SocketEvents.POST_CHAT_LIST, checkForNewPatient);
          });

          setTimeout(async () => {
            await handleRefreshClick();
            await waitForChatListUpdate;
          }, 1000);
        });
      } else {
        NotificationManager.error("Please Create a Free chat Package");
      }
    },
    [chatPackages, handleRefreshClick, socket]
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          backgroundColor: "white",
          gap: 2,
          height: "85vh",
          p: 1,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            flex: 1,
            pt: 1,
            display: "flex",
            flexDirection: "column",
            borderRight: "1px solid #ccc",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              pb: 1,
              gap: 2,
            }}
          >
            <TextField
              label="Search Patient"
              value={searchQuery}
              onChange={handleSearch}
              variant="outlined"
              size="small"
              fullWidth
            />
            <Tooltip title="Refresh">
              <Fab
                variant="circular"
                size="small"
                color="primary"
                onClick={handleRefreshClick}
                sx={{
                  p: 2,
                  marginRight: 2,
                }}
              >
                {loading ? (
                  <>
                    <LoadingOverlay />
                    <HourglassTop />
                  </>
                ) : (
                  <Refresh sx={{ fontSize: "20px" }} />
                )}
              </Fab>
            </Tooltip>
          </Box>
          <Box
            sx={{
              flex: 1,
              mt: 1,
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#f5f5f5",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#092e54",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: "#4c4ccf",
                },
              },
            }}
          >
            {filteredPatients?.length > 0 ? (
              <Box>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Chat History
                </Typography>
                {filteredPatients?.map((patient, index) => (
                  <Box key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "8px 16px",
                        backgroundColor: "white",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#f5f5f5",
                        },
                      }}
                      onClick={() => handlePatientClick(patient)}
                    >
                      <Avatar
                        src={
                          patient?.patientDetails?.profilePicData
                            ? "data:image/jpeg;base64," +
                              patient?.patientDetails?.profilePicData
                            : undefined
                        }
                        alt={patient.name}
                        sx={{ width: 42, height: 42, marginRight: "15px" }}
                      />
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: "13px",
                            maxWidth: {
                              xs: "200px", 
                              sm: "100px", 
                              md: "130px", 
                            },
                          }}
                          variant="subtitle1"
                        >
                          {patient?.patientDetails?.name}
                        </Typography>
                        <Typography
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: "13px",
                            maxWidth: "100px",
                          }}
                          variant="body2"
                          color="textSecondary"
                        >
                          {patient?.latestMessagePreview}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { md: "13px", xs: "12px" },
                            fontWeight: "700",
                          }}
                        >
                           {dayjs(patient?.dateModified).format("HH:mm")}
                         </Typography>
                        <Typography
                          sx={{
                            fontSize: { md: "12px", xs: "11px" }
                          }}
                        >
                          {dayjs(patient?.dateModified).format("DD/MM/YY")}
                        </Typography>
                        {patient?.latestMessageBy === "PATIENT" &&
                        patient?.readStatus === "UNREAD" ? (
                          <MarkChatUnreadIcon
                            sx={{
                              color: "#092e54",
                            }}
                          />
                        ) : null}
                      </Box>
                    </Box>
                    {index < filteredPatients.length - 1 && <Divider />}
                  </Box>
                ))}
              </Box>
            ) : null}

            {newChatUsers.length > 0 && (
              <Box>
                <Typography variant="subtitle1" sx={{ mt: 2, mb: 2 }}>
                  New Chat
                </Typography>
                {newChatUsers.map((patient, index) => (
                  <Box key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "8px 16px",
                        backgroundColor: "white",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#f5f5f5",
                        },
                      }}
                      onClick={() => handleStartNewChat(patient)}
                    >
                      <Avatar
                        src={
                          patient?.profilePicData
                            ? "data:image/jpeg;base64," +
                              patient?.profilePicData
                            : undefined
                        }
                        alt={patient.name}
                        sx={{ width: 42, height: 42, marginRight: "15px" }}
                      />
                      <Box sx={{ flex: 1 }}>
                        <Typography
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: "13px",
                            maxWidth: "100px",
                          }}
                          variant="subtitle1"
                        >
                          {patient?.name || patient?.mobileNumber}
                        </Typography>
                        <Typography
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: "13px",
                            maxWidth: "100px",
                          }}
                          variant="body2"
                          color="textSecondary"
                        >
                          {patient?.uhid || patient?.slug}
                        </Typography>
                      </Box>
                    </Box>
                    {index < newChatUsers.length - 1 && <Divider />}
                  </Box>
                ))}
              </Box>
            )}

            {!filteredPatients?.length && !newChatUsers.length && (
              <CenteredTypography text="No Results Found" />
            )}
          </Box>
        </Box>
        <Box sx={{ flex: 2 }}>
          {selectedPatient ? (
            <ChatScreen
              selectedPatient={selectedPatient}
              socket={socket}
              handleRefreshClick={handleRefreshClick}
            />
          ) : (
            <Typography variant="h5" sx={{ textAlign: "center", mt: 2 }}>
              Select a Patient to start chat
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

ChatListDesktop.propTypes = {
  socket: PropTypes.object.isRequired,
  chatList: PropTypes.array,
  loading: PropTypes.bool,
  handleRefreshClick: PropTypes.func,
};

export default ChatListDesktop;
