import React from "react";
import GenericGrid from "../../components/grid/generic-grid";
import ChatPackageColumn from "./column";
import useGlobalStyles from "../../utils/global-style";
import AuthService from "../../utils/services/auth-service";
import useDocumentTitle from "../../hooks/useDocumentTitle";
const docSlug = AuthService.getUser()?.slug;

const ChatPackage = () => {
  // set document title
  useDocumentTitle("Chat Packages");

  const globalClasses = useGlobalStyles();
  const defaultFilter = {
      doctorSlug: {
        filterType: "exact",
        filter: `${docSlug}`
      }
  }

  return (
    <div className={globalClasses.addSpacing}>
      <GenericGrid
        baseUrl="chat-package"
        csvName="chat"
        hideOnCopy={true}
        hideExport={true}
        hideOnUpload={true}
        columnDefGenerator={ChatPackageColumn}
        title="Chat Packages"
        defaultFilter={defaultFilter}
      />
    </div>
  );
};

export default ChatPackage;
