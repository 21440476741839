import React from "react";
import GenericGrid from "../../components/grid/generic-grid";
import OtpColumn from "./otp-column";
import useGlobalStyles from "../../utils/global-style";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const OTPScreen = () => {
  // set document title
  useDocumentTitle("OTP");

  const globalClasses = useGlobalStyles();
  const user = JSON.parse(localStorage.getItem("USER")) || {};
 
  const defaultFilter = {
    doctorSlug: { filterType: 'set', values: [`${user?.slug}` || '']} ,
 }

  return (
    <div className={globalClasses.addSpacing}>
      <GenericGrid
        baseUrl="otp"
        hideToggleEditMode
        hideGlobalSearch
        hideDelete
        hideExport={true}
        hideOnCopy={true}
        hideAddNew
        hideOnUpload
        columnDefGenerator={OtpColumn}
        title="OTP"
        //defaultFilter={defaultFilter}
      />

      {/* <OtpList /> */}
    </div>
  );
};

export default OTPScreen;
