import React from "react";
import { Dialog, Button } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

const ViewBlockPatientModal = ({ open, onClose, patientData }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle sx={{ fontSize: 20, fontWeight: "bold" }}>Patient Blocked</DialogTitle>
      <DialogContent>
        <Typography sx={{ fontSize: 16 }}>
          The patient <span style={{ fontWeight: "600" }}>{patientData?.name}</span> has been blocked by {patientData?.blockedByUser}
        </Typography>
        <Typography sx={{ fontSize: 16 }}>
          Reason: <span style={{ fontWeight: "600" }}>{patientData?.blockReason}</span>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewBlockPatientModal;