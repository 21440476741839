export const SocketEvents = {
  CONNECT: "connect",
  DISCONNECT: "disconnect",
  CONNECTION_ERROR: "connect_error",
  FETCH_CHAT_LIST: "fetch:chat_list",
  POST_CHAT_LIST: "post:chat_list",
  FETCH_SESSION: "fetch:session",
  POST_SESSION: "post:session",
  FETCH_SESSION_MESSAGES: "fetch:session_messages",
  POST_SESSION_MESSAGES: "post:session_messages",
  MESSAGE_SEND: "message:send",
  NEW_MESSAGE: "message:new",
  UPDATE_MESSAGE_STATUS: "update:status_message",
  FETCH_TOTAL_MESSAGES: "fetch:total_messages",
  POST_TOTAL_MESSAGES: "post:total_messages",
  UPDATE_MESSAGE_READ_STATUS: "update:session_read",
  ADD_QUESTION: "add:question",
  LEAVE_ROOM: "leave:room",
  ERROR: "error",
};
