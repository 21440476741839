import { useState, useEffect, useRef } from "react";
import { Box, Modal, Typography, Button } from "@mui/material";
import ChatScreenBox from "./ChatScreenBox.jsx";
import ChatScreenHeader from "./ChatScreenHeader.jsx";
import TextInputToolbar from "./TextInputToolbar.jsx";
import AppConstants from "../../utils/app-constants.js";
import { LoadingOverlay } from "../common/LoadingOverlay.jsx";
import PropTypes from "prop-types";
import { SocketEvents } from "../socket/socketEvents.js";
import { CustomDialogBox } from "../common/CustomDialogBox.jsx";
import { useMediaQuery } from "@mui/material";

const ChatScreen = ({
  selectedPatient,
  socket,
  setShowChatScreen,
  handleRefreshClick,
}) => {
  const pageNumberRef = useRef(0);
  const [messages, setMessages] = useState([]);
  const [resolvedStatus, setResolvedStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [totalMessages, setTotalMessages] = useState(0);
  const [questionsRemaining, setQuestionsRemaining] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const user = JSON.parse(localStorage.getItem(AppConstants.USER_KEY));

  useEffect(() => {
    socket.on(SocketEvents.NEW_MESSAGE, handleNewMessage);

    return () => {
      socket.off(SocketEvents.NEW_MESSAGE, handleNewMessage);
    };
  }, [socket]);

  useEffect(() => {
    pageNumberRef.current = 0;
    setMessages([]);
    setScrollToBottom(false);
    fetchSession();
    fetchChatSessionMessages();
    socket.on(SocketEvents.POST_SESSION, handlePostSession);
    socket.on(SocketEvents.POST_SESSION_MESSAGES, handlePostSessionMessages);
    socket.on(SocketEvents.POST_TOTAL_MESSAGES, handleTotalMessages);
    return () => {
      socket.emit(SocketEvents.LEAVE_ROOM, selectedPatient?.slug);
      socket.off(SocketEvents.POST_SESSION, handlePostSession);
      socket.off(SocketEvents.POST_SESSION_MESSAGES, handlePostSessionMessages);
      socket.off(SocketEvents.POST_TOTAL_MESSAGES, handleTotalMessages);
    };
  }, [selectedPatient]);

  const toggleDialog = () => setDialogOpen((prev) => !prev);
  const trueCount = resolvedStatus.filter((item) => item.resolved).length;
  const falseCount = resolvedStatus.filter((item) => !item.resolved).length;

  // Utlity functions
  const handleNewMessage = async (message) => {
    await setMessages((prevMessages) => [...prevMessages, message]);
    await setScrollToBottom(true);
    await handleRefreshClick();
  };

  const handleLoadMoreMessages = async () => {
    pageNumberRef.current += 1;
    await fetchChatSessionMessages();
  };

  const handleTotalMessages = async (count) => {
    setTotalMessages(count);
  };

  const handlePostSession = (sessionData) => {
    setQuestionsRemaining(sessionData?.totalQuestions);
    setTotalDays(sessionData?.totalDays);
  };

  const handlePostSessionMessages = async (newMessages) => {
    if (pageNumberRef.current === 0) {
      setMessages(newMessages);
    } else {
      setMessages((prevArray) => [...newMessages, ...prevArray]);
    }
  };

  // ============================================================================================================================
  // Socket functions
  const handleUpdateResolvedStatus = () => {
    socket.emit(SocketEvents.UPDATE_MESSAGE_STATUS, resolvedStatus);
    setResolvedStatus([]);
    setDialogOpen(false);
  };

  const handleSubmit = async () => {
    try {
      const messageBody = {
        chatSessionSlug: selectedPatient?.slug,
        fromUserSlug: user?.slug,
        text: newMessage,
        type: "USER_GENERATED",
        resolved: false,
        latestMessageBy: "DOCTOR",
        readStatus: "UNREAD",
      };
      socket.emit(SocketEvents.MESSAGE_SEND, messageBody);
      await setNewMessage("");
      await setScrollToBottom(true);
      await handleRefreshClick();
    } catch (error) {
      console.error("Error sending message", error);
    }
  };

  const handleFileUpload = async (evt) => {
    let fileArray = Object.values(evt.target.files);
    const file = fileArray[0];

    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });

    try {
      const base64File = await toBase64(file);
      const messageBody = {
        chatSessionSlug: selectedPatient?.slug,
        fromUserSlug: user?.slug,
        text: "Attachment",
        attachment: base64File,
        fileName: file.name,
        fileType: file.type,
        type: "USER_GENERATED",
        resolved: false,
        latestMessageBy: "DOCTOR",
        readStatus: "UNREAD",
      };
      socket.emit(SocketEvents.MESSAGE_SEND, messageBody);
      await setNewMessage("");
      await setScrollToBottom((prev) => !prev);
      await handleRefreshClick();
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  const handleAddQuestion = async () => {
    try {
      setQuestionsRemaining((prev) => prev + 1);
      socket.emit(SocketEvents.ADD_QUESTION, selectedPatient.slug);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchSession = async () => {
    try {
      socket.emit(SocketEvents.FETCH_SESSION, selectedPatient?.slug);
      socket.emit(SocketEvents.FETCH_TOTAL_MESSAGES, selectedPatient?.slug);
    } catch (error) {
      console.error("Error fetching chat session", error);
    }
  };

  const fetchChatSessionMessages = async () => {
    try {
      socket.emit(
        SocketEvents.FETCH_SESSION_MESSAGES,
        selectedPatient?.slug,
        pageNumberRef.current
      );
    } catch (error) {
      console.error("Error fetching chat session", error);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingOverlay />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: isMobile ? "100dvh" : "100%",
          }}
        >
          <ChatScreenHeader
            patient={selectedPatient}
            totalQuestions={questionsRemaining}
            totalDays={totalDays}
            setShowChatScreen={setShowChatScreen}
            showResolveButton={resolvedStatus.length > 0}
            toggleDialog={toggleDialog}
            handleAddQuestion={handleAddQuestion}
          />
          <ChatScreenBox
            user={user}
            patient={selectedPatient}
            messages={messages}
            questionsRemaining={questionsRemaining}
            totalMessages={totalMessages}
            setResolvedStatus={setResolvedStatus}
            setQuestionsRemaining={setQuestionsRemaining}
            handleLoadMoreMessages={handleLoadMoreMessages}
            scrollToBottom={scrollToBottom}
          />
          <TextInputToolbar
            newMessage={newMessage}
            setNewMessage={setNewMessage}
            handleSubmit={handleSubmit}
            handleFileUpload={handleFileUpload}
          />
          <CustomDialogBox
            open={dialogOpen}
            dialogTitle="Resolved Messages"
            handleDialogOnClose={toggleDialog}
            onConfirm={handleUpdateResolvedStatus}
            actionBtnText="Update Status"
            dialogContentInlineStyle={{ padding: "30px" }}
          >
            <Typography>
              Total Resolved Messages: {trueCount}
              <br />
              Total Unresolved Messages: {falseCount}
            </Typography>
          </CustomDialogBox>
        </Box>
      )}
    </>
  );
};

ChatScreen.propTypes = {
  selectedPatient: PropTypes.object,
  socket: PropTypes.object.isRequired,
  setShowChatScreen: PropTypes.func,
  handleRefreshClick: PropTypes.func,
};

export default ChatScreen;
