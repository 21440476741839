import React from 'react';
import { useField } from 'informed';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { FormHelperText } from '@material-ui/core';

export const CustomDateInput = (props) => {
  const { fieldState, fieldApi } = useField(props);
  const {
    onChange: _onChange,
    containerClassName,
    label,
    className,
    required,
    onBlur,
    onFocus,
    minDate,
    maxLength,
    format,
    hideLabel,
    ...rest
  } = props;
  const { error, value: fieldValue, touched } = fieldState;
  const value = fieldValue === undefined ? null : fieldValue;
  const { setValue } = fieldApi;

  return (
    <div className={containerClassName}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div>
          <KeyboardDatePicker
            className={`MuiFormControl-marginNormal ${className}`}
            id="date-picker-dialog"
            {...rest}
            format="DD/MM/YYYY"
            label={label}
            inputProps={{
              style: { backgroundColor: 'white' }
            }}
            value={value}
            onChange={(e) => {
              if (_onChange) {
                _onChange(e && e.valueOf());
              }
              setValue(e && e.valueOf());
            }}
          />
          <FormHelperText>{error}</FormHelperText>
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default CustomDateInput;
