import { Box, TextField, InputAdornment, IconButton } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import PropTypes from "prop-types";

const TextInputToolbar = ({
  newMessage,
  setNewMessage,
  handleSubmit,
  handleFileUpload,
}) => {
  const isMessageEmpty = newMessage?.trim() === "";

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bottom: 0,
        position: "sticky",
      }}
    >
      <TextField
        variant="filled"
        fullWidth
        placeholder="Type a message"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        sx={{
          backgroundColor: "white",
          input: {
            color: "black",
            padding: "10px",
            fontSize: "18px",
          },
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            if (!isMessageEmpty) {
              handleSubmit();
            }
          }
        }}
        InputProps={{
          disableUnderline: true,
          sx: {
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton component="label">
                <input
                  accept="image/*,application/pdf"
                  type="file"
                  multiple
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                />
                <AttachFileIcon />
              </IconButton>
              <IconButton
                color="primary"
                onClick={handleSubmit}
                disabled={isMessageEmpty}
              >
                <SendIcon sx={{ fontSize: { xs: 24, sm: 30 } }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

TextInputToolbar.propTypes = {
  newMessage: PropTypes.string,
  setNewMessage: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleImageUpload: PropTypes.func,
};

export default TextInputToolbar;
