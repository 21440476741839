import React from 'react';
import { Box, Button, Grid, Modal, Select, MenuItem, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Loader from "../../components/misc/loader";

const StatusModal = ({
    open,
    handleClose,
    title,
    data,
    onChange,
    onSave,
    isLoading,
    classes,
    globalClass,
    socialMediaArr
}) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={classes.modalStyle}>
                <Box
                    mt="1rem"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography id="modal-modal-title" style={{ fontFamily: "Comfortaa", fontSize: "1.5rem" }} variant="h4" component="h2">
                        {title}
                    </Typography>
                    <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
                </Box>
                <Box className={classes.prescriptionmodal}>
                    <Box
                        mt="0.5rem"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h6" style={{ fontFamily: "Comfortaa", fontSize: "1rem" }} className={classes.prescriptionTitle}>
                            Title
                        </Typography>
                    </Box>
                    <Grid>
                        <TextField
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            name="name"
                            type="text"
                            placeholder="Title"
                            className={classes.style_2}
                            onChange={onChange}
                            value={data.name}
                        />
                    </Grid>
                </Box>
                <Box className={classes.prescriptionmodal}>
                    <Box
                        mt="0.5rem"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h6" style={{ fontFamily: "Comfortaa", fontSize: "1rem" }} className={classes.nameModal}>
                            Status*
                        </Typography>
                    </Box>
                    <Grid>
                        <Select
                            style={{ width: "100%" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="status"
                            value={data.status}
                            onChange={onChange}
                        >
                            {socialMediaArr.map((value) => (
                                <MenuItem key={value} value={value}>{value}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Box>
                <Box mb={3} style={{ marginTop: "2%" }}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={globalClass.largeBtnContainer}
                        onClick={onSave}
                    >
                        {isLoading ? (
                            <Loader width="200px" height="20px" color="white" />
                        ) : (
                            title.includes("Add") ? "SAVE" : "UPDATE"
                        )}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default StatusModal;