import React from "react";
import { Box, Button, Grid, Typography, Select, MenuItem } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@mui/material/Modal";
import { Formik, Form } from "formik";
import DynamicFieldArray from "../../components/common/DynamicFieldArray";
import Loader from "../../components/misc/loader";

const ChecklistModal = ({
    open,
    handleClose,
    getUpdateData,
    addChecklistTask,
    id,
    isLoading,
    classes,
    globalClass,
    onChange
}) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={classes.modalStyle}>
                <Box
                    mt="1rem"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography id="modal-modal-title" style={{ fontFamily: "Comfortaa", fontSize: "1.5rem" }} variant="h4" component="h2">
                        Add Checklist Task
                    </Typography>
                    <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
                </Box>

                <Box className={classes.prescriptionmodal}>
                    <Box
                        mt="0.5rem"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h6" style={{ fontFamily: "Comfortaa", fontSize: "1rem" }} className={classes.nameModal}>
                            Checklist*
                        </Typography>
                    </Box>
                    <Grid>
                        <Select
                            style={{ width: "100%" }}
                            labelId="demo-simple-select-label-checklist"
                            id="demo-simple-select-checklist"
                            name="checklist"
                            value={getUpdateData.checklist}
                            onChange={onChange}
                        >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </Select>
                    </Grid>
                </Box>

                {getUpdateData && (
                    <Box className={classes.prescriptionmodal}>
                        <Box
                            mt="0.5rem"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="h6" style={{ fontFamily: "Comfortaa", fontSize: "1rem" }} className={classes.nameModal}>
                                Checklist Task
                            </Typography>
                        </Box>

                        <Grid>
                            <Formik
                                initialValues={{
                                    checklistTask: getUpdateData?.checklistItems?.map(item => item.name) || [],
                                }}
                                validateOnChange={true}
                                validateOnBlur={true}
                            >
                                {({
                                    values,
                                    handleChange,
                                }) => (
                                    <Form className="queue-form">
                                        <Box className="form-section" style={{ maxHeight: '300px', overflow: 'auto' }}>
                                            <DynamicFieldArray
                                                name="checklistTask"
                                                values={values.checklistTask}
                                                handleChange={handleChange}
                                                placeholder="Enter status option text"
                                                maxLength={200}
                                            />
                                        </Box>
                                        <Box mb={3} style={{ marginTop: "2%" }}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                className={globalClass.largeBtnContainer}
                                                onClick={() => addChecklistTask(id, values.checklistTask)}
                                            >
                                                {isLoading.logoisLoading ? (
                                                    <Loader width="200px" height="20px" color="white" />
                                                ) : (
                                                    "UPDATE"
                                                )}
                                            </Button>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default ChecklistModal;