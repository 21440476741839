import React from "react";
import { Typography, Checkbox } from "@material-ui/core";
import moment from "moment";
import { Box } from "@mui/material";

const AdmitHistoryItem = ({
  item,
  fontSize,
  isChecked,
  onCheckboxChange,
  isPrinting = false,
}) => {
  const textStyle = { fontSize };

  const handleChange = (event) => {
    onCheckboxChange(item.id, event.target.checked);
  };

  if (item.historyType === "prescription") {
    return (
      <>
        <Box display="flex" alignItems="flex-start" mt={2}>
          {!isPrinting && (
            <Checkbox
              size="medium"
              style={{ paddingTop: "0" }}
              checked={isChecked}
              onChange={handleChange}
            />
          )}
          <Box>
            <Typography variant="h6" style={textStyle}>
              {item?.prescription?.templateTypeName}
            </Typography>
            <div
              dangerouslySetInnerHTML={{ __html: item?.prescriptionHtml }}
              style={textStyle}
            />
            <span style={textStyle}>{item.createdByName}</span>
            <br />
            <span style={textStyle}>
              Date: {moment(item.createdAt).format("DD-MM-YYYY")}, Time:{" "}
              {moment(item.createdAt).format("HH:mm:ss")}
            </span>
          </Box>
        </Box>
        <hr />
      </>
    );
  } else if (item.historyType === "billing") {
    return (
      <>
        <Box display="flex" alignItems="flex-start" mt={2}>
          {!isPrinting && (
            <Checkbox
              size="medium"
              style={{ paddingTop: "0" }}
              checked={isChecked}
              onChange={handleChange}
            />
          )}
          <Box>
            <Typography
              variant="h6"
              style={{ fontWeight: "bold", ...textStyle }}
            >
              Bill
            </Typography>
            <Typography style={textStyle}>
              Cost: {item?.billing?.price.toFixed(2)} | Quantity:{" "}
              {item?.billing?.number} | Discount:{" "}
              {item?.billing?.discountValue > 0
                ? item?.billing?.discountType === "percentage"
                  ? `${item?.billing?.discount} (${item?.billing?.discountValue}%)`
                  : item?.billing?.discountValue.toFixed(2)
                : "0"}{" "}
              | Subtotal: ({item?.billing?.type === "credit" ? "+" : "-"}){" "}
              {item?.billing?.totalAmount.toFixed(2)}
            </Typography>
          </Box>
        </Box>
        <hr />
      </>
    );
  } else if (item.historyType === "STATUS_CHANGED") {
    return (
      <>
        <Box display="flex" alignItems="flex-start" mt={2}>
          {!isPrinting && (
            <Checkbox
              size="medium"
              style={{ paddingTop: "0" }}
              checked={isChecked}
              onChange={handleChange}
            />
          )}
          <Box>
            <Typography variant="h6" style={textStyle}>
              IPD Status Changed
            </Typography>
            <div
              dangerouslySetInnerHTML={{ __html: item?.ipdStatusHtml }}
              style={textStyle}
            />
            <span style={textStyle}>{item.createdByName}</span>
            <br />
            <span style={textStyle}>
              Date: {moment(item.createdAt).format("DD-MM-YYYY")}, Time:{" "}
              {moment(item.createdAt).format("HH:mm:ss")}
            </span>
          </Box>
        </Box>
        <hr />
      </>
    );
  }
  return null;
};

export default AdmitHistoryItem;
