import React from "react";
import { Card, CardContent, Avatar, Typography, Chip } from "@mui/material";
import dayjs from "dayjs";

export const WaitingScreenDoctorCard = ({
  doctorName = "",
  doctorProfileImageUrl = "",
  doctorCurrentStatus = "Yet to arrive",
  doctorRoomNumber = "-",
  appointmentList = [],
}) => {
  return (
    <Card variant="outlined" className="doctor-card">
      <CardContent className="doctor-card__content">
        {/* Doctor Info */}
        <div className="doctor-card__content__info">
          <div className="dcc__avatar">
            <Avatar src={doctorProfileImageUrl} />
          </div>
          <div className="dcc__details">
            <div className="details-name">
              <Typography sx={{ fontWeight: "bold", fontFamily: "Comfortaa" }}>
                {doctorName}
              </Typography>
            </div>
            <div className="details-status">
              <Typography sx={{ fontSize: "9px", fontFamily: "Comfortaa" }}>
                {doctorCurrentStatus}
              </Typography>
              <Chip
                label={`Room: ${doctorRoomNumber ?? "-"}`}
                className="chip--responsive"
                sx={{ fontFamily: "Comfortaa" }}
              />
            </div>
          </div>
        </div>
        {/* Appointment List */}
        <div className="doctor-card__content__appointments">
          {appointmentList?.length !== 0 ? (
            appointmentList.map((appointment, _) => (
              <AppointmentInfo
                key={appointment?.uhid} // Ensure each item has a unique key
                patientName={appointment?.patientName}
                patientUHID={appointment?.uhid}
                appointmentDateTime={appointment?.startTime}
                customStatusList={getStatusList(appointment)}
                isCalled={appointment?.called}
              />
            ))
          ) : (
            <Typography sx={{ padding: "1rem", fontFamily: "Comfortaa" }}>
              No appointments
            </Typography>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export const AppointmentInfo = ({
  patientName = "",
  patientUHID = "",
  appointmentDateTime = "",
  customStatusList = [],
  isCalled = false,
}) => {
  const regex = /\b(\w{2})(\w+)(\w)\b/g;

  return (
    <div
      className={`appointment-info__wrapper ${
        isCalled ? "appointment-info__wrapper--called" : ""
      }`}
    >
      {/* Time, Name, UHID row */}
      <div className="appointment-info__row">
        <div className="appointment-info__name">
          <Typography sx={{ fontSize: "12px", fontFamily: "Comfortaa" }}>
            {maskPatientName(patientName)}
          </Typography>
        </div>
        {/* UHID */}
        <div className="appointment-info__uhid">
          <Typography
            textAlign="end"
            sx={{ fontSize: "12px", fontFamily: "Comfortaa" }}
          >
            {maskID(patientUHID)}
          </Typography>
        </div>
      </div>
      {/* Custom status chips */}
      <div className="appointment-info__status-time-chips">
        <div className="appointment-info__time">
          <Typography sx={{ fontSize: "12px", fontFamily: "Comfortaa", fontWeight: "bold" }}>
            {appointmentDateTime}
          </Typography>
        </div>
        <div className="appointment-info__status-chips">
          {customStatusList?.map((status, index) => {
            return (
              <div key={index} className="appointment-info__status-chip">
                <Chip
                  label={status?.label}
                  sx={{
                    backgroundColor: status?.color,
                    color: "white",
                    fontFamily: "Comfortaa",
                  }}
                  className="chip--responsive"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const getStatusList = (appointment) => {
  let statusList = [];
  const appointmentStatus =
    appointment?.status
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ") ?? "";
  statusList.push({
    label: appointmentStatus,
    color: appointmentStatus === "Checked In" ? "#72b505" : "#04a6b5",
  });
  statusList.push({
    label: appointment?.isLate ? "Late" : "On Time",
    color: appointment?.isLate ? "#d12114" : "#2884ff",
  });

  // called or not
  if (appointment?.called) {
    statusList.push({ label: "Called", color: "#4f5f79" });
  }
  return statusList;
};

const maskID = (id) => {
  // Determine the start length based on the prefix of the ID
  let startLength = id.startsWith("JH") ? 2 : 4;

  // Set a standard end length (4 characters to be shown at the end)
  const endLength = 4;

  if (id.length <= startLength + endLength) {
    return id; // No need to mask if the ID is too short
  }

  // Extract the start and end parts of the ID
  const start = id.slice(0, startLength);
  const end = id.slice(-endLength);

  // Mask the middle part
  const maskLength = 2;
  const maskedPart = "*".repeat(maskLength);

  // Return the masked ID
  return `${start}${maskedPart}${end}`;
};

const maskPatientName = (patientName) => {
  // Split the patientName by spaces
  const words = patientName.split(" ");

  // Mask each word based on the length rules
  const maskedWords = words.map((word) => {
    if (word.length <= 2) {
      return word; // No masking for length ≤ 2
    } else if (word.length === 3) {
      return word[0] + "*" + word[2]; // Mask middle character for length = 3
    } else {
      // Mask the middle characters for length > 3
      return word.slice(0, 2) + "*".repeat(word.length - 3) + word.slice(-1);
    }
  });

  // Join the masked words back together with spaces
  return maskedWords.join(" ");
};
