import React, { useState, useCallback } from "react";
import AppConstants from "../../utils/app-constants";
import { getFormattedDate } from "../../utils/app-util";
import { DEFAULT_ERROR_CALLBACK } from "../../utils/app-util";
import { makeStyles } from "@material-ui/styles";
import ThemedButton from "../layout/styled-btn";
import EyeIcon from "../../assets/eye-outline.svg";
import Icon from "@material-ui/core/Icon";
import Routes from "../../routes/routes";
import Axios from "axios";

import {
  Box, Modal,
  Typography,
  Grid,
  CircularProgress, TextField
} from "@material-ui/core";
import Api from "../../utils/api";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from "@mui/material/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useGlobalStyles from "../../utils/global-style";
import DataService from "../../utils/services/data-service";
import clsx from "clsx";
import DialogTitle from "@mui/material/DialogTitle";
import AuthService from "../../utils/services/auth-service";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import BlockPatientModal from "../../screens/patient/BlockPatientModal";
import {
  SHOW_SUCCESS_NOTIFICATION
} from "../../utils/app-util";
import { blockPatient } from "../../apis/PatientAPI";

const CustomRenderer = ({ currentValue, dataType, context, data }) => {
  let slug = data?.slug;
  let product_id = data?.products?.productId;
  let product_type = data?.products?.productType;

  // let billing = data?.products?.billings;

  let billing = data?.billings;

  const globalClass = useGlobalStyles();
  const useStyles = makeStyles({
    imageIcon: {
      width: "100%",
      height: "100%",
    },
    iconRoot: {
      display: "flex",
      alignItems: "center",
      marginTop: "12px",
      "&:hover": {
        cursor: "pointer",
      },
      width: "24px",
      height: "24px",
    },
  });

  const classes = useStyles();
  const [val] = useState(currentValue);

  const handleClick = () => {
    context.onUpdateBtnClicked(data);
  };

  const handleSpeciality = () => {
    context.onUpdateBtnClicked(data);
  };

  const toggleModal = () => {
    context.onEyeBtnClicked(data);
  };

  const chatBtnClick = () => {
    context.onChatBtnClick(data);
  };

  const [openModel, setOpenModel] = React.useState(false);
  const [mail, setMail] = React.useState({
    subject: "",
    content: "",
  });

  // block patient
  const [blockModalOpen, setBlockModalOpen] = useState(false);

  const handleCloseModal = () => {
    setBlockModalOpen(false);
  }

  const handleBlockPatient = () => {
    setBlockModalOpen(true);
    context.onBlockButtonClick(data);
  }

  const handleBlockConfirm = (payload) => {
    blockPatient(payload);
    context.refreshGridData();
  };

  const handleChangeMail = (e) => {
    const { value, name } = e.target;
    setMail({ ...mail, [name]: value });
  };

  const mailBtnClick = () => {
    setMail(data);
    context.onMailButtonClicked(data);
    // alert("hiiii")
    setOpenModel(true);
  };
  const handleCloseModel = () => {
    setOpenModel(false);
  };

  const handleSubmitMail = async () => {
    const formData = new FormData();
    formData.append("slug", mail?.slug);
    formData.append("subject", mail?.subject);
    formData.append("content", mail?.content);
    const token = AuthService.getToken();
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_API_HOST}patient/send_custom_msg`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-AUTH-TOKEN": token,
          },
        }
      );
      if (res) {
        SHOW_SUCCESS_NOTIFICATION(res.data.message);
      }
    } catch (error) {}
  };

  //  const handleSubmitMail=async()=>{
  //    const formData = new FormData();
  //   formData.append("slug", mail?.slug);
  //   formData.append("subject", mail?.subject);
  //   formData.append("content", mail?.content);

  //   console.log("variable",process.env.REACT_APP_API_HOST)
  //   const res=await Axios.post(`${process.env.REACT_APP_API_HOST}send_custom_msg?slug=${data?.slug}`,{
  //      formData
  //     // headers: { "Content-Type": "application/json" },
  //   })

  //  }

  const logsBtnClick = () => {
    context.onLogButtonClicked(data);
  };
  const feedbackBtnClick = () => {
    context.onfeedbackButtonClicked(data);
  };
  const fetchReports = (rPatientSlug) => {
    setIsLoading(true);
    DataService.fetchList("report/search", 0, 100, {
      patientSlug: {
        // values: [data && data.patientSlug],
        values: [rPatientSlug],
        filterType: "set",
      },
    }).subscribe((resp) => {
      const reports = [...resp.content];
      const newestReports = reports.reverse();
      setPatientReports(newestReports);
      setIsLoading(false);
    });
  };
  const reportBtnClick = () => {
    // setReport(true);
    // fetchReports(item.patientSlug);
    // fetchReports(slug);
    window.open(`#!` + `${Routes.NEWREPORT}?slug=${slug}`, "_blank");
  };

  const billdetailsBtnClick = () => {
    if (billing !== null || billing !== "") {
      if (product_type === "APPOINTMENT") {
        window.open(`#!` + `${Routes.BILLING}?slug=${product_id}`, "_blank");
      }
    }
  };

  const onImageClick = (val) => {
    let data = val;
    let w = window.open("about:blank");
    let image = new Image();
    image.src = data;
    setTimeout(function () {
      w.document.write(image.outerHTML);
    }, 0);
  };

  const getComponent = (type) => {
    if (type === "DATE") {
      return (
        <div>{val ? getFormattedDate(val, "DD MMM YYYY, hh:mm a") : null}</div>
      );
    }

    if (type === "BOOLEAN") {
      return <div>{val + ""}</div>;
    }

    if (type === "ICON") {
      return (
        <Icon classes={{ root: classes.iconRoot }} onClick={toggleModal}>
          <VisibilityIcon className={classes.imageIcon}/>
        </Icon>
      );
    }

    if (type === "billicon") {
      return (
        <Icon classes={{ root: classes.iconRoot }} onClick={toggleModal}>
          <img className={classes.imageIcon} src={EyeIcon} alt="" />
        </Icon>
      );
    }

    if (type === "CHAT") {
      return (
        <Icon classes={{ root: classes.iconRoot }} onClick={chatBtnClick}>
          <ChatIcon className={classes.imageIcon}/>
        </Icon>
      );
    }

    if (type === "MAIL") {
      return (
        <>
          <React.Fragment>
            <Icon classes={{ root: classes.iconRoot }} onClick={mailBtnClick}>
              <NotificationsActiveIcon className={classes.imageIcon}/>
            </Icon>
            <Dialog
              open={openModel}
              onClose={handleCloseModel}
              PaperProps={{
                component: "form",
                onSubmit: (event) => {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const formJson = Object.fromEntries(formData.entries());
                  const email = formJson.email;
                  handleCloseModel();
                },
              }}
            >
              <DialogTitle>Notify</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="subject"
                  value={mail.subject}
                  onChange={handleChangeMail}
                  name="subject"
                  label="Subject"
                  type="text"
                  fullWidth
                  variant="standard"
                />
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="content"
                  name="content"
                  onChange={handleChangeMail}
                  value={mail.content}
                  label="Content"
                  type="text"
                  fullWidth
                  variant="standard"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseModel}>Cancel</Button>
                <Button type="submit" onClick={handleSubmitMail}>
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        </>
      );
    }

    if (type === "LOGS") {
      return (
        <Icon classes={{ root: classes.iconRoot }} onClick={logsBtnClick}>
          <ContentPasteSearchIcon className={classes.imageIcon}/>
        </Icon>
      );
    }
    if (type === "FEEDBACK") {
      return (
        <Icon classes={{ root: classes.iconRoot }} onClick={feedbackBtnClick}>
          <img className={classes.imageIcon} src="/logs.svg" alt="chaticon" />
        </Icon>
      );
    }

    if (type === "REPORT") {
      return (
        <Icon onClick={reportBtnClick} classes={{ root: classes.iconRoot }}>
          <FileCopyIcon className={classes.imageIcon}/>
        </Icon>
      );
    }

    if (type === "Bill_DETAILS") {
      return (
        <>
          {billing != "" &&
          billing != null &&
          product_type === "APPOINTMENT" ? (
            <>
              <Icon
                onClick={billdetailsBtnClick}
                classes={{ root: classes.iconRoot }}
              >
                <FileCopyIcon/>
              </Icon>
            </>
          ) : (
            <></>
          )}
        </>
      );
    }

    if (type === "Bill_amount") {
      return (
        <>
          {billing != "" &&
          billing != null &&
          product_type === "APPOINTMENT" ? (
            <>
              TOTAL : {data?.totalBill} <br />
              {data?.receivedAmount > 0 ? (
                <>
                  RECIVED : {data?.receivedAmount} <br />
                </>
              ) : (
                ""
              )}
              {data?.pendingAmount > 0 ? (
                <>
                  PENDING : {data?.pendingAmount} <br />
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <>{data?.paymentDetails?.amount}</>
          )}
        </>
      );
    }

    if (type === "IMAGE") {
      return (
        <div className="text-center">
          {val ? (
            <img
              className="mr-4"
              src={val || AppConstants.ICON.IMAGE}
              width="40"
              alt="logo"
              onClick={() => onImageClick(val)}
            />
          ) : null}
        </div>
      );
    }

    if (type === "BUTTON") {
      return (
        <>
          <ThemedButton onClick={handleClick} text="Update" />
        </>
      );
    }

    if (type === "BLOCK") {
      return (
        <>
          <ThemedButton 
            classes={{ root: classes.iconRoot }}
            onClick={handleBlockPatient}
            text={data?.isBlocked ? "Unblock" : "Block"}
          />
          <BlockPatientModal
            openModel={blockModalOpen}
            onClose={handleCloseModal}
            onConfirm={handleBlockConfirm}
            patientData={data}
            actionType={data?.isBlocked ? "unblock" : "block"}
          />
        </>
      );
    }

    return <div>{val}</div>;
  };

  // All report content

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [openReport, setOpenReport] = useState(false);
  const handleClosed = () => {
    setOpen(false);
  };
  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);
  const handleCloseReport = useCallback(() => {
    setOpenReport(false);
  }, []);
  const handleClose = () => {
    setOpen(false);
    setReport(false);
  };
  // const handleClose = () => {
  //   setOpen(false);
  //   setReport(false)
  // };
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [isLoading, setIsLoading] = useState(false);
  const [openRep, setReport] = useState(false);
  const [selectedReport, setSelectedReport] = useState([]);
  const [reportType, setReportType] = useState(null);
  const [selectSingleImage, setSelectSingleImage] = useState(null);
  const [patientReports, setPatientReports] = useState([]);
  const handleSelectedReport = (report) => {
    setSelectedReport(report);
    Api.get(`report/${report.slug}`).subscribe(
      (resp) => {
        setSelectSingleImage(resp.data.data);
        setOpenReport(true);
        if (resp.data.data?.images) {
          let fileType = null;
          fileType = resp.data.data.images.map((data) => data.contenttype);
          setReportType(fileType);
        }
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );

    // if(report?.images?.[0]){
    //   let fileType = null;
    //   fileType = report.images[0].split(".").pop()
    //   setReportType(fileType);
    // }
  };
  const handleImageClick = (val) => {
    let data = val;
    let w = window.open("about:blank");
    let image = new Image();
    image.src = data;
    setTimeout(function () {
      w.document.write(image.outerHTML);
    }, 0);
  };

  return (
    <>
      {getComponent(dataType)}
      {
        <>
          <div>
            <Dialog
              open={openRep}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="xl"
              position="fixed"
            >
              <DialogContent sx={{ width: 600 }}>
                {isLoading ? (
                  <CircularProgress
                    disableShrink
                    className={classes.loaderStyle}
                    size={35}
                  />
                ) : (
                  <>
                    <Typography variant="h4">Report</Typography>
                    <Grid container item sx={{ width: 500 }}>
                      <Paper
                        className={classes.containerBox}
                        sx={{ width: 500 }}
                      >
                        {/* <MiniHeader title="Report(s)" /> */}
                        {patientReports.length > 0 ? (
                          <Box
                            className={clsx(
                              classes.presBox,
                              globalClass.srollBar
                            )}
                            style={{ height: "300px", paddingLeft: "20px" }}
                          >
                            {patientReports.map((data) => (
                              <div
                                className={classes.reportCard}
                                key={data.id}
                                onClick={() => handleSelectedReport(data)}
                              >
                                <h6 className={classes.title}>
                                  {`${data.reportName ?? ""} / (${
                                    getFormattedDate(
                                      data.reportDate,
                                      "Do MMMM YYYY"
                                    ) ?? ""
                                  })`}
                                </h6>
                              </div>
                            ))}
                          </Box>
                        ) : (
                          <div className={classes.noReport}>
                            <Typography variant="h6">
                              No Reports Found
                            </Typography>
                          </div>
                        )}
                      </Paper>
                    </Grid>
                  </>
                )}
              </DialogContent>

              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>

            <Modal
              open={opened}
              onClose={handleClosed}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Text in a modal
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Duis mollis, est non commodo luctus, nisi erat porttitor
                  ligula.
                </Typography>
              </Box>
            </Modal>
          </div>
          <Dialog
            className={classes.dialogBackground}
            fullScreen
            open={openReport}
            onClose={handleCloseReport}
          >
            <div className={classes.closeContainer}>
              <div className={classes.reportClose} onClick={handleCloseReport}>
                <p
                  className={classes.closeHeading}
                  style={{ marginTop: "14px" }}
                >
                  Close
                </p>
              </div>
              <div className={classes.reportTitle}>
                <span>
                  {`${selectSingleImage?.reportName ?? ""} / ${
                    getFormattedDate(
                      selectSingleImage?.reportDate,
                      "Do MMMM YYYY"
                    ) ?? ""
                  }/ 
                  Value: ${selectSingleImage?.value ?? ""}`}
                </span>
              </div>
            </div>
            {selectSingleImage?.images ? (
              selectSingleImage?.images?.length === 1 ? (
                selectSingleImage?.images[0].contenttype ===
                "application/pdf" ? (
                  <iframe
                    title="Report picture"
                    src={selectSingleImage.images[0].content}
                    alt="Report"
                    className={classes.reportImage}
                    onClick={() =>
                      handleImageClick(selectSingleImage.images[0].content)
                    }
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <img
                    title="Report picture"
                    src={selectSingleImage.images[0].content}
                    alt="Report"
                    className={classes.reportImage}
                    onClick={() =>
                      handleImageClick(selectSingleImage.images[0].content)
                    }
                    style={{ cursor: "pointer" }}
                  />
                )
              ) : (
                <div style={{}} className={classes.multipleImageContainer}>
                  <Slider {...settings}>
                    {selectSingleImage.images.map((im, index) => {
                      return (
                        <div
                          key={index}
                          style={{ width: "100%", height: "90vh" }}
                        >
                          {im?.contenttype === "application/pdf" ? (
                            <iframe
                              title="Report picture"
                              src={im.content}
                              alt="Report"
                              className={classes.reportImage}
                              onClick={() => handleImageClick(im?.content)}
                              style={{
                                cursor: "pointer",
                                width: "100%",
                                height: "90vh",
                              }}
                            />
                          ) : (
                            <img
                              title="Report picture"
                              src={im?.content}
                              alt="Report"
                              className={classes.reportMultipleImage}
                              onClick={() => handleImageClick(im?.content)}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              )
            ) : (
              <div className={classes.noReportImage}>
                Report image is not available.
              </div>
            )}
          </Dialog>
        </>
      }
    </>
  );
};

export default CustomRenderer;
