import { io } from "socket.io-client";
import AppConstants from "../../utils/app-constants";

let socket;

export const getSocket = () => {
  if (!socket) {
    const URL = process.env.REACT_APP_API2_HOST;
    const authKey = localStorage.getItem(AppConstants.AUTH_TOKEN_KEY);

    socket = io(URL, {
      auth: {
        token: authKey,
      },
    });
  }
  return socket;
};
