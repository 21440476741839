import { useQuery, useMutation } from '@tanstack/react-query'
import Api from "../utils/api"
import { SHOW_ERROR_NOTIFICATION, SHOW_SUCCESS_NOTIFICATION } from '../utils/app-util';

export const useGetAllActiveRooms = () => {
    return useQuery({
        queryKey: ['activeRooms'],
        queryFn: async () => {
            const response = await Api.get("admit/get_all_rooms?status=Active").toPromise();
            return response.data?.data;
        },
    })
}

export const useSearchDoctors = () => {
    return useQuery({
        queryKey: ['doctors'],
        queryFn: async () => {
            const response = await Api.post("doctor/search", {
                page: 0,
                size: 300,
                filterModel: {
                    roleName: {
                        filterType: "set",
                        values: ["DOCTOR", "DOCTOR_ADMIN"],
                    },
                },
                sortModel: [],
            }).toPromise();
            return response.data?.data?.content;
        },
    })
}

export const useAdmitPatient = () => {
    return useMutation({
        mutationFn: async (addPatientData) => {
            const response = await Api.post("/admit/add", addPatientData).toPromise();
            return response.data;
        },
        onSuccess: (data) => {
            if (data.status === true) {
                SHOW_SUCCESS_NOTIFICATION(data.message);
            } else {
                SHOW_ERROR_NOTIFICATION(data.message);
            }
        },
        onError: (error) => {
            SHOW_ERROR_NOTIFICATION("An error occurred while admitting the patient");
        }
    });
}

export const useGetAdmitInfo = (admitId) => {
    return useQuery({
        queryKey: ['admitInfo'],
        queryFn: async () => {
            const response = await Api.get(`/admit/id/${admitId}`).toPromise();
            return response.data?.data;
        },
    })
}

export const useGetAdmitHistory = (admitId, page = 0, size = 20) => {
    return useQuery({
        queryKey: ['admitHistory', admitId, page],
        queryFn: async () => {
            const response = await Api.post('admit/history/search', {
                size: size,
                page: page,
                filterModel: {
                    admitId: {
                        type: "equals",
                        value: admitId
                    }
                },
                sortModel: [
                    {
                        colId: "createdAt",
                        sort: "DESC"
                    }
                ]
            }).toPromise();
            return response.data?.data; // Return the entire data object, not just content
        },
        keepPreviousData: true,
    });
}

export const SearchAdmit = (searchPayload) => {
    return Api.post("/admit/search", searchPayload)
        .toPromise()
        .then(response => response.data)
        .catch(error => {
            console.log(error);
            throw error;
        });
}