import React, { useState } from "react";
import { Dialog, Button, TextField } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { SHOW_ERROR_NOTIFICATION } from "../../utils/app-util";

const BlockPatientModal = ({ openModel, onClose, patientData, onConfirm, actionType }) => {
  const [reason, setReason] = useState('');
  
  const handleConfirm = () => {
    if (actionType === "block" && !reason.trim()) {
      SHOW_ERROR_NOTIFICATION("Please enter a reason for blocking the patient.");
      return;
    }

    const payload = {
      patientSlug: patientData.slug,
      reason: reason,
      isBlocked: actionType === "block"
    };
    onConfirm(payload);
    onClose();
  };

  return (
    <Dialog
      open={openModel}
      onClose={() => {
        setReason("");
        onClose();
      }}
    >
      <DialogTitle sx={{ fontSize: 20, fontWeight: "bold" }}>
        {actionType === "block" ? "Block Patient" : "Unblock Patient"}
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ fontSize: 16 }}>
          Are you sure you want to {actionType} this patient?
        </Typography>
        <Typography sx={{ fontSize: 16 }}>
          Patient Name: <span style={{ fontWeight: "600" }}>{patientData?.name}</span>
        </Typography>
          <TextField
            sx={{ marginTop: 2 }}
            label="Reason for blocking"
            fullWidth
            value={reason || patientData?.blockReason}
            onChange={(e) => setReason(e.target.value)}
            multiline
            minRows={2}
            disabled={actionType === "unblock"}
            required
          />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button type="submit" onClick={handleConfirm}>
          {actionType === "block" ? "Block" : "Unblock"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BlockPatientModal;
