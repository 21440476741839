import { Button, Divider } from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import AddIcon from "@material-ui/icons/Add";
import { useEffect, useRef } from "react";

function DataGridToolbar(props) {
  const { toolBarButtonTitle, handleToolBarButtonClick } = props;

  return (
    <>
      <GridToolbarContainer
        sx={{ backgroundColor: "white", height: 56, justifyContent: "end" }}
      >
        {/* TODO: have reusable button */}
        <Button
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleToolBarButtonClick}
          sx={{
            mr: 2,
            backgroundColor: "#445b79",
            "&:hover": {
              backgroundColor: "#9aa7bc",
            },
            borderRadius: "10px",
          }}
          variant="contained"
        >
          {toolBarButtonTitle}
        </Button>
      </GridToolbarContainer>
      <Divider />
    </>
  );
}

export const CustomDataGrid = ({
  getRowId = (cell) => cell.id,
  rows,
  columns,
  toolBarButtonTitle = "Add",
  showEditToolbar = "false",
  handleToolBarButtonClick = () => {},
  showPagination = false,
  isLoading = false,
  editMode = "cell",
  onRowModesModelChange = () => {},
  onRowEditStop = () => {},
  processRowUpdate = () => {},
  apiRef: passedApiRef,
  rowModesModel = {},
  sx,
  getRowClassName = () => {},
  getRowHeight = () => {},
  autoScroll = false,
  checkboxSelection = false, // New prop with default value false
}) => {
  const gridRef = useRef(null);
  const scrollInterval = useRef(null);
  const defaultApiRef = useRef(null);
  const apiRef = passedApiRef || defaultApiRef;

  useEffect(() => {
    if (!autoScroll) return;

    const scrollGrid = () => {
      if (gridRef.current) {
        const gridElement = gridRef.current.querySelector(".MuiDataGrid-virtualScroller");
        
        if (gridElement.scrollTop + gridElement.clientHeight >= gridElement.scrollHeight) {
          gridElement.scrollTop = 0;
        } else {
          gridElement.scrollTop += 1;
        }
      }
    };

    // increase time to slow down scroll
    scrollInterval.current = setInterval(scrollGrid, 30);

    return () => clearInterval(scrollInterval.current);
  }, [autoScroll]);

  return (
    <div className="custom-data-grid-wrapper" ref={gridRef}>
      <DataGrid
        rowModesModel={rowModesModel}
        editMode={editMode}
        loading={isLoading}
        getRowId={getRowId}
        rows={rows}
        columns={columns}
        slots={{
          toolbar: showEditToolbar ? DataGridToolbar : null,
        }}
        slotProps={{
          toolbar: showEditToolbar ? { toolBarButtonTitle, handleToolBarButtonClick } : {},
          loadingOverlay: {
            variant: "linear-progress",
            noRowsVariant: "linear-progress",
          },
        }}
        onRowModesModelChange={onRowModesModelChange}
        onRowEditStop={onRowEditStop}
        processRowUpdate={processRowUpdate}
        apiRef={apiRef}
        sx={{
          "& .MuiDataGrid-footerContainer": {
            display: showPagination ? "block" : "none",
          },
          "& .MuiDataGrid-row": {
            backgroundColor: "background.paper",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "action.hover",
          },
          ...sx,
        }}
        rowHeight={40}
        headerHeight={40}
        getRowClassName={getRowClassName}
        getRowHeight={getRowHeight}
        checkboxSelection={checkboxSelection}
      />
    </div>
  );
};
