import React, { useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Paper,
  Box,
  TableFooter,
  TablePagination,
  makeStyles,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import Icon from "@material-ui/icons/AssignmentOutlined";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import CallNextIcon from "../../assets/call-next.svg";
import CallNextIconW from "../../assets/call-next-w.svg";
import DescriptionIcon from "@material-ui/icons/Description";
import { getFormattedDate, SHOW_ERROR_NOTIFICATION } from "../../utils/app-util";
import { TextInput } from "sixsprints-react-ui";
import AppConstants from "../../utils/app-constants";
import isAuthorised, { isCurrentRole } from "../../utils/role-util";
import AuthService from "../../utils/services/auth-service";
import TablePaginationActions from "../../components/table/table-pagination";
import Label from "../../components/layout/label";
import Tooltip from "@material-ui/core/Tooltip";
import OverFlowMenu from "../../components/layout/menu";
import { useState } from "react";
import MessageDialog from "../../components/layout/message-dialog";
import LightTooltip from "./light-tooltip";
import { useRecoilValue } from "recoil";
import { AppointmentsStatusState, AppointmentsState } from "../../states";
import PatientTurn from "../dashboard/patient-turn";
import ApartmentIcon from "@material-ui/icons/Apartment";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { useSelector } from "react-redux";

const formatTime = (timeString) => {
  if (!timeString) {
    return "N/A";
  }
  return timeString.lastIndexOf(":") === 5
    ? timeString.substring(0, 5)
    : timeString;
};

const formatCheckInTime = (timeString) => {
  if (!timeString) {
    return "N/A";
  }
  return `${timeString.slice(0, 2)}:${timeString.slice(2, 4)}`;
};

const useStyles = makeStyles((theme) => ({
  queue: {
    width: "40px",
    textAlign: "center",
  },
  container: {
    margin: "20px auto",
    width: "96%",
    padding: theme.spacing(8),
    borderRadius: theme.spacing(0, 6, 6, 6),
  },
  textStyle: {
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.8",
    fontSize: "0.7rem",
    width: "10%",
  },

  textMoreStyle: {
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.8",
    fontSize: "0.7rem",
    width: "4%",
  },
  cellTextStyle: {
    color: theme.palette.common.black,
    fontSize: "0.7rem",
  },
  calledCellStyle: {
    backgroundColor: "#faebd7 ",
  },
  icon: {
    margin: theme.spacing(0, 4),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    opacity: "0.8",
    padding: theme.spacing(4),
    "&:hover": {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      opacity: "0.6",
    },
    "&:disabled": {
      border: "1px solid rgba(0, 0, 0, 0.26)",
    },
  },
  paginationToolbar: {
    width: '10px', 
  },
}));

const AppointmentList = ({

  page,
  pageSize,
  rows,
  count,
  className,
  onCallNextBtnClicked,
  onReviewBtnClicked,
  handleChangeStatus,
  onQueueUpdateClicked,
  handleChangePage,
  onDateTimeClicked,
  onViewPrescriptionClicked,
  onAddDiscountClicked,
  onPrintReceiptClicked,
  onViewEMRClicked,
  onPrintLetterHeadClicked,
  handleOpenPrescription,
  onNotifyClicked,
  summary,
  handleOpen,
  handlenofify,
  handleNotifyDate,
  setApiStatus,
}) => {
  const config = AuthService.getConfig();

  const location = useLocation();
  const isPrimaryProfile = useSelector(state => state?.switchProfile?.isPrimaryProfile);

  const moreItems = [
    {
      label: "View EMR",
      onClick: onViewEMRClicked,
      isVisible: isCurrentRole(AppConstants.ROLE.DOCTOR),
    },
    {
      label: "View Prescription",
      onClick: onViewPrescriptionClicked,
      isVisible: true,
    },
    { label: "Add Discount", onClick: onAddDiscountClicked, isVisible: true },
    { label: "Print Receipt", onClick: onPrintReceiptClicked, isVisible: true },
    {
      label: "Print LetterHead Only",
      onClick: onPrintLetterHeadClicked,
      isVisible: true,
    },
    { label: "Notify", onClick: onNotifyClicked, isVisible: true },
  ];

  const [isMsgDialogVisible, setIsMsgDialogVisible] = useState(false);
  const [remarks, setRemarks] = useState(null);
  const [status, setStatus] = useState({});
  const appointmentStatus = useRecoilValue(AppointmentsStatusState);
  const user = AuthService.getUser();
  const appointmentList = useRecoilValue(AppointmentsState);
  const [showModal, setShowModal] = useState(false);
  const [calledUser, setCalledUser] = useState([]);

  const classes = useStyles();
  const onRemarksBtnClicked = (remarks) => {
    setRemarks(remarks);
    setIsMsgDialogVisible(true);
  };

  const handleMenu = (row) => {
    moreItems.forEach(function (option) {
      if (row.prescription === null && option.label === "View Prescription") {
        option.isVisible = false;
      } else if (option.label !== "View EMR") {
        option.isVisible = true;
      }
    });
  };

  useEffect(() => {
    setStatus((prev) => {
      const allStatus = JSON.parse(JSON.stringify(prev));

      rows.forEach((row) => {
        allStatus[row.slug] = row.status;
      });
      return allStatus;
    });
  }, [rows]);

  useEffect(() => {
    if (rows.length) {
      const calledUserList = JSON.parse(localStorage.getItem("allreadyCalled"));
      const calledPatient = rows?.filter((pat) => {
        return pat?.called;
      });
      const toBeCalled = calledPatient?.filter((el) => {
        return !calledUserList?.find((element) => {
          return element === el?.id;
        });
      });
      toBeCalled && handlePopUp(toBeCalled);
    }
  }, [rows]);

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handlePopUp = async (patients) => {
    const patientTurn = patients;
    const localStore = localStorage.getItem("allreadyCalled");
    var updateLocalStore = [];
    if (localStore) {
      updateLocalStore = JSON.parse(localStore);
    }

    for (let i = 0; i < patientTurn.length; i++) {
      setCalledUser(patientTurn[i]);
      setShowModal(true);
      updateLocalStore.push(patientTurn[i]?.id);
      localStorage.setItem("allreadyCalled", JSON.stringify(updateLocalStore));

      await timeout(4500);
      setShowModal(false);

      await timeout(500);
    }
  };

  const getLimitedRemarks = (remarks) => {
    return remarks.length > 255
      ? remarks.substring(0, 255) + " . . ."
      : remarks;
  };
  if (!rows || rows.length <= 0) {
    return (
      <Box className={className ? className : classes.container}>
        <Typography variant="h6">No appointments to show!</Typography>
      </Box>
    );
  }

  const isQueueUser = user.roleName === AppConstants.ROLE.QUEUE;
  let appointmentDashboardPage = false;

  if (location.pathname !== "/app/dashboard") {
    appointmentDashboardPage = true;
  } else if (location.pathname === "/app/dashboard" && !isQueueUser) {
    appointmentDashboardPage = true;
  }

  const checkChangedToConfirm = (row) => {
    return (
      appointmentStatus[row.slug] && appointmentStatus[row.slug] === "CONFIRMED"
    );
  };

  const getLabel = (row) => {
    if (
      appointmentStatus[row.slug] &&
      appointmentStatus[row.slug] === "CONFIRMED"
    ) {
      return row.labels?.filter(({ label }) => label !== "Called");
    }
    setApiStatus(true);
    return row.labels;
  };

  return (
    <>
      <TableContainer
        component={Paper}
        className={className ? className : classes.container}
      >
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell component="th" className={classes.textStyle}>
                Queue
              </TableCell>
              <TableCell component="th" className={classes.textStyle}>
                Date & Time
              </TableCell>
              <TableCell component="th" className={classes.textStyle}>
                Check In Time
              </TableCell>
              <TableCell component="th" className={classes.textStyle}>
                Doctor
              </TableCell>
              <TableCell component="th" className={classes.textStyle}>
                Patient Name
              </TableCell>
              <TableCell component="th" className={classes.textStyle}>
                Number / UHID
              </TableCell>
              <TableCell component="th" className={classes.textStyle}>
                Status
              </TableCell>

              {/* <TableCell component='th' className={classes.textStyle}>
                Type
              </TableCell> */}
              {!summary &&
                isAuthorised(AppConstants.ENTITY.CONSULTATION) &&
                appointmentDashboardPage && (
                  <TableCell component="th" className={classes.textStyle}>
                    Consultation
                  </TableCell>
                )}
              {!summary &&
                isAuthorised(AppConstants.ENTITY.CHECK_IN) &&
                appointmentDashboardPage &&  (
                  <TableCell component="th" className={classes.textStyle}>
                    Action
                  </TableCell>
                )}
              {!summary && appointmentDashboardPage && isPrimaryProfile && (
                <TableCell component="th" className={classes.textStyle}>
                  More
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(status).length &&
              (!appointmentDashboardPage
                ? rows?.filter((row) => row?.status === "CHECKED_IN")
                : rows
              )?.map((row, index) => (
                <TableRow
                  className={
                    row.called
                      ? checkChangedToConfirm(row)
                        ? ""
                        : classes.calledCellStyle
                      : ""
                  }
                  key={row.id}
                >
                  {!appointmentDashboardPage ? (
                    <TableCell className={classes.cellTextStyle}>
                      <Box>{row.queue === 0 ? "NA" : row.queue}</Box>
                    </TableCell>
                  ) : (
                    <TableCell className={classes.cellTextStyle}>
                      <TextInput
                        required
                        className={classes.queue}
                        // containerClassName="text-center"
                        field="queue"
                        InputProps={{ inputProps: { min: 1 } }}
                        initialValue={row.queue === 0 ? "NA" : row.queue}
                        onChange={(e) => onQueueUpdateClicked(e, row)}
                        variant="standard"
                        validateOnBlur
                        validateOnChange
                      />
                    </TableCell>
                  )}
                  <TableCell
                    className={classes.cellTextStyle}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      appointmentDashboardPage && onDateTimeClicked(row);
                    }}
                  >
                    {getFormattedDate(row.date, "DD MMM")},{" "}
                    {formatTime(row.time)}
                  </TableCell>
                  <TableCell className={classes.cellTextStyle}>
                    {formatCheckInTime(row?.checkInTime)}
                  </TableCell>
                  <TableCell className={classes.cellTextStyle}>
                    {row?.doctor?.name ?? ""}
                  </TableCell>
                  <TableCell className={classes.cellTextStyle}>
                    {appointmentDashboardPage
                      ? row.patient?.name ?? ""
                      : row?.patient?.name
                          ?.split("")
                          ?.map((word, index) => (index < 4 ? word : "*"))}
                  </TableCell>
                  <TableCell className={classes.cellTextStyle}>
                    {row?.patient?.mobileNumber ? (
                      <a href={`tel: ${row?.patient?.mobileNumber}`}>
                        {" "}
                        {row?.patient?.mobileNumber}{" "}
                      </a>
                    ) : null}
                    {appointmentDashboardPage
                      ? row.patient?.uhid ?? ""
                      : row?.patient?.uhid
                          ?.split("")
                          ?.map((word, index) =>
                            index >
                            row?.patient?.mobileNumber?.split("")?.length - 5
                              ? word
                              : "*"
                          )}
                  </TableCell>
                  <TableCell>
                    <Label
                      statusList={getLabel(row)}
                      paymentstatus={row?.order?.status}
                      paymentMode={row?.order?.paymentDetails?.paymentMode}
                      customStatus={row?.customStatus}
                    />
                  </TableCell>

                  {!summary && appointmentDashboardPage && (
                    <TableCell>
                      <Box display="flex">
                        <Tooltip title="Report">
                          <span>
                            <IconButton
                              className={classes.icon}
                              onClick={() =>
                                onReviewBtnClicked(row.patientSlug, row.slug)
                              }
                            >
                              <DescriptionIcon />
                            </IconButton>
                          </span>
                        </Tooltip>

                        {isAuthorised(AppConstants.ENTITY.CALL_NEXT) &&
                          isAuthorised(AppConstants.ENTITY.CONSULTATION) && isPrimaryProfile && (
                            <Tooltip title="Call Next">
                              <span>
                                <IconButton
                                  disabled={
                                    row.status === "NO_SHOW" ||
                                    row.status === "CANCELLED" ||
                                    (row.called && !checkChangedToConfirm(row))
                                  }
                                  onClick={onCallNextBtnClicked.bind(
                                    this,
                                    row.slug
                                  )}
                                  className={classes.icon}
                                >
                                  <img
                                    height="16"
                                    alt="call-next"
                                    src={
                                      row.status === "NO_SHOW" ||
                                      row.status === "CANCELLED" ||
                                      row.called
                                        ? CallNextIconW
                                        : CallNextIcon
                                    }
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}
                        <Tooltip title="Consult">
                          <span>
                            <IconButton
                              disabled={
                                row.status === "NO_SHOW" ||
                                row.status === "CANCELLED"
                              }
                              className={classes.icon}
                              onClick={() => handleOpenPrescription(row.slug)}
                            >
                              <Icon />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title="Custom_Status">
                          <span>
                            <IconButton
                              className={classes.icon}
                              onClick={() => handleOpen(row?.id)}
                            >
                              <ApartmentIcon />
                            </IconButton>
                          </span>
                        </Tooltip>

                        {isPrimaryProfile && <Tooltip title="notify">
                          <span>
                            <IconButton
                              className={classes.icon}
                              onClick={() => handlenofify(row?.patientSlug)}
                            >
                              <NotificationsActiveIcon />
                            </IconButton>
                          </span>
                        </Tooltip>}
                        {row.remarks && (
                          <LightTooltip title={getLimitedRemarks(row.remarks)}>
                            <span>
                              <IconButton
                                className={classes.icon}
                                onClick={onRemarksBtnClicked.bind(
                                  this,
                                  row.remarks
                                )}
                              >
                                <InfoIcon />
                              </IconButton>
                            </span>
                          </LightTooltip>
                        )}
                      </Box>
                    </TableCell>
                  )}
                  {!summary &&
                    isAuthorised(AppConstants.ENTITY.CHECK_IN) &&
                    appointmentDashboardPage && (
                      <TableCell>
                        <Box
                          alignItems="center"
                          display="flex"
                          flexDirection="row"
                          style={{ width: "80%" }}
                        >
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <InputLabel htmlFor="filled-age-native-simple">
                              Action
                            </InputLabel>
                            <Select
                              id="demo-simple-select-outlined"
                              style={{ width: 180 }}
                              required
                              value={status[row.slug] ?? row.status}
                              onChange={(e) => {
                                if(row?.patient?.isBlocked){
                                  SHOW_ERROR_NOTIFICATION("Patient is blocked");
                                  return;
                                } else {
                                  setStatus((prev) => ({
                                    ...prev,
                                    [row.slug]: e.target.value,
                                  }));
                                  handleChangeStatus(e, row);
                                }
                              }}
                              label="Age"
                            >
                              <MenuItem value={"CONFIRMED"}>CONFIRMED</MenuItem>
                              {config?.appointmentStatus?.map(
                                (option, index) => {
                                  return (
                                    <MenuItem key={index} value={option}>
                                      {option}
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      </TableCell>
                    )}
                  {!summary && appointmentDashboardPage && isPrimaryProfile && (
                    <TableCell>
                      <OverFlowMenu
                        appointmentObj={row}
                        onClick={() => handleMenu(row)}
                        options={moreItems}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              {count > 0 ? (
                <TablePagination
                  rowsPerPageOptions={[5]}
                  page={page}
                  rowsPerPage={pageSize}
                  count={count}
                  onChangePage={handleChangePage}
                  ActionsComponent={TablePaginationActions}
                  classes={{
                    toolbar: classes.paginationToolbar, 
                  }}
                />
              ) : null}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {isQueueUser && showModal && <PatientTurn calledPatient={calledUser} />}
      <MessageDialog
        handleClose={() => {
          setIsMsgDialogVisible(false);
        }}
        headerTitle="Remarks"
        open={isMsgDialogVisible}
        message={remarks}
      />
    </>
  );
};

export default AppointmentList;
