import FilterOptions from "../../utils/filter-options";
import AuthService from "../../utils/services/auth-service";
import { isCurrentRole } from "../../utils/role-util";
import AppConstants from "../../utils/app-constants";

class PatientColumn {
  constructor(isEditMode) {
    this.allColumns = [
      {
        field: "_",
        colId: "_",
        hide: true,
        lockVisible: true,
        filter: "agTextColumnFilter",
        filterParams: {
          newRowsAction: "keep",
        },
      }
    ].concat(
      allFields().map((field) => {
        return {
          field: field.name,
          colId: field.name,
          headerName: field.displayName,
          editable: isEditMode && !field.locked,
          sortable: true,
          // ...patientFilters.chooseAptFilter(field.dataType),
          pinned: field.pinned ? "left" : "",
          cellRendererSelector: function (params) {
            return {
              component: "customRenderer",
              params: {
                headerName: field.displayName,
                dataType: field.dataType,
                currentValue: params.value,
                context: params.context,
              },
            };
          },
          cellEditorSelector: function (params) {
            return {
              component: "customEditor",
              params: {
                dataType: field.dataType,
                currentValue: params.value,
                validation: field.validation,
                collectionName: field.collectionName,
                columnName: field.columnName,
                allValues: field.allValues,
              },
            };
          },
        };
      })
    );
  }

  generateColumns() 
  {
    this.allColumns.splice(6, 0, {
        field: "icon",
        colId: "icon",
        headerName: "Prescription",
        editable: false,
        sortable: false,
        cellRendererSelector: function (params) {
          return {
            component: "customRenderer",
            params: {
              headerName: "View",
              dataType: "ICON",
              currentValue: params.value,
              context: params.context,
            },
          };
        },

      });

      this.allColumns.splice(7, 0, {
        field: "icon",
        colId: "icon",
        headerName: "Chat",
        editable: false,
        sortable: false,
        cellRendererSelector: function (params) {
          
          return {
            component: "customRenderer",
            params: {
              headerName: "Chat",
              dataType: "CHAT",
              currentValue: params.value, 
              context: params.context,
            },
          };
        },
      });
      this.allColumns.splice(8, 0, {
        field: "icon",
        colId: "icon",
        headerName: "Notify",
        editable: false,
        sortable: false,
        cellRendererSelector: function (params) {
          return {
            component: "customRenderer",
            params: {
              headerName: "Notify",
              dataType: "MAIL",
              currentValue: params.value, 
              context: params.context,
            },
          };
        },
      });

      this.allColumns.splice(9, 0, {
        field: "icon",
        colId: "icon",
        headerName: "Logs",
        editable: false,
        sortable: false,
        cellRendererSelector: function (params) {
          
          return {
            component: "customRenderer",
            params: {
              headerName: "Logs",
              dataType: "LOGS",
              currentValue: params.value, 
              context: params.context,
            },
          };
        },
      });
      this.allColumns.splice(10, 0, {
        field: "icon",
        colId: "icon",
        headerName: "Report",
        editable: false,
        sortable: false,
        cellRendererSelector: function (params) {

          return {
            component: "customRenderer",
            params: {
              headerName: "Report",
              dataType: "REPORT",
              currentValue: params.value, 
              context: params.context,
            },
          };
        },
      });
      this.allColumns.splice(15, 0, {
        field: "isBlocked",
        colId: "isBlocked",
        headerName: "Block Status",
        editable: false,
        sortable: true,
        valueFormatter: (params) => (
          params.value ? "Block" : "Unblock"
        ),
        cellRendererSelector: function (params) {
          return {
            component: "customRenderer",
            params: {
              headerName: "Block",
              dataType: "BLOCK",
              currentValue: params.value,
              context: params.context,
            },
          };
        },
      });
      // this.allColumns.splice(10, 0, {
      //   field: "icon",
      //   colId: "icon",
      //   headerName: "Pay_at_hospital",
      //   editable: false,
      //   // editable: isEditMode && !field.locked,
      //   sortable: false,
      //   cellRendererSelector: function (params) {

      //     return {
      //       component: "customRenderer",
      //       params: {
      //         headerName: "Pay_at_hospital",
      //         dataType: "HOSPITAL",
      //         currentValue: params.value, 
      //         context: params.context,
      //       },
      //     };
      //   },
      // });
      
    return this.allColumns
  }
}


const allFields = () => {
  const config = AuthService.getConfig();
  return config["patientField"];
};

const patientFilters = new FilterOptions({ collection: "patient" });

export default PatientColumn;
