import Api from "../utils/api";
import { useMutation } from "@tanstack/react-query";
import { SHOW_SUCCESS_NOTIFICATION, SHOW_ERROR_NOTIFICATION } from '../utils/app-util';

export const DeleteReport = () => {
    return useMutation({
        mutationFn: async (id) => {
            const response = await Api.delete(`/report/${id}`).toPromise();
            return response.data;
        },
        onSuccess: (data) => {
            if (data.status === true) {
                SHOW_SUCCESS_NOTIFICATION(data.message);
            }
        },
        onError: (error) => {
            if (error?.response?.data?.message) {
                SHOW_ERROR_NOTIFICATION(error?.response?.data?.message);
            } else {
                SHOW_ERROR_NOTIFICATION("An error occurred while deleting the report");
            }
        }
    })
}