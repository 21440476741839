import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import moment from "moment";

const INPUT_OUTPUT_KEY = {
  INPUT: "input",
  OUTPUT: "output"
}

const InputOutputTable = ({ content }) => {
  if (content?.content) {
    content.content = content?.content?.filter(
      (item) => item?.historyType !== "prescription"
    );
  }
  // Check if content and content.content exist
  if (!content || !content.content || content.content.length === 0) {
    return (
      <Box p={2}>
        <Typography>No data available</Typography>
      </Box>
    );
  }

  // Group rows by date
  const groupedContent = content.content.reduce((acc, row) => {
    const date = moment(row.createdAt).format("DD-MM-YYYY");
    if (!acc[date]) {
      acc[date] = [];
    }
    // acc[date].push(row);
    acc[date].push(row);
    return acc;
  }, {});

  // Render the tables
  return (
    <>
      {/* Table 1: Vitals */}
      <TableContainer
        style={{
          backgroundColor: "#fff",
          borderRadius: "10px",
          border: "1px solid grey",
          flex: 1,
          overflowY: "auto",
          marginTop: "20px",
        }}
      >
        <Table size="small" aria-label="vitals table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ borderRight: "1px solid grey" }}
                align="center"
              >
                Pr
              </TableCell>
              <TableCell
                style={{ borderRight: "1px solid grey" }}
                align="center"
              >
                BP
              </TableCell>
              <TableCell
                style={{ borderRight: "1px solid grey" }}
                align="center"
              >
                SPO2
              </TableCell>
              <TableCell
                style={{ borderRight: "1px solid grey" }}
                align="center"
              >
                Temp
              </TableCell>
              <TableCell
                style={{ borderRight: "1px solid grey" }}
                align="center"
              >
                RR
              </TableCell>
              <TableCell
                style={{ borderRight: "1px solid grey" }}
                align="center"
              >
                Pain Score
              </TableCell>
              <TableCell
                style={{ borderRight: "1px solid grey" }}
                align="center"
              >
                RBS
              </TableCell>
              <TableCell
                style={{
                  borderRight: "1px solid grey",
                  width: "100px",
                  whiteSpace: "nowrap",
                }}
                align="center"
              >
                Username
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(groupedContent).map(([date, rows]) => (
              <React.Fragment key={date}>
                <TableRow>
                  <TableCell
                    colSpan={8}
                    align="center"
                    style={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                  >
                    {date}
                  </TableCell>
                </TableRow>
                {rows.map((row, index) => (
                  <TableRow key={`${date}-${index}`}>
                    <TableCell
                      align="right"
                      style={{ borderRight: "1px solid grey" }}
                    >
                      {row?.vitals?.pulseRate}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ borderRight: "1px solid grey" }}
                    >
                      {row?.vitals?.bloodPressure}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ borderRight: "1px solid grey" }}
                    >
                      {row?.vitals?.oxygenSaturation}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ borderRight: "1px solid grey" }}
                    >
                      {row?.vitals?.temperature}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ borderRight: "1px solid grey" }}
                    >
                      {row?.vitals?.respirationRate}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ borderRight: "1px solid grey" }}
                    >
                      {row?.vitals?.ps}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ borderRight: "1px solid grey" }}
                    >
                      {row?.vitals?.remarks}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ borderRight: "1px solid grey" }}
                    >
                      {row?.createdByName}
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Table 2: Input/Output */}
      <TableContainer
        style={{
          backgroundColor: "#fff",
          borderRadius: "10px",
          border: "1px solid grey",
          flex: 1,
          overflowY: "auto",
          marginTop: "20px",
        }}
      >
        <Table size="small" aria-label="input-output table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ borderRight: "1px solid grey" }}
                align="center"
              >
                Input
              </TableCell>
              <TableCell
                style={{ borderRight: "1px solid grey" }}
                align="center"
              >
                Input Type
              </TableCell>
              <TableCell
                style={{ borderRight: "1px solid grey" }}
                align="center"
              >
                Output
              </TableCell>
              <TableCell
                style={{ borderRight: "1px solid grey" }}
                align="center"
              >
                Output Type
              </TableCell>
              <TableCell
                style={{
                  borderRight: "1px solid grey",
                  width: "100px",
                  whiteSpace: "nowrap",
                }}
                align="center"
              >
                Username
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(groupedContent).map(([date, rows]) => (
              <React.Fragment key={date}>
                <TableRow>
                  <TableCell
                    colSpan={5}
                    align="center"
                    style={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}
                  >
                    {date}
                  </TableCell>
                </TableRow>
                {rows.map((row, index) => (
                  <TableRow key={`${date}-${index}`}>
                    <TableCell
                      align="right"
                      style={{ borderRight: "1px solid grey" }}
                    >
                      {row?.inputOutput?.inputOutputType === INPUT_OUTPUT_KEY.INPUT
                        ? `${row?.inputOutput?.volume}${row?.inputOutput?.unit}`
                        : ""}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ borderRight: "1px solid grey" }}
                    >
                      {row?.inputOutput?.inputOutputType === INPUT_OUTPUT_KEY.INPUT
                        ? row?.inputOutput?.valueType
                        : ""}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ borderRight: "1px solid grey" }}
                    >
                      {row?.inputOutput?.inputOutputType === INPUT_OUTPUT_KEY.OUTPUT
                        ? `${row?.inputOutput?.volume}${row?.inputOutput?.unit}`
                        : ""}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ borderRight: "1px solid grey" }}
                    >
                      {row?.inputOutput?.inputOutputType === INPUT_OUTPUT_KEY.OUTPUT
                        ? row?.inputOutput?.valueType
                        : ""}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ borderRight: "1px solid grey" }}
                    >
                      {row?.createdByName}
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default InputOutputTable;
