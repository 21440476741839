import Api from "../utils/api";
import { firstValueFrom } from "rxjs";

class SearchAPI {
  static async searchDoctors(inputValue, searchColumns, page = 0, size = 20) {
    const payload = {
      page: page,
      size: size,
      filterModel: {
        _: {
          filterType: "text",
          type: "",
          filter: inputValue,
          fields: searchColumns || [],
        },
        roleName: {
          filterType: "set",
          values: ["DOCTOR", "DOCTOR_ADMIN"],
        }
      },
      sortModel: [],
    };

    try {
      const response = await firstValueFrom(Api.post(`doctor/search`, payload));
      return response?.data?.data?.content || [];
    } catch (error) {
      console.error("Error searching doctors:", error);
      throw error;
    }
  }

  static async searchPatients(inputValue, searchColumns, userSlug, page = 0, size = 20) {
    const payload = {
      page: page,
      size: size,
      filterModel: {
        _: {
          filterType: "text",
          type: "",
          filter: inputValue,
          fields: searchColumns || [],
        },
        custom: {
          filterType: "exact",
          filter: `doctorPatients::slug::${userSlug}`
        },
      },
      sortModel: [],
    };

    try {
      const response = await firstValueFrom(Api.post(`patient/search`, payload));
      return response?.data?.data?.content || [];
    } catch (error) {
      console.error("Error searching patients:", error);
      throw error;
    }
  }

  static async searchStaff(inputValue, page) {
    const payload = {
      page: page,
      size: 25,
      filterModel: {
        _: {
          filterType: "text",
          type: "",
          filter: inputValue,
          fields: ["name", "roleName"],
        },
      },
      sortModel: [],
    };

    try {
      const response = await firstValueFrom(Api.post(`doctor/search`, payload));
      return response?.data?.data || [];
    } catch (error) {
      console.error("Error searching staff:", error);
      throw error;
    }
  }
}

export default SearchAPI;
