import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { getSocket } from "../../components/socket/socket.js";
import { SocketEvents } from "../../components/socket/socketEvents.js";
import ChatListDesktop from "../../components/chat/ChatListDesktop.jsx";
import ChatListMobile from "../../components/chat/ChatListMobile.jsx";
import AppConstants from "../../utils/app-constants";
import { LoadingOverlay } from "../../components/common/LoadingOverlay.jsx";
import Header from "../../components/layout/header.jsx";

const ChatInitiateScreen = () => {
  const user = JSON.parse(localStorage.getItem(AppConstants.USER_KEY));
  const [chatList, setChatList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingOverlay, setLoadingOverlay] = useState(true);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const socket = getSocket();

  function onConnect() {
    console.log("socket connection established");
  }

  function onConnectionError(err) {
    console.log(err.message);
  }

  // TODO:Make this logic beter to attempt reconnect
  function onDisconnect() {
    console.log("socket disconnected");
    if (socket.active) {
      console.log("Socket connection active. Attempting Reconnect...");
    } else {
      console.log("Socket connection forcefully close");
    }
  }

  async function handleChatList(chatListData) {
    await setChatList(chatListData ? chatListData : chatList);
    await setLoading(false);
    await setLoadingOverlay(false);
  }

  const handleRefreshClick = async () => {
    socket.emit(SocketEvents.FETCH_CHAT_LIST, user?.slug);
  };

  // Socket events. Connect/Disconnect/Emission/Listen/
  useEffect(() => {
    setLoading(true);

    socket.on(SocketEvents.CONNECT, onConnect);
    socket.on(SocketEvents.CONNECTION_ERROR, onConnectionError);
    socket.on(SocketEvents.DISCONNECT, onDisconnect);
    socket.on(SocketEvents.POST_CHAT_LIST, handleChatList);

    socket.emit(SocketEvents.FETCH_CHAT_LIST, user?.slug);

    return () => {
      socket.off(SocketEvents.CONNECT, onConnect);
      socket.off(SocketEvents.DISCONNECT, onDisconnect);
      socket.off(SocketEvents.POST_CHAT_LIST, handleChatList);
    };
  }, []);
  return (
    <Box
      sx={{
        backgroundColor: "rgba(245, 245, 245, 0.6)",
        p: isMobile ? 0 : 4,
        //TODO:Better solution here
        ml: isMobile ? 0 : -4,
      }}
    >
      <Box
        sx={{
          marginTop: isMobile ? "-20px" : "-30px",
          marginLeft: isMobile ? "-20px" : "-30px",
        }}
      >
        <Header title="Chat" />
      </Box>
      {loadingOverlay && <LoadingOverlay />}
      {!loadingOverlay && (
        <Box>
          <Box
            component={isMobile ? Box : Paper}
            sx={{
              width: "100%",
              padding: isMobile ? 0 : "15px",
            }}
          >
            {isMobile ? (
              <ChatListMobile
                socket={socket}
                chatList={chatList}
                loading={loading}
                handleRefreshClick={handleRefreshClick}
              />
            ) : (
              <ChatListDesktop
                socket={socket}
                chatList={chatList}
                loading={loading}
                handleRefreshClick={handleRefreshClick}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ChatInitiateScreen;
