import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles, Typography,

  Button, TableCell,
  Paper,
  Table,
  TableContainer,
  TablePagination,
  TableHead,
  TableBody,
  TableRow
} from "@material-ui/core";
import { DEFAULT_ERROR_CALLBACK } from '../../utils/app-util';

import { TextInput } from 'sixsprints-react-ui';
import Header from "../../components/layout/header";
import Api from "../../utils/api";


import { NotificationManager } from 'react-notifications';
import AppConstants from "../../utils/app-constants";




import EditIcon from "@material-ui/icons/Edit";

import Modal from '@material-ui/core/Modal';
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { Form } from "informed";
import useGlobalStyles from "../../utils/global-style";
import Loader from "../../components/misc/loader";
import useDocumentTitle from "../../hooks/useDocumentTitle";



const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50vh",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
  },
  modalStyle_1: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 375,
    width: "100%",
    height: "85%",
    background: "white",
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
    overflowX: "scroll",
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  },
  queue: {
    width: '40px',
    textAlign: 'center',
  },
  container: {
    margin: '20px auto',
    width: '96%',
    padding: theme.spacing(8),
    borderRadius: theme.spacing(6, 6, 6, 6),
  },
  textStyle: {
    color: theme.palette.common.black,
    fontWeight: 'bold',
    opacity: '0.8',
    fontSize: '0.7rem',
    width: '10%',
  },

  textMoreStyle: {
    color: theme.palette.common.black,
    fontWeight: 'bold',
    opacity: '0.8',
    fontSize: '0.7rem',
    width: '4%',
  },
  cellTextStyle: {
    color: theme.palette.common.black,
    fontSize: '0.7rem',
  },
  calledCellStyle: {
    backgroundColor: '#faebd7 ',
  },
  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "15px",
    marginTop: "20px"
  },
  icon: {
    margin: theme.spacing(0, 4),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    opacity: '0.8',
    padding: theme.spacing(4),
    '&:hover': {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      opacity: '0.6',
    },
    '&:disabled': {
      border: '1px solid rgba(0, 0, 0, 0.26)',
    },
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
}));



const ActivityRegistry = () => {
  // set document title
  useDocumentTitle("Activity Registry");

  const globalClass = useGlobalStyles();
  const classes = useStyles();
  const [id, setId] = useState(null)

  const [addModelOpen, setaddModelOpen] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState('')




  const addhandleClose = () => setaddModelOpen(false);


  // const updatehandleOpen = (item) => (setOpen(true));

  const handleClose = () => setOpen(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };



  const [resp, setResp] = useState([])




  const [addfield, setAddField] = useState([{
    name: "",
    price: ""

  },
  ])
  const [updatefield, setUpdateField] = useState([{
    name: "",
    price: "",
    status: ""

  },
  ])

  const handleAddchange = (event) => {
    setAddField({
      ...addfield,
      [event.target.name]: event.target.value,
    });
  };
  const handleUpdatechange = (event) => {
    setUpdateField({
      ...updatefield,
      [event.target.name]: event.target.value,
    });
  };
  const addhandleOpen = () => {
    setaddModelOpen(true)
    addfield.name = "";
    addfield.price = "";
  }


  const updatehandleOpen = (item) => {
    console.log("item", item)
    setOpen(true)
    setId(item.id)

    setUpdateField(prevState => ({

      ...prevState,

      name: item.name,
      price: item.price,
      status: item.status



    }));




  }

  const [lodingStatus, setLoadingStatus] = useState({
    logoLodingStatus: false,
  });




  const handleSaveData = () => {

    setLoadingStatus({
      logoLodingStatus: true,
    });


    if (addfield.name === "" ||
      addfield.price === ""
    ) {
      NotificationManager.error(AppConstants.MSG.ALL_FIELDS_REQUIRED);
      setLoadingStatus({
        logoLodingStatus: false,
      });
    } else {
      try {
        Api.post("/admit/activity/add", addfield).subscribe((response) => {


          if (response.data.status == true) {


            setLoadingStatus({
              logoLodingStatus: false,
            });


            setaddModelOpen(false);
            NotificationManager.success(response.data.message);



          }
          else {
            setOpen(false);
            setLoadingStatus({
              logoLodingStatus: false,
            });

            NotificationManager.success(response.data.message);

          }

          //   handleClosee();

        })
      } catch (error) {
        DEFAULT_ERROR_CALLBACK(error);
      }
    }

  }



  const onUpdateData = async (id) => {

    setLoadingStatus({
      logoLodingStatus: true,
    });

    if (
      updatefield.name === "" ||
      updatefield.price === ""

    ) {
      NotificationManager.error(AppConstants.MSG.ALL_FIELDS_REQUIRED);
      setLoadingStatus({
        logoLodingStatus: false,
      });
    } else {

      try {
        await Api.put(`/admit/activity/edit/${id}`, updatefield).subscribe((response) => {

          if (response.data.status === true) {
            setOpen(false);

            setLoadingStatus({
              logoLodingStatus: false,
            });
            NotificationManager.success(response.data.message);
          } else {
            NotificationManager.success(response.data.message);
            setLoadingStatus(false);
          }
        });
      } catch (error) {
        setOpen(false);
        setLoadingStatus({
          logoLodingStatus: false,
        });
        console.log(error);
      }
    }

  };











  const [content, setContent] = useState({})
  useEffect(() => {
    Api.post('/admit/activity/search', {
      "size": rowsPerPage,
      "page": page,
      "filterModel": {
        "status": {
          "type": "equals",
          "value": "Active"
        }
      }
    }).subscribe((resp) => {
      if (resp) {
        console.log("test_getactivity", resp);

        setResp(resp.data.data.content);
        setContent(resp?.data?.data)



      }

    })
  }, [lodingStatus.logoLodingStatus, page, rowsPerPage]);


  return (
    <>
      <Header title="Activity Registry" />


      {
        resp === null ? (<Loader />) : (
          <TableContainer
            component={Paper}

            className={classes.container}
          >
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<AddIcon />}
                onClick={addhandleOpen}
              >
                ADD
              </Button>
            </Box>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell component='th' className={classes.textStyle} >RX</TableCell>
                  <TableCell component='th' className={classes.textStyle}>Name</TableCell>
                  <TableCell component='th' className={classes.textStyle}>Price</TableCell>
                  <TableCell component='th' className={classes.textStyle}></TableCell>



                </TableRow>
              </TableHead>

              <TableBody>
                {content?.content?.map((item, index) => {
                  return (
                    <>
                      <TableRow key={index} style={{ padding: "5%" }}>

                        <TableCell className={classes.cellTextStyle} style={{ padding: "2%" }} >
                          <Typography> {index + 1}</Typography>
                        </TableCell>
                        <TableCell className={classes.cellTextStyle} style={{ padding: "2%" }}>
                          <Typography>{item.name}</Typography>
                        </TableCell>
                        <TableCell className={classes.cellTextStyle} style={{ padding: "2%" }}>
                          <Typography >{item.price}</Typography>
                        </TableCell>
                        <TableCell className={classes.cellTextStyle} style={{ padding: "2%" }}>
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            startIcon={<EditIcon />}
                            onClick={(e) => updatehandleOpen(item)}>

                            Update
                          </Button>
                        </TableCell>



                      </TableRow>
                    </>
                  )
                })}

                <TablePagination
                  rowsPerPageOptions={[1, 10, 25, 50, 100]}
                  count={content?.totalElements}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />


              </TableBody>
            </Table>
          </TableContainer>
        )
      }



      {/* <Box sx={{ overflow: 'hidden', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr', gap: '9px', marginTop: '20px' }}>

        <TextField type="text" required value={addPatientData.name} name="name" onChange={handleadmitpatient} style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 5px 20px #00000008", borderRadius: 14, marginTop: 5, marginRight: 10, }} id="outlined-basic" label="Activity Name" variant="outlined" />
        <TextField type="number" required value={addPatientData.price} name="price" onChange={handleadmitpatient} style={{ background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 5px 20px #00000008", borderRadius: 14, marginTop: 5, marginRight: 10, }} id="outlined-basic" label="Price" variant="outlined" />
      </Box> */}

      {
        addModelOpen &&
        <Modal
          open={addModelOpen}
          onClose={addhandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle_1} style={{ background: "white" }}>
            <Typography variant="h4" component="h2">Add Activity Registry</Typography>
            <CloseIcon onClick={addhandleClose} style={{ float: 'right', marginBottom: "7px", cursor: "pointer" }} />

            <Form className={classes.form}>
              <Box className={classes.staffDetailsForm}>
                <Box mb='0.5rem'>
                  <Typography variant="h6" className={classes.prescriptionTitle}>
                    Name
                  </Typography>
                  <TextInput
                    className={classes.style_1}
                    field='name'
                    type='text'
                    label=' Name'
                    validateOnBlur
                    validateOnChange
                    name='name'
                    value={addfield.name}
                    onChange={handleAddchange}
                  />
                </Box>
                <Box mb='0.5rem'>
                  <Typography variant="h6" className={classes.prescriptionTitle}>
                    Price
                  </Typography>
                  <TextInput
                    className={classes.style_1}
                    field='price'
                    type='number'
                    label='price'
                    validateOnBlur
                    validateOnChange
                    name='price'
                    value={addfield.price}
                    onChange={handleAddchange}

                  />
                </Box>


              </Box>
              <Box mx='.4rem' my={12} className={classes.submitBtn}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  className={classes.btn}
                  onClick={handleSaveData}
                >
                  {lodingStatus.logoLodingStatus ? (
                    <Loader width="200px" height="20px" color="white" />
                  ) : (
                    "SAVE"
                  )}
                </Button>
              </Box>
            </Form>
          </Box>
        </Modal>
      }



      {
        open &&
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle_1} style={{ background: "white" }}>
            <Typography variant="h4" component="h2">Update Activity Registry</Typography>
            <CloseIcon onClick={handleClose} style={{ float: 'right', marginBottom: "7px", cursor: "pointer" }} />

            <Form className={classes.form}>
              <Box className={classes.staffDetailsForm}>
                <Box mb='0.5rem'>
                  <Typography variant="h6" className={classes.prescriptionTitle}>
                    Name
                  </Typography>
                  <TextInput
                    className={classes.style_1}
                    field='name'
                    type='text'
                    label=' Name'
                    validateOnBlur
                    validateOnChange
                    name='name'
                    value={updatefield.name}
                    onChange={handleUpdatechange}
                  />
                </Box>
                <Box mb='0.5rem'>
                  <Typography variant="h6" className={classes.prescriptionTitle}>
                    Price
                  </Typography>
                  <TextInput
                    className={classes.style_1}
                    field='price'
                    type='number'
                    label='price'
                    validateOnBlur
                    validateOnChange
                    name='price'
                    value={updatefield.price}
                    onChange={handleUpdatechange}

                  />
                </Box>


              </Box>
              <Box mx='.4rem' my={12} className={classes.submitBtn}>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  className={classes.btn}
                  onClick={() => onUpdateData(id)}
                >
                  {lodingStatus.logoLodingStatus ? (<Loader width="200px" height="20px" color="white" />) : ("Update")}
                </Button>
              </Box>
            </Form>
          </Box>
        </Modal>
      }

    </>
  );
}
export default ActivityRegistry;










