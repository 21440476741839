import React from 'react';
import { Typography, Box } from '@mui/material';
import {CustomDialogBox} from '../../../components/common/CustomDialogBox';
import moment from 'moment';

const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
  border: '0px',
};

const thStyle = {
  border: '0px',
  borderBottom: '1px solid gray',
  padding: '15px 10px',
  textAlign: 'left',
};

const tdStyle = {
  padding: '8px',
  textAlign: 'left',
  border: '0px',
};

const WarningDialog = ({ open, onClose, visitRequests, indicators }) => {
  return (
    <CustomDialogBox
      open={open}
      handleDialogOnClose={onClose}
      dialogTitle="Warning"
      maxWidth="md"
      children={
        <Box sx={{ width: '100%', minWidth: "400px", display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {visitRequests && visitRequests.length > 0 && (
            <table style={tableStyle}>
              {visitRequests.map((item, index) => (
                <tr style={thStyle} key={index}>
                  <td style={tdStyle}>
                    <Typography variant="body1">Visit Request</Typography>
                  </td>
                  <td style={tdStyle}>
                    <Typography variant="body2">
                      <strong>Description:</strong> <br />
                      {item.description}
                    </Typography>
                  </td>
                  <td style={tdStyle}>
                    <Typography variant="body2">
                      <strong>Requested on:</strong>
                      <br />
                      {moment(item.createdAt).format('DD-MM-YYYY, HH:mm:ss')}
                    </Typography>
                  </td>
                </tr>
              ))}
            </table>
          )}
          {indicators && (
            <Box>
              {indicators.vitalsCheckDone === false && (
                <Typography variant="body2" color="error">Vitals not done</Typography>
              )}
              {indicators.inputOutputCheckDone === false && (
                <Typography variant="body2" color="error">Input/Output monitoring not done</Typography>
              )}
            </Box>
          )}
        </Box>
      }
      showDialogActions={false}
    />
  );
};

export default WarningDialog;