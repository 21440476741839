import React from "react";
import GenericGrid from "../../components/grid/generic-grid";
import EmrColumn from "./emr-column";
import { useHistory } from "react-router-dom";
import Routes from "../../routes/routes";
import useGlobalStyles from "../../utils/global-style";
import AuthService from "../../utils/services/auth-service";
import { generateSpecialityFilter } from "../../utils/app-util";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const Emr = () => {
  // set document title
  useDocumentTitle("EMR Templates");

  let history = useHistory();
  const globalClasses = useGlobalStyles();
  const onUpdateBtnClicked = (val) => {
    history.push(Routes.UPDATE_EMR + `?slug=${val.slug}`);
  };
  const user = AuthService.getUser();
  const DocSlug = JSON.parse(localStorage.getItem('USER'))

  return (
    <div className={globalClasses.addSpacing}>
      <GenericGrid
        hideToggleEditMode={true}
        baseUrl="emr-template"
        csvName="emr-template"
        addText={"Create New Template"}
        hideGlobalSearch={true}
        onUpdateBtnClicked={onUpdateBtnClicked}
        hideOnUpload={false}
        hideExport={false}
        defaultFilter={{
            custom: {
              filterType: "exact",
              filter: `forDoctor::slugSpec::${DocSlug.slug}::${generateSpecialityFilter(user.speciality)}`
            }
            }
          }
        columnDefGenerator={EmrColumn}
        title="EMR Templates"
      />
    </div>
  );
};

export default Emr;
