import React from "react";
import {
    Box, Checkbox,
    FormControlLabel, Grid,
    TextField,
    Typography
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { Form } from "informed";
import Loader from "../../components/misc/loader";
import { CustomDialogBox } from "../../components/common/CustomDialogBox";
import CustomActionButton from "../../components/common/CustomActionButton";

const ReceiptDialog = ({
  open,
  handleClose,
  classes,
  globalClass,
  invoice,
  receiptData,
  receiptDate,
  printDateSelected,
  defaultTime,
  isChecked,
  isChecked2,
  handleReceiptData,
  onReceiptDateSelected,
  onPrintDateSelected,
  onSelectPrintingTIme,
  handleCheckboxChange,
  handleCheckboxChange2,
  saveReceipt,
  totalBill,
}) => {
  const dialogActions = (
    <CustomActionButton
      buttonTitle="Print Receipt"
      onClick={saveReceipt}
      variant="contained"
      color="primary"
    />
  );

  return (
    <CustomDialogBox
      open={open}
      handleDialogOnClose={handleClose}
      dialogTitle="Receipt"
      showDialogActions={true}
      dialogActions={dialogActions}
      maxWidth="md"
    >
      <Form>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {invoice !== null && invoice !== "" ? (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Appointment Number"
                    variant="outlined"
                    name="AppointmentNumber"
                    value={receiptData.AppointmentNumber}
                    onChange={handleReceiptData}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Receipt Date"
                    value={receiptDate}
                    onChange={onReceiptDateSelected}
                    renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                    inputFormat="DD/MM/YYYY"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Printing Date"
                    value={printDateSelected}
                    onChange={onPrintDateSelected}
                    renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                    inputFormat="DD/MM/YYYY"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TimePicker
                    label="Printing Time"
                    value={defaultTime || null}
                    onChange={onSelectPrintingTIme}
                    renderInput={(params) => <TextField {...params} fullWidth variant="outlined" />}
                  />
                </Grid>
              </Grid>

              {isChecked && (
                <Box sx={{ marginTop: '0px' }}>
                  <Typography variant="h6" gutterBottom>
                    Custom Receipt Details
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Fees"
                        variant="outlined"
                        type="number"
                        value={totalBill}
                        placeholder="Enter fees"
                        name="fee"
                        onChange={handleReceiptData}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Discount"
                        variant="outlined"
                        type="number"
                        name="discount"
                        value={receiptData.discount}
                        placeholder="Enter discount"
                        onChange={handleReceiptData}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Item Description"
                        variant="outlined"
                        multiline
                        rows={2}
                        value={receiptData.itemDescription}
                        placeholder="Enter item description"
                        name="itemDescription"
                        onChange={handleReceiptData}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
              
              {/* <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Custom Receipt"
                  />
                </Grid>
              </Grid> */}
              <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked2}
                        onChange={handleCheckboxChange2}
                      />
                    }
                    label="Duplicate"
                  />
                </Grid>
            </>
          ) : (
            <Loader width="10px" />
          )}
        </Box>
      </Form>
    </CustomDialogBox>
  );
};

export default ReceiptDialog;
