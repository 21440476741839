import React, { useEffect } from "react";
import Header from "../../components/layout/header";
import {
  Box,
  makeStyles,
  Grid,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
} from "@material-ui/core";
import useGlobalStyles from "../../utils/global-style";
import { NotificationManager } from "react-notifications";
import AppConstants from "../../utils/app-constants";
import { useState } from "react";
import Axios from "axios";
import { SHOW_ERROR_NOTIFICATION } from "../../utils/app-util";
import Loader from "../../components/misc/loader";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "1.2rem",
    fontWeight: "700",
    opacity: "0.8",
    lineHeight: "normal",
    marginBottom: "20px",
    color: theme.palette.primary.main,
  },

  prescriptionSection: {
    marginTop: "2rem",
    marginBottom: "2rem",
    padding: "2rem",
    backgroundColor: "#f5f5f5",
    borderRadius: "12px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
  },

  radioGroup: {
    "& .MuiFormControlLabel-root": {
      marginBottom: "10px",
    },
  },

  radioLabel: {
    "& .MuiFormControlLabel-label": {
      marginBottom: "10px",
    },
  },

  checkboxLabel: {
    marginTop: "10px",
    marginBottom: "20px",
  },

  sectionTitle: {
    fontSize: "1.1rem",
    fontWeight: "bold",
    marginBottom: "15px",
  },

  optionDescription: {
    fontSize: "0.9rem",
    color: "#666",
    marginTop: "5px",
  },

  formControlContainer: {
    padding: "25px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
    marginBottom: "15px",
  },

  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  style_2: {
    width: "100%",
    marginLeft: "2%",
    maxWidth: "50%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  dropdown: {
    width: "100%",
    marginLeft: "2%",
    //maxWidth: "50%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 7,
    marginTop: "1%",
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
}));

const LetterHead = (props) => {
  const { respo } = props;

  const classes = useStyles();

  const [data, setData] = useState({
    id: "",
    logo: "",
  });

  const onChangeData = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const [letterHead, setLetterHead] = useState(respo?.letterHead);
  const [letterHeadData, setLetterHeadData] = useState(respo?.letterHead);
  const [letterHeadStatus, setLetterHeadStatus] = useState(false);

  const [topLetterHead, setTopLetterHead] = useState(respo?.topLetterHead);
  const [topLetterHeadData, setTopLetterHeadData] = useState(
    respo?.topLetterHead
  );
  const [topLetterHeadStatus, setTopLetterHeadStatus] = useState(false);

  const [bottomLetterHead, setBottomLetterHead] = useState(
    respo?.bottomLetterHead
  );
  const [bottomLetterHeadData, setBottomLetterHeadData] = useState(
    respo?.bottomLetterHead
  );
  const [bottomLetterHeadStatus, setBottomLetterHeadStatus] = useState(false);

  const [receiptHeader, setReceiptHeader] = useState(respo?.receiptHeader);
  const [receiptHeaderData, setReceiptHeaderData] = useState(
    respo?.receiptHeader
  );
  const [receiptHeaderState, setreceiptHeaderState] = useState(false);

  const [receiptFooter, setReceiptFooter] = useState(respo?.receiptFooter);
  const [receiptFooterData, setReceiptFooterData] = useState(
    respo?.receiptFooter
  );
  const [receiptFooterStatus, setreceiptFooterStatus] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleInputs = (e) => {
    const files = Array.prototype.slice.call(e.target.files);

    const supportedFormats = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/webp",
    ];

    const unsupportedFiles = files.filter(
      (file) => !supportedFormats.includes(file.type)
    );

    if (unsupportedFiles.length > 0) {
      SHOW_ERROR_NOTIFICATION(
        "Only JPG, JPEG, WEBP and PNG files are allowed."
      );
      return;
    }

    const name = e.target.name;
    if (name === "letterHead") {
      const value = e.target.files[0];
      if (e.target.files[0]) {
        setLetterHeadData(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setLetterHead({
            data: {
              data: reader.result.split(",")[1],
              contenttype: e.target.files[0].type,
            },
            contenttype: e.target.files[0].type,
          });
          setLetterHeadStatus(true);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }

    if (name === "topLetterHead") {
      const value = e.target.files[0];
      if (e.target.files[0]) {
        setTopLetterHeadData(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setTopLetterHead(reader.result);
          setTopLetterHeadStatus(true);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }

    if (name === "bottomLetterHead") {
      const value = e.target.files[0];
      if (e.target.files[0]) {
        setBottomLetterHeadData(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setBottomLetterHead(reader.result);
          setBottomLetterHeadStatus(true);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }

    if (name === "ReceiptHeader") {
      const value = e.target.files[0];
      if (e.target.files[0]) {
        setReceiptHeader(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setReceiptHeaderData(reader.result);
          setreceiptHeaderState(true);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }

    if (name === "ReceiptFooter") {
      const value = e.target.files[0];
      if (e.target.files[0]) {
        setReceiptFooter(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setReceiptFooterData(reader.result);
          setreceiptFooterStatus(true);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    setData({
      letterHead: respo?.letterHead,
      topLetterHead: respo?.topLetterHead,
      bottomLetterHead: respo?.bottomLetterHead,
      id: respo?._id,
    });
  }, []);

  const [loadingStatus, setLoadingStatus] = useState({
    letterHeadLoadingStatus: false,
    topLetterHeadLoadingStatus: false,
    bottomLetterHeadLoadingStatus: false,
    formLoadingStatus: false,
    receiptFooterStatus: false,
    receiptHeaderStatus: false,
  });

  const handleSaveImage = async (type) => {
    const formData = new FormData();

    formData.append("id", respo._id);

    if (type === "letterHead") {
      formData.append("letterHead", letterHeadData);
      setLoadingStatus({
        letterHeadLoadingStatus: true,
        topLetterHeadLoadingStatus: false,
        bottomLetterHeadLoadingStatus: false,
        formLoadingStatus: false,
        receiptFooterStatus: false,
        receiptHeaderStatus: false,
      });
    } else if (type === "topLetterHead") {
      formData.append("topHead", topLetterHeadData);
      setLoadingStatus({
        letterHeadLoadingStatus: false,
        topLetterHeadLoadingStatus: true,
        bottomLetterHeadLoadingStatus: false,
        formLoadingStatus: false,
        receiptFooterStatus: false,
        receiptHeaderStatus: false,
      });
    } else if (type === "bottomLetterHead") {
      formData.append("bottomHead", bottomLetterHeadData);
      setLoadingStatus({
        letterHeadLoadingStatus: false,
        topLetterHeadLoadingStatus: false,
        bottomLetterHeadLoadingStatus: true,
        formLoadingStatus: false,
        receiptFooterStatus: false,
        receiptHeaderStatus: false,
      });
    } else if (type === "ReceiptHeader") {
      formData.append("receiptHeader", receiptHeader);
      setLoadingStatus({
        letterHeadLoadingStatus: false,
        topLetterHeadLoadingStatus: false,
        bottomLetterHeadLoadingStatus: false,
        formLoadingStatus: false,
        receiptFooterStatus: true,
        receiptHeaderStatus: false,
      });
    } else if (type === "ReceiptFooter") {
      formData.append("receiptFooter", receiptFooter);
      setLoadingStatus({
        letterHeadLoadingStatus: false,
        topLetterHeadLoadingStatus: false,
        bottomLetterHeadLoadingStatus: false,
        formLoadingStatus: false,
        receiptFooterStatus: false,
        receiptHeaderStatus: true,
      });
    }

    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}update_letter_heads`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response) {
        if (response.data.message.toString() === "Updated Successfully!") {
          setLetterHeadStatus(false);
          setTopLetterHeadStatus(false);
          setBottomLetterHeadStatus(false);
          setLoadingStatus({
            letterHeadLoadingStatus: false,
            topLetterHeadLoadingStatus: false,
            bottomLetterHeadLoadingStatus: false,
            formLoadingStatus: false,
            receiptFooterStatus: false,
            receiptHeaderStatus: false,
          });

          NotificationManager.success(AppConstants.MSG.UPDATE_SUCCESS);
        } else {
          setLoadingStatus({
            letterHeadLoadingStatus: false,
            topLetterHeadLoadingStatus: false,
            bottomLetterHeadLoadingStatus: false,
            formLoadingStatus: false,
            receiptFooterStatus: false,
            receiptHeaderStatus: false,
          });
          NotificationManager.success("Something went wrong Please try again");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [radioValue, setRadioValue] = useState(
    String(respo?.showLetterHeadOnPrescription || false)
  );

  const handleRadioChange = (event) => {
    const newRadioValue = event.target.value;
    setRadioValue(newRadioValue);
    handleSaveRadioSelection(event.target.value);
  };

  const handleSaveRadioSelection = async (newRadioValue) => {
    try {
      const formData = new FormData();
      formData.append("id", respo._id);
      formData.append("showLetterHeadOnPrescription", newRadioValue);
      const response = await Axios.post(
        `${process.env.REACT_APP_API2_HOST}update_letter_heads`,
        formData
      );

      if (response && response.data.message === "Updated Successfully!") {
        NotificationManager.success("Print layout updated successfully!");
      } else {
        NotificationManager.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error("Error updating the print layout:", error);
      NotificationManager.error("Failed to update the print layout");
    }
  };
  const [checked, setChecked] = useState(respo?.showSignatureOnPrescription);

  const handleCheckboxChange = (event) => {
    const newChecked = event.target.checked;
    setChecked(newChecked);
    handleSaveCheckboxSelection(newChecked);
  };

  const handleSaveCheckboxSelection = async (newChecked) => {
    try {
      const formData = new FormData();
      formData.append("id", respo._id);
      formData.append("showSignatureOnPrescription", newChecked);
      const response = await Axios.post(
        `${process.env.REACT_APP_API2_HOST}update_letter_heads`,
        formData
      );

      if (response && response.data.message === "Updated Successfully!") {
        NotificationManager.success("Signature option updated successfully!");
      } else {
        NotificationManager.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error("Error updating the signature option:", error);
      NotificationManager.error("Failed to update the signature option");
    }
  };

  return (
    <>
      <Header title="Letter Head" />
      <div style={{ width: "93%", marginLeft: "2%", paddingBottom: "3%" }}>
        <Grid className={classes.logoContainer}>
          <Box>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "5vw",
              }}
            >
              <FormControl className={classes.formControlContainer}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  Choose Prescription Print Layout
                </Typography>
                <RadioGroup
                  className={classes.radioGroup}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={radioValue}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    className={classes.radioLabel}
                    value="true"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 35 },
                        }}
                      />
                    }
                    label={
                      <div>
                        <Typography variant="h5" style={{ fontWeight: "bold" }}>
                          Letter Head
                        </Typography>
                        <Typography className={classes.optionDescription}>
                          Upload the full letterhead as a single file.
                        </Typography>
                      </div>
                    }
                  />
                  <FormControlLabel
                    className={classes.radioLabel}
                    value="false"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 35 },
                        }}
                      />
                    }
                    label={
                      <div>
                        <Typography variant="h5" style={{ fontWeight: "bold" }}>
                          Header & Footer
                        </Typography>
                        <Typography className={classes.optionDescription}>
                          Upload the header and footer files separately.
                        </Typography>
                      </div>
                    }
                  />
                </RadioGroup>

                <FormControlLabel
                  className={classes.checkboxLabel}
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckboxChange}
                      name="checked"
                      color="primary"
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 35 },
                      }}
                    />
                  }
                  label={
                    <div>
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        Display Signature
                      </Typography>
                      <Typography className={classes.optionDescription}>
                        Include the signature on the printed layout.
                      </Typography>
                    </div>
                  }
                />
              </FormControl>
            </div>
          </Box>
          <Box className={`${classes.prescriptionSection}`}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Prescription Letter Head (A4 Size Only)
              </Typography>
            </Box>
            <div style={{ width: "100%" }}>
              <div class="col-12">
                <input
                  type="file"
                  placeholder="favicon"
                  name="letterHead"
                  class="form-control"
                  id="letterHeadID"
                  autocomplete="off"
                  onChange={handleInputs}
                  style={{ display: "none" }}
                />
                <div className="previewProfilePic">
                  <img
                    className="playerProfilePic_home_tile"
                    src={
                      (letterHead?.data
                        ? `data:${letterHead?.contenttype};base64,` +
                          letterHead?.data?.data
                        : undefined) ||
                      (respo?.letterHead?.data
                        ? `data:${respo?.letterHead?.contenttype};base64,` +
                          respo?.letterHead?.data?.data
                        : undefined)
                    }
                    alt="letterHeadID"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <br />
            {letterHeadStatus ? (
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.button}
                onClick={() => handleSaveImage("letterHead")}
              >
                {loadingStatus.letterHeadLoadingStatus ? (
                  <Loader width="200px" height="20px" color="primary" />
                ) : (
                  "SAVE"
                )}
              </Button>
            ) : (
              <label htmlFor="letterHeadID">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                >
                  <EditIcon /> &nbsp; Edit
                </Button>
              </label>
            )}
            &nbsp;
          </Box>

          <Box className={classes.prescriptionSection}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Prescription Header
              </Typography>
            </Box>
            <div style={{ width: "100%" }}>
              <div class="col-12">
                <input
                  type="file"
                  placeholder="favicon"
                  name="topLetterHead"
                  class="form-control"
                  id="topLetterHeadID"
                  autocomplete="off"
                  onChange={handleInputs}
                  style={{ display: "none" }}
                />
                <div className="previewProfilePic">
                  <img
                    className="playerProfilePic_home_tile"
                    src={topLetterHead || respo?.topLetterHead}
                    alt="topLetterHeadID"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <br />
            {topLetterHeadStatus ? (
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.button}
                onClick={() => handleSaveImage("topLetterHead")}
              >
                {loadingStatus.topLetterHeadLoadingStatus ? (
                  <Loader width="200px" height="20px" color="primary" />
                ) : (
                  "SAVE"
                )}
              </Button>
            ) : (
              <label htmlFor="topLetterHeadID">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                >
                  <EditIcon /> &nbsp; Edit
                </Button>
              </label>
            )}
            &nbsp;
          </Box>

          <Box className={classes.prescriptionSection}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Prescription Footer
              </Typography>
            </Box>
            <div class="col-12">
              <input
                type="file"
                placeholder="favicon"
                name="bottomLetterHead"
                class="form-control"
                id="bottomLetterHeadID"
                autocomplete="off"
                onChange={handleInputs}
                style={{ display: "none" }}
              />

              <div className="previewProfilePic">
                <img
                  className="playerProfilePic_home_tile"
                  src={bottomLetterHead || respo?.bottomLetterHead}
                  alt="bottomLetterHeadID"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <br />
            {bottomLetterHeadStatus ? (
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.button}
                onClick={() => handleSaveImage("bottomLetterHead")}
              >
                {loadingStatus.bottomLetterHeadLoadingStatus ? (
                  <Loader width="200px" height="20px" color="primary" />
                ) : (
                  "SAVE"
                )}
              </Button>
            ) : (
              <label htmlFor="bottomLetterHeadID">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                >
                  <EditIcon /> &nbsp; Edit
                </Button>
              </label>
            )}
            &nbsp;
          </Box>
        </Grid>
      </div>

      <div style={{ width: "93%", marginLeft: "2%", paddingBottom: "3%" }}>
        <Grid className={classes.logoContainer}>
          <Box className={classes.prescriptionSection}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Receipt Header
              </Typography>
            </Box>
            <div style={{ width: "100%" }}>
              <div class="col-12">
                <input
                  type="file"
                  placeholder="favicon"
                  name="ReceiptHeader"
                  class="form-control"
                  id="receipt-header"
                  autocomplete="off"
                  onChange={handleInputs}
                  style={{ display: "none" }}
                />
                <div className="previewProfilePic">
                  <img
                    className="playerProfilePic_home_tile"
                    src={receiptHeaderData}
                    alt="logo"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
            <br />
            {receiptHeaderState ? (
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.button}
                onClick={() => handleSaveImage("ReceiptHeader")}
              >
                {loadingStatus.receiptFooterStatus ? (
                  <Loader width="200px" height="20px" color="primary" />
                ) : (
                  "SAVE"
                )}
              </Button>
            ) : (
              <label htmlFor="receipt-header">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                >
                  <EditIcon /> &nbsp; Edit
                </Button>
              </label>
            )}
            &nbsp;
          </Box>

          <Box
            className={classes.prescriptionSection}
            style={{ display: "none" }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Receipt Footer
              </Typography>
            </Box>
            <form method="post">
              <div class="col-12">
                <input
                  type="file"
                  placeholder="favicon"
                  name="ReceiptFooter"
                  class="form-control"
                  id="receipt-footer"
                  autocomplete="off"
                  onChange={handleInputs}
                  style={{ display: "none" }}
                />

                <div className="previewProfilePic">
                  <img
                    className="playerProfilePic_home_tile"
                    src={receiptFooterData}
                    alt="logo"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <br />
              {receiptFooterStatus ? (
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                  onClick={() => handleSaveImage("ReceiptFooter")}
                >
                  {loadingStatus.receiptHeaderStatus ? (
                    <Loader width="200px" height="20px" color="primary" />
                  ) : (
                    "SAVE"
                  )}
                </Button>
              ) : (
                <label htmlFor="receipt-footer">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.button}
                  >
                    <EditIcon /> &nbsp; Edit
                  </Button>
                </label>
              )}
              &nbsp;
            </form>
          </Box>
        </Grid>
      </div>
    </>
  );
};

export default LetterHead;
