import React, { useEffect, useState } from "react";
import PhoneNumberPage from "./components/phone-number-page";
import OtpPage from "./components/otp-page";
import Forgetpassword from "./forgetpassword";
import "react-phone-input-2/lib/style.css";
import "./login-page.scss";
import Axios from "axios";
import { Skeleton, useMediaQuery } from "@mui/material";

const LoginPage = () => {
  const [openOtpPage, setOpenOtpPage] = useState(false);
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [imgDatabackend, setImgDatabackend] = useState();

  const isMobile = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getwebsite`).then((resp1) => {
      if (resp1) {
        setImgDatabackend(resp1?.data[0]?.loginBgBackEnd)
      }
    });
  }, []);
  

  return (
    <>
    {imgDatabackend ? (
      <div className='login-page' >
        <div className='login-page__poster' style={{ backgroundImage: `url(${imgDatabackend})`}}></div>
        <div className={`login-page__login ${isMobile ? "mobile" : "desktop"}`} style={{ background: `linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url("./images/pattern_background.jpg")`, backgroundSize: "cover"}}>
            {!showForgetPassword ? (
              !openOtpPage ? (
                <PhoneNumberPage
                  setOpenOtpPage={setOpenOtpPage}
                  onForgetPassword={() => setShowForgetPassword(true)}
                />
              ) : (
                <OtpPage setOpenOtpPage={setOpenOtpPage} />
              )
            ) : (
              <Forgetpassword onBack={() => setShowForgetPassword(false)} />
            )}
          </div>
        </div>
      ) : (
        <Skeleton
          variant="rectangular"
          width={isMobile ? "0vw" : "68vw"} 
          height="100vh" 
        />
      )}
    </>
  );
};
export default LoginPage;
