import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  makeStyles,
  Avatar,
  Grid,
} from "@material-ui/core";
import {
  AvatarGroup,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton, Tooltip
} from "@mui/material";

import { CustomCircularLoader, CustomDialogBox } from "../common";
import { DoctorAPI } from "../../apis/DoctorAPI";
import { useDispatch, useSelector } from "react-redux";
import { changePrimaryUserCurrentStatus, setSharedAccessBy, switchProfile } from "../../redux/action/action";
import { SHOW_INFO_NOTIFICATION, SHOW_SUCCESS_NOTIFICATION } from "../../utils/app-util";
import ChipStyledSelectDropdown from "../common/ChipStyledSelectDropdown";
import { SettingsAPI } from "../../apis/SettingsAPI";

const useStyles = makeStyles((theme) => ({
  profileBox: {
    padding: "1rem 0",
    margin: "1rem 0",
    textAlign: "center",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 10px #00000008",
    borderRadius: 14,
    marginRight: 15,
  },
  img: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
  currentProfileImg: {
    width: theme.spacing(35),
    height: theme.spacing(35),
    border: "2px solid #000",
    borderRadius: "50%",
  },
}));

const SwitchProfileDialog = ({
  openProfileSwitcherMenu,
  handleProfileSwitcherDialogOnClose,
  ...rest
}) => {
  const classes = useStyles();

  const SwitchProfileDialogContent = ({
    sharedAccessByList,
    isLoading,
    handleProfileSwitch,
    currentProfile,
    isPrimaryProfile,
  }) => {
    return (
      <Grid container direction="column">
        {/* Current profile */}
        <Grid item style={{ padding: "0px 70px 0px 70px" }}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <Avatar
                className={classes.currentProfileImg}
                alt={
                  isPrimaryProfile
                    ? currentProfile?.name
                    : currentProfile?.doctorName
                }
                src={
                  isPrimaryProfile
                    ? currentProfile?.profilePicString
                    : currentProfile?.doctorProfilePicString
                }
              />
            </Grid>
            <Grid item style={{ marginTop: "5px" }}>
              <Typography variant="h4">
                {isPrimaryProfile
                  ? currentProfile?.name
                  : currentProfile?.doctorName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {isPrimaryProfile
                  ? currentProfile?.specialityName
                  : currentProfile?.doctorSpecialityName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* Profile switcher */}
        <Grid item>
          {isLoading ? (
            <CustomCircularLoader />
          ) : (
            <List sx={{ pt: 0, marginTop: "15px" }}>
              {sharedAccessByList?.length !== 0 ? (
                sharedAccessByList
                  ?.filter(
                    (profile) =>
                      profile?.doctorSlug !==
                      (isPrimaryProfile
                        ? currentProfile?.slug
                        : currentProfile?.doctorSlug)
                  )
                  ?.map((profile) => (
                    <ListItem disableGutters key={profile?.doctorSlug}>
                      <ListItemButton
                        onClick={() => handleProfileSwitch(profile?.doctorSlug)}
                        style={{
                          boxShadow: "0 2px 2px rgb(0 0 0 / 0.2)",
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            src={profile?.doctorProfilePicString}
                            style={{
                              border: "2px solid #000",
                              borderRadius: "50%",
                            }}
                          ></Avatar>
                        </ListItemAvatar>
                        {/* <ListItemText primary={profile?.doctorName} /> */}
                        <Typography variant="h5">
                          {profile?.doctorName}
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  ))
              ) : (
                <Typography>No switchable profile.</Typography>
              )}
            </List>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <CustomDialogBox
      dialogTitle={"Switch Profile"}
      open={openProfileSwitcherMenu}
      handleDialogOnClose={handleProfileSwitcherDialogOnClose}
      showDialogActions={false}
      children={<SwitchProfileDialogContent {...rest} />}
      dialogContentInlineStyle={{ padding: "0 20px 20px 20px" }}
    />
  );
};

const ProfileBox = ({ user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isPrimaryProfile = useSelector(
    (state) => state.switchProfile?.isPrimaryProfile
  );
  const currentProfile = useSelector((state) => {
    return state.switchProfile?.isPrimaryProfile
      ? state.switchProfile?.primaryProfileInfo
      : state.switchProfile?.switchedProfileInfo;
  });
  const sharedAccessByList = useSelector(
    (state) => state.switchProfile?.accessSharedBy
  );
  // ---------- local states --------------
  const [isLoading, setIsLoading] = useState(false);
  const [openProfileSwitcherMenu, setOpenProfileSwitcherMenu] = useState(false);
  const [allCurrentStatusOptions, setAllCurrentStatusOptions] = useState([]);
  const totalSwitchableProfilesCount = Array.isArray(user?.accessSharedBy)
    ? user.accessSharedBy?.length + 1
    : 1;

  // -------- side-effects ------------
  useEffect(() => {
    SettingsAPI.getWaitingScreenSettingsInfo().then((response) => {
      setAllCurrentStatusOptions(response?.doctorStatusOptions);
    });
  }, []);

  // To fetch the shared-access info related to the logged-in doctor
  useEffect(() => {
    if (openProfileSwitcherMenu) {
      setIsLoading(true);
      // make request to get all the share access info
      DoctorAPI.getSharedAccessInfoOfADoctor().then((data) => {
        dispatch(setSharedAccessBy(data?.accessSharedBy ?? []));
        setIsLoading(false);
      });
    }
  }, [openProfileSwitcherMenu]);

  // ---------- event listeners---------------
  const handleProfieSwitcherOnClick = () => {
    setOpenProfileSwitcherMenu((prev) => !prev);
  };

  const handleProfileSwitcherDialogOnClose = () => {
    setOpenProfileSwitcherMenu(false);
  };

  const handleProfileSwitch = (switchProfileSlug) => {
    const switchProfileInfo = sharedAccessByList.find(
      (profile) => profile?.doctorSlug === switchProfileSlug
    );
    dispatch(switchProfile(switchProfileInfo));
    setOpenProfileSwitcherMenu(false);
    SHOW_INFO_NOTIFICATION(`Switched to ${switchProfileInfo.doctorName}`);
  };

  const handleCurrentStatusUpdate = (value) => {
    // dispatch the updated status to redux
    dispatch(changePrimaryUserCurrentStatus(value))
    DoctorAPI.updateDoctorsCurrentStatus(value).then(_ => {
      SHOW_SUCCESS_NOTIFICATION("Current status updated")
    })
  };

  return (
    <>
      <Paper className={classes.profileBox}>
        <Box display="flex" justifyContent="center">
          <Tooltip title="Switch Profile">
            <IconButton onClick={handleProfieSwitcherOnClick} sx={{ ml: 2 }}>
              <AvatarGroup total={totalSwitchableProfilesCount}>
                <Avatar
                  className={classes.img}
                  alt={
                    isPrimaryProfile
                      ? currentProfile?.name
                      : currentProfile?.doctorName
                  }
                  src={
                    isPrimaryProfile
                      ? currentProfile?.profilePicString
                      : currentProfile?.doctorProfilePicString
                  }
                ></Avatar>
              </AvatarGroup>
            </IconButton>
          </Tooltip>
        </Box>

        <Box mt={2}>
          <Typography variant="h6">
            {isPrimaryProfile
              ? currentProfile?.name
              : currentProfile?.doctorName}
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography variant="subtitle1">
            {isPrimaryProfile
              ? currentProfile?.specialityName
              : currentProfile?.doctorSpecialityName}
          </Typography>
        </Box>

        {isPrimaryProfile &&
          (currentProfile?.roleName === "DOCTOR" ||
            currentProfile?.roleName === "DOCTOR_ADMIN") && (
            <Box mt={2}>
              <ChipStyledSelectDropdown
                onChangeHandler={handleCurrentStatusUpdate}
                dropdownChips={allCurrentStatusOptions}
                tooltipLabel={
                  currentProfile?.currentStatus === null ? "Set Status" : "Update Status"
                }
                value={currentProfile?.currentStatus}
              />
            </Box>
          )}
      </Paper>

      <SwitchProfileDialog
        openProfileSwitcherMenu={openProfileSwitcherMenu}
        handleProfileSwitcherDialogOnClose={handleProfileSwitcherDialogOnClose}
        isPrimaryProfile={isPrimaryProfile}
        currentProfile={currentProfile}
        sharedAccessByList={sharedAccessByList}
        handleProfileSwitch={handleProfileSwitch}
        isLoading={isLoading}
      />
    </>
  );
};

export default ProfileBox;
