const initialState = {
  unreadCount: 0,
};

const chatNotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_UNREAD_CHAT_COUNT":
      return {
        ...state,
        unreadCount: action.payload,
      };
    default:
      return state;
  }
};

export default chatNotificationReducer;