import {
  Box,
  DialogContent,
  Divider,
  makeStyles,
  Select,
  MenuItem,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form } from "informed";
import React, { useEffect, useRef, useState } from "react";
import { SelectInput, TextInput } from "../../components/sixsprint-react-ui/";
import Api from "../../utils/api";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_ERROR_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_INFO_NOTIFICATION,
} from "../../utils/app-util";
import Bro from "brototype";
import useGlobalStyles from "../../utils/global-style";
import { isRequired } from "../../utils/validations";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    maxWidth: "80%",
  },
  formRow: {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  emrTypeRow: {
    width: "50%",
  },
  prescriptionTitle: {
    lineHeight: "normal",
    marginBottom: "10px",
  },
}));

const TemplateDialog = ({
  open = false,
  handleClose,
  savedPrescription,
  cb,
  uploadedDiagramid,
  isPrescriptionSaved,
  setIsPrescriptionSaved,
}) => {
  const globalClass = useGlobalStyles();
  const classes = useStyles();
  const [specialities, setSpecialities] = useState([]);
  const [emrTemplate, setEmrTemplate] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedEmrType, setSelectedEmrType] = useState("");
  const formApi = useRef();

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getspeciality`).then(
      (resp) => {
        if (resp) {
          setSpecialities(resp?.data);
        }
      }
    );
    Api.get(`emr-template/get_emr_template_type`).subscribe((resp) => {
      if (resp) {
        setEmrTemplate(resp?.data?.data);
        resp.data.data.map((e, i) => {
          if (e.default == true) {
            setSelectedEmrType(e._id);
          }
        });
      }
    });
  }, []);

  const handleSubmit = async (val) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    const user = JSON.parse(localStorage.getItem("USER"));

    const templateData = {
      // Basic Information
      chiefComplaints: savedPrescription?.chiefComplaints || "",
      prov: savedPrescription?.prov || "",
      hopi: savedPrescription?.hopi || "",
      remarks: savedPrescription?.remarks || "",
      examination: savedPrescription?.examination || "",
      
      // Treatment Related
      treatment: savedPrescription?.treatment || "",
      surgicalPlan: savedPrescription?.surgicalPlan || "",
      surgeryName: savedPrescription?.surgeryName || "",
      surgeryDone: savedPrescription?.surgeryDone || "",
      investigation: savedPrescription?.investigation || "",
      medications: savedPrescription?.medications || [],
      
      // Patient Status
      vitals: savedPrescription?.vitals || [],
      coMorbidities: savedPrescription?.coMorbidities || [],
      
      // Instructions and Notes
      notes: savedPrescription?.notes || "",
      instructions: savedPrescription?.instructions || "",
      dietaryRestriction: savedPrescription?.dietaryRestriction || "",
      restrictions: savedPrescription?.restrictions || "",
      
      // Additional Fields
      diagnosis: savedPrescription?.diagnosis || "",
      allergies: savedPrescription?.allergies || "",
      followUpInstructions: savedPrescription?.followUpInstructions || "",
      labInvestigations: savedPrescription?.labInvestigations || "",
      radiologyInvestigations: savedPrescription?.radiologyInvestigations || "",
      otherInvestigations: savedPrescription?.otherInvestigations || "",
      
      // Images and Diagrams
      images: savedPrescription?.images || [],
      diagrams: savedPrescription?.diagrams || [],
      diagramIds: savedPrescription?.diagramIds || [],
      
      // Referrals and External Info
      referringDoc: savedPrescription?.referringDoc || "",
      referringDocNo: savedPrescription?.referringDocNo || "",
      referringDocEmail: savedPrescription?.referringDocEmail || "",
      referringOtherEmail: savedPrescription?.referringOtherEmail || "",
      
      // Additional Clinical Info
      pastMedicalHistory: savedPrescription?.pastMedicalHistory || "",
      familyHistory: savedPrescription?.familyHistory || "",
      socialHistory: savedPrescription?.socialHistory || "",
      oTDetails: savedPrescription?.oTDetails || "",
      
      // Follow-up Details
      followUpRequired: savedPrescription?.followUpRequired || false,
      followUpDuration: savedPrescription?.followUpDuration || { number: "", interval: "" }
    };

    const requestBody = {
      name: val.name,
      specialitySlug: val.specialitySlug,
      createdBySlug: user?.slug,
      emrTemplateTypeId: val.emrTemplateType,
      template: templateData,
    };
    const searchFilter = {
      page: 0,
      size: 10,
      filterModel: {
        name: {
          filterType: "exact",
          filter: val.name,
        },
        createdBySlug: {
          filterType: "exact",
          filter: user?.slug,
        },
      },
    };

    Api.post("emr-template/search_new", searchFilter).subscribe({
      next: (searchResponse) => {
        if (
          searchResponse?.data?.status &&
          searchResponse?.data?.data?.content?.length > 0
        ) {
          const existingTemplate = searchResponse.data.data.content[0];
          if (existingTemplate.specialitySlug === val.specialitySlug) {
            Api.put(
              `emr-template/new/${existingTemplate._id}`,
              requestBody
            ).subscribe({
              next: (updateResponse) => {
                if (updateResponse?.data?.status) {
                  SHOW_SUCCESS_NOTIFICATION("Template updated successfully!");
                  setIsPrescriptionSaved(false);
                  handleClose();
                } else {
                  SHOW_ERROR_NOTIFICATION("Failed to update template");
                }
                setIsSubmitting(false);
              },
              error: (error) => {
                DEFAULT_ERROR_CALLBACK(error);
                setIsSubmitting(false);
              },
            });
          } else {
            SHOW_INFO_NOTIFICATION(
              "Template name already exists for another speciality"
            );
            setIsSubmitting(false);
            setIsPrescriptionSaved(false);
            handleClose();
          }
        } else {
          Api.post(`emr-template/add_emr_template`, requestBody).subscribe({
            next: (createResponse) => {
              if (createResponse?.data?.status) {
                SHOW_SUCCESS_NOTIFICATION("Template saved successfully!");
                setIsPrescriptionSaved(false);
                handleClose();
              } else {
                SHOW_ERROR_NOTIFICATION("Failed to create template");
              }
              setIsSubmitting(false);
            },
            error: (error) => {
              DEFAULT_ERROR_CALLBACK(error);
              setIsSubmitting(false);
            },
          });
        }
      },
      error: (error) => {
        DEFAULT_ERROR_CALLBACK(error);
        setIsSubmitting(false);
      },
    });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Add Template Info"}</DialogTitle>
      <Divider />
      <Form apiRef={formApi} onSubmit={handleSubmit}>
        <DialogContent>
          <Box className={classes.container}>
            <Box className={classes.formRow}>
              <TextInput
                className={globalClass.roundWhiteBg}
                containerClassName={classes.templateName}
                required
                field="name"
                type="text"
                label="Template Name"
                validateOnBlur
                validateOnChange
                validate={isRequired}
              />
              <SelectInput
                required
                className={globalClass.roundWhiteBg}
                containerClassName={classes.specialityField}
                options={specialities}
                validate={isRequired}
                field="specialitySlug"
                placeholder="Speciality"
                label="Speciality"
                labelKey="name"
                valueKey="_id"
                validateOnBlur
                validateOnChange
              />
            </Box>
            <Box className={classes.emrTypeRow}>
              <SelectInput
                required
                className={globalClass.roundWhiteBg}
                containerClassName={classes.specialityField}
                options={emrTemplate}
                validate={isRequired}
                field="emrTemplateType"
                placeholder="EMR Template"
                label="EMR Template"
                labelKey="name"
                valueKey="_id"
                validateOnBlur
                validateOnChange
              />
            </Box>
            <Button
              className={globalClass.largeBtnContainer}
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isPrescriptionSaved}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </Form>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TemplateDialog;
