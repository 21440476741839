import "./PrintComponent.scss";
export default function DuplicatePrintComponent({ Content, reference }) {
  return (
    <div className="print-wrapper" ref={reference}>
      <div className="content">
        <Content />
      </div>
    </div>
  );
}
