import React, { useEffect, useState, useRef } from "react";
import { WaitingScreenDoctorCard } from "./WaitingScreenDoctorCard";

export const WaitingScreenCardsPanel = ({
  allDoctorsList = [],
  todaysAppointmentDetails = [],
}) => {
  const [displayedDoctors, setDisplayedDoctors] = useState([]);
  const currentIndexRef = useRef(0);
  const doctorsPerPage = 6;
  const intervalTime = 10000;

  useEffect(() => {
    if (
      allDoctorsList.length === 0 ||
      allDoctorsList.length <= doctorsPerPage
    ) {
      setDisplayedDoctors(allDoctorsList);
      return;
    }
    setDisplayedDoctors([]);
    currentIndexRef.current = 0;

    // Function to update displayed doctors
    const updateDisplayedDoctors = () => {
      const currentIndex = currentIndexRef.current;
      const newDisplayedDoctors = allDoctorsList.slice(
        currentIndex,
        currentIndex + doctorsPerPage
      );
      setDisplayedDoctors(newDisplayedDoctors);

      // Update currentIndex for the next cycle
      currentIndexRef.current =
        (currentIndex + doctorsPerPage) % allDoctorsList.length;
    };

    // Initial call to display the first set of doctors
    updateDisplayedDoctors();

    // Set interval to update the displayed doctors
    const intervalId = setInterval(updateDisplayedDoctors, intervalTime);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDoctorsList]);

  return (
    <div className="ws-card-panel">
        {displayedDoctors?.map((doctorInfo) => (
          <div
            key={doctorInfo?.slug}
            className="ws-card-panel__doctor-card-wrapper"
          >
            <WaitingScreenDoctorCard
              doctorName={doctorInfo?.name}
              doctorProfileImageUrl={doctorInfo?.profilePic}
              doctorCurrentStatus={doctorInfo?.currentStatus ?? "NA"}
              doctorRoomNumber={doctorInfo?.roomNumber}
              appointmentList={(todaysAppointmentDetails ?? [])
                .filter(
                  (appointment) => appointment?.doctorSlug === doctorInfo?.slug
                )
                .sort((a, b) => {
                  if (a.called && !b.called) return -1;
                  if (!a.called && b.called) return 1;
                  return 0;
                })}
            />
          </div>
        ))}
      </div>
  );
};
