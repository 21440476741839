import Api from "../utils/api";
import {
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
} from "../utils/app-util";

export const blockPatient = async (payload) => {
    try {
      const response = await Api.post("/patient/blockpatient", payload).toPromise();
      if (response.data.status === true) {
        SHOW_SUCCESS_NOTIFICATION(response.data.message);
      } else {
        SHOW_ERROR_NOTIFICATION(response.data.message);
      }
    } catch (error) {
      SHOW_ERROR_NOTIFICATION(error?.response?.data?.message || "An error occurred while blocking the patient.");
    }
  };