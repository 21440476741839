import dayjs from "dayjs";
import Api2 from "../utils/api2";
import { firstValueFrom } from "rxjs";
import Api from "../utils/api";
import { useQuery } from "@tanstack/react-query";
import { notifyAppointmentRescheduleFormBuilder } from "../screens/appointment/appointment.util";

export const AppointmentAPI = {
  getAllAppointmentsForToday: async function (doctorSlugsList) {
    const requestBody = {
      filterModel: {
        doctors: doctorSlugsList,
        date: {
          filter: dayjs().format("YYYY-MM-DD"),
          filterType: "date",
          type: "equals",
        },
      },
    };
    try {
      const response = await firstValueFrom(
        Api2.post(
          `${process.env.REACT_APP_API2_HOST}get_appointment`,
          requestBody
        )
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching all appointments from today:", error);
      throw error;
    }
  },

  getAppointmentInfoBySlug: async function (appointmentSlug) {
    try {
      const response = await firstValueFrom(
        Api.get(`appointment/${appointmentSlug}`)
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching all appointments from today:", error);
      throw error;
    }
  },

  addNewAppointment: async function ({
    startDateTime,
    endDateTime,
    doctorSlug,
    patientSlug,
    minutes,
    fees,
    discount,
    creatorName,
    creatorRole,
    creatorNumber,
    appointmentTypeId,
    from,
    remark,
    paymentMode,
    queue,
    isFreeChatActive,
    isFriends,
    isFamily,
    slotId,
    notifyPatient,
  }) {
    const formData = new FormData();

    formData.append("startDateTime", startDateTime);
    formData.append("endDateTime", endDateTime);
    formData.append("doctorSlug", doctorSlug);
    formData.append("patientSlug", patientSlug);
    formData.append("minutes", minutes);
    formData.append("fee", fees);
    formData.append("discount", discount);
    formData.append("creatorName", creatorName);
    formData.append("creatorRole", creatorRole);
    formData.append("creatorNumber", creatorNumber);
    formData.append("appointmentTypeId", appointmentTypeId);
    formData.append("from", from);
    formData.append("remark", remark);
    formData.append("paymentMode", paymentMode);
    formData.append("queue", queue);
    formData.append("isFreeChatActive", isFreeChatActive);
    formData.append("isFriends", isFriends);
    formData.append("isFamily", isFamily);
    formData.append("slotId", slotId);
    formData.append("notifyPatient", notifyPatient);

    try {
      const response = await firstValueFrom(
        Api2.post("add_appointment", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      );
      return response.data.data;
    } catch (error) {
      console.error("Error adding new appointment:", error);
      throw error;
    }
  },

  updateAppointmentStatus: async function (appointmentSlug, newStatus) {
    try {
      const response = await firstValueFrom(
        Api.put(`appointment/status/${appointmentSlug}/${newStatus}`)
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching all appointments from today:", error);
      throw error;
    }
  },

  checkExistingAppointment: async function (doctorSlug, patientSlug, date, isReschedule) {
    const formData = new FormData();
    formData.append("doctorSlug", doctorSlug);
    formData.append("patientSlug", patientSlug);
    formData.append("date", date);
    formData.append("isReschedule", isReschedule);

    try {
      const response = await firstValueFrom(
        Api2.post("/appointment/check-existing", formData)
      );
      return response?.data;
    } catch (error) {
      console.error("Error checking existing appointment:", error);
      throw error;
    }
  },

  notifyAboutAppointmentReschedule: async function (appointmentSlug, oldDateStringInEpoch, oldTimeInHHmm,
    newDate, newTimeStringInHHmm) {
    const payloadFormData = notifyAppointmentRescheduleFormBuilder(appointmentSlug, oldDateStringInEpoch,
      oldTimeInHHmm, newDate, newTimeStringInHHmm);
    console.log('notify payload', payloadFormData)
    try {
      const response = await firstValueFrom(Api2.post("/notify_appointment_change", payloadFormData));
      return response;
    } catch (error) {
      console.error("Error notifying about appointment reschedule.");
      throw error;
    }
  }
};

export const useGetAppointmentByPatientSlug = (patientslug) => {
  const payload = { "filterModel": { "id": patientslug } };

  return useQuery({
    queryKey: ['appointmentByPatientSlug', patientslug],
    queryFn: async () => {
      if (!patientslug) {
        return Promise.reject(new Error("Patient slug is required"));
      }
      const response = await Api2.post(
        `${process.env.REACT_APP_API2_HOST}get_appointment`,
        payload
      ).toPromise();
      return response.data?.data;
    },
    enabled: !!patientslug, // This ensures the query only runs if patientslug is truthy
  });
}