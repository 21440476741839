import React from 'react';
import { AppointmentsCards } from './appointments-cards';

const AppointmentsCardSection = ({
  onReviewBtnClicked,
  handleChangeStatus,
  onPrintLetterHeadClicked,
  onCallNextBtnClicked,
  onViewPrescriptionClicked,
  onAddDiscountClicked,
  onPrintReceiptClicked,
  onQueueUpdateClicked,
  onViewEMRClicked,
  onNotifyClicked,
  rows,
  handleOpenPrescription
}) => {
  return (
    <div className='appointments-card-section'>
      {rows?.map((row, index) => (
          <AppointmentsCards
            key={index}
            row={row}
            index={index}
            onReviewBtnClicked={onReviewBtnClicked}
            handleChangeStatus={(evt, row) => handleChangeStatus(evt, row)}
            onPrintLetterHeadClicked={onPrintLetterHeadClicked}
            onQueueUpdateClicked={onQueueUpdateClicked}
            onCallNextBtnClicked={onCallNextBtnClicked}
            onViewPrescriptionClicked={onViewPrescriptionClicked}
            onAddDiscountClicked={onAddDiscountClicked}
            onPrintReceiptClicked={onPrintReceiptClicked}
            onViewEMRClicked={onViewEMRClicked}
            onNotifyClicked={onNotifyClicked}
            handleOpenPrescription={handleOpenPrescription}
          />
        ))}
    </div>
  );
};
export default AppointmentsCardSection;
