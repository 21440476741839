import React, { useState, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { SHOW_ERROR_NOTIFICATION } from "../../utils/app-util";
import dayjs from "dayjs";
import CustomActionButton from "./CustomActionButton";

const DateRangePicker = ({ onDateRangeChange, sx, theme, resetDate }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    if (endDate && date > endDate) {
      SHOW_ERROR_NOTIFICATION("Start date cannot be after end date.");
      const adjustedStartDate = dayjs(endDate).subtract(1, "day");
      setStartDate(adjustedStartDate);
      onDateRangeChange(adjustedStartDate, endDate); // Ensure correct range is passed
    } else {
      setStartDate(date);
      onDateRangeChange(date, endDate);
    }
  };
  
  const handleEndDateChange = (date) => {
    if (startDate && date < startDate) {
      SHOW_ERROR_NOTIFICATION("End date cannot be before start date.");
      const adjustedEndDate = dayjs(startDate).add(1, "day");
      setEndDate(adjustedEndDate);
      onDateRangeChange(startDate, adjustedEndDate); // Ensure correct range is passed
    } else {
      setEndDate(date);
      onDateRangeChange(startDate, date);
    }
  };

  useEffect(() => {
    if (resetDate) {
      setStartDate(null);
      setEndDate(null);
      onDateRangeChange(null, null);
    }
  }, [resetDate]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "inherit",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <DatePicker
          className={`MuiFormControl-marginNormal`}
          format="DD/MM/YYYY"
          label={"Start Date"}
          placeholder="DD/MM/YYYY"
          value={startDate}
          onChange={handleStartDateChange}
          sx={{
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: theme?.palette?.primary?.main,
              },
            },
            '& .MuiInputLabel-root': {
                color: theme.palette.text.secondary,
                '&.Mui-focused': {
                    color: theme?.palette?.primary?.main,
                },
            },
            ...sx,
          }}
          slotProps={{
            textField: {
              size: "small",
            },
          }}
        />
        <span style={{ margin: "0 6px" }}>to</span>
        <DatePicker
          className={`MuiFormControl-marginNormal`}
          format="DD/MM/YYYY"
          label={"End Date"}
          placeholder="DD/MM/YYYY"
          value={endDate}
          onChange={handleEndDateChange}
          disabled={!startDate}
          minDate={startDate}
          sx={{
            backgroundColor: "white",
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: theme?.palette?.primary?.main,
              },
            },
            '& .MuiInputLabel-root': {
                color: theme.palette.text.secondary,
                '&.Mui-focused': {
                    color: theme?.palette?.primary?.main,
                },
            },
            ...sx,
          }}
          slotProps={{
            textField: {
              size: "small",
            },
          }}
        />
        {(startDate || endDate) && (
          <CustomActionButton
            variant="contained"
            onClick={() => {
              setStartDate(null);
              setEndDate(null);
              onDateRangeChange(null, null);
            }}
            sx={{
              fontSize: "9px",
              whiteSpace: "nowrap",
              marginLeft: "10px",
            }}
            buttonTitle="Clear"
          />
        )}
      </div>
    </div>
  );
};

export default DateRangePicker;
