import React, { useState } from "react";
import OtpInput from "react-otp-input";
import Loader from "../../components/misc/loader";
import { useRecoilValue } from "recoil";
import {LoginState} from '../../states'
import AuthService from '../../utils/services/auth-service'
import { useHistory } from "react-router-dom";
import Routes from "../../routes/routes";
import { DEFAULT_ERROR_CALLBACK } from  "../../utils/app-util";
import { NotificationManager } from "react-notifications";


const Otpforgetpassword = ({ setOpenOtpPage , mobileNumber , setVerifyStatus}) => {
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidOTP, setIsInvalidOTP] = useState(false);
  
  //const {mobileNumber, email, countryCode} = useRecoilValue(LoginState);

  const history = useHistory()

  const handleGoBack = () => {
    setOpenOtpPage(false);
  };

 

  const handleVerifyOTPForget = (event) => {
    setIsLoading(true);
    event.preventDefault();
    const payload = {
     mobileNumber: mobileNumber,
      password: Number(otp)
    }
    if (otp.length === 4) {
      AuthService.verifyForgetPassword(payload).subscribe(
        (resp) => {
          if(resp?.message === "Otp is valid!") {
            NotificationManager.success(resp?.message);
            setVerifyStatus(resp?.status)
          
            setIsLoading(false);
            
          } else {
            NotificationManager.error(resp?.message);
            setIsLoading(false)
          } 
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
        }
      );
    } else {
      setIsInvalidOTP(true);
    }
  };


  const handleChangeOtp = (enteredOtp) => {
    setOtp(enteredOtp);
    
    if (!/^\d*$/.test(enteredOtp)) {
      setIsInvalidOTP(true); 
    } else {
      setIsInvalidOTP(false); 
    }
  };

  return (
    <>

      <form
        className='login-page__login-box login-page__login-form'
        onSubmit={handleVerifyOTPForget }
      >
        <div className='login-page__form-row'>
          <label className='login-page__label' htmlFor=''>
            Enter OTP
          </label>
          <OtpInput
            value={otp}
            onChange={handleChangeOtp}
            inputStyle='login-page__otp'
            containerStyle='login-page__otp-container'
            isInputNum={true}
            shouldAutoFocus
            renderInput={(props) => <input {...props} />}
          />
          {isInvalidOTP && (
            <span className='login-page__validation-error'>
              This Field is Required
            </span>
          )}
        </div>
        <button
          className='login-page__login-btn login-page__form-row'
          type='submit'
        >
          {isLoading ? <Loader size={20} color={"#fff"} /> : "Submit"}
        </button>
      </form>
      <div className='login-page__switch-btn' onClick={handleGoBack}>
        Back
      </div>
    </>
  );
};
export default Otpforgetpassword;
