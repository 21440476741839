import React from 'react';
import { Box, Button, CircularProgress, Modal, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@material-ui/icons/Close';

const RoomSelectionModal = ({
    open,
    handleClose,
    roomsLoading,
    roomsError,
    allActiveRooms,
    addroomno,
    handlechangeroomno,
    handleSaveandUpdateRoom,
    myTheme,
    classes
}) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="room-selection-modal-title"
            aria-describedby="room-selection-modal-description"
        >
            <Box className={classes.modalStyle}>
                <Box
                    mt="1rem"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography id="room-selection-modal-title" style={{ fontFamily: "Comfortaa", fontSize: "1.5rem" }} variant="h4" component="h2">
                        Room Numbers
                    </Typography>
                    <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
                </Box>
                <Box mt="1rem" display="flex" flexDirection="row" flexWrap="wrap" style={{ maxHeight: '300px', overflow: 'auto' }}>
                    <ThemeProvider theme={myTheme}>
                        <ToggleButtonGroup
                            name="roomNumber"
                            value={addroomno.roomNumber}
                            exclusive
                            onChange={handlechangeroomno}
                            aria-label="text alignment"
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                            }}
                        >
                            {roomsLoading ? (
                                <CircularProgress />
                            ) : roomsError ? (
                                <Typography color="error">Error loading rooms</Typography>
                            ) : (
                                allActiveRooms?.map((item, index) => {
                                    if (item.roomNumber == null) {
                                        return null;
                                    } else {
                                        return (
                                            <ToggleButton
                                                name="roomNumber"
                                                key={index}
                                                value={item.roomNumber}
                                                aria-label="centered"
                                                style={{ width: "56px" }}
                                            >
                                                {item.roomNumber}
                                            </ToggleButton>
                                        );
                                    }
                                })
                            )}
                        </ToggleButtonGroup>
                    </ThemeProvider>
                </Box>
                <Box mt="1rem" display="flex" justifyContent="flex-end">
                    <Button variant="contained" color="primary" onClick={handleSaveandUpdateRoom}>
                        Save
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default RoomSelectionModal;