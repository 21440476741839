import { Grid, useMediaQuery} from "@mui/material";
import { useTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import JobModal from "./JobModal";
import DeleteJobModal from "./DeleteJobModal";
import {
  DeleteJob,
  EditJob,
  UpdateJobStatus,
  BatchDeleteJob,
  UpdateBatchJobStatus,
} from "../../apis/JobAPI";
import { useQueryClient } from "@tanstack/react-query";
import { Toolbar } from "@mui/material";
import { SHOW_ERROR_NOTIFICATION } from "../../utils/app-util";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import CustomActionButton from "../../components/common/CustomActionButton";
import { MultiSelectWithCheckbox } from "../../components/common/MultiSelectWithCheckbox";
import DateRangePicker from "../../components/common/DateRangePicker";
import { convertEpochToDate } from "../../utils/datetime.util";

const calculateOverdueDays = (dueDate) => {
    const today = new Date();
    const due = new Date(dueDate);
    today.setHours(0, 0, 0, 0);
    due.setHours(0, 0, 0, 0);
    const diffTime = today > due ? today - due : 0;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
};

const JobGrid = ({ rows, isAssignedToUser, resetDate }) => {
  const theme = useTheme();

  const [selectedRows, setSelectedRows] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalData, setModalData] = useState(null);
  const [viewOnly, setViewOnly] = useState(false);
  const [statusFilter, setStatusFilter] = useState([]);
  const [statusMenu, setStatusMenu] = useState([]);
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

  const queryClient = useQueryClient();
  const deleteJob = DeleteJob();
  const editJob = EditJob();
  const updateJobStatus = UpdateJobStatus();
  const updateBatchJobStatus = UpdateBatchJobStatus();
  const deleteBatchJob = BatchDeleteJob();

  const user = useSelector((state) => state.user);

  const isMobile = useMediaQuery("(max-width:768px)");

  let columns = [
    {
      field: "taskName",
      headerName: "Title",
      flex: 3,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span
            style={{ cursor: "pointer" }}
            onClick={(event) => {
              event.stopPropagation();
              setModalTitle("View Job");
              setEditModal(true);
              const filterModalData = rows.find((row) => row.id === params.id);
              setModalData(filterModalData);
              setViewOnly(isAssignedToUser);
            }}
          >
            {params.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "taskDescription",
      headerName: "Description",
      flex: isMobile ? 5 : 8,
      renderCell: (params) => (
        <span
          style={{ cursor: "pointer" }}
          title={params.value}
          onClick={(event) => {
            event.stopPropagation();
            setModalTitle("View Job");
            setEditModal(true);
            const filterModalData = rows.find((row) => row.id === params.id);
            setModalData(filterModalData);
            setViewOnly(isAssignedToUser);
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 110,
      flex: 2,
      valueGetter: (params) => convertEpochToDate(params),
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span
            style={{ cursor: "pointer" }}
            onClick={(event) => {
              event.stopPropagation();
              setModalTitle("View Job");
              setEditModal(true);
              const filterModalData = rows.find((row) => row.id === params.id);
              setModalData(filterModalData);
              setViewOnly(isAssignedToUser);
            }}
          >
            {params.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "assignedBy",
      headerName: "Assigned By",
      width: 160,
      flex: 2,
      valueGetter: (params) => params.name,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span
            style={{ cursor: "pointer" }}
            onClick={(event) => {
              event.stopPropagation();
              setModalTitle("View Job");
              setEditModal(true);
              const filterModalData = rows.find((row) => row.id === params.id);
              setModalData(filterModalData);
              setViewOnly(isAssignedToUser);
            }}
          >
            {params.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      flex: 2,
      renderCell: (params) => {
        const overdueDays =
          params.value === "OVERDUE"
            ? calculateOverdueDays(params.row.dueDate)
            : null;
        const displayValue = overdueDays
          ? `${params.value} (${overdueDays} days)`
          : params.value;
        return (
            <Tooltip title={displayValue}>
                <span
                    style={{
                        cursor: "pointer",
                        color: params.value === "OVERDUE" ? "red" : "inherit",
                        fontWeight: params.value === "COMPLETED" ? "normal" : "bold"
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                        setModalTitle("View Job");
                        setEditModal(true);
                        const filterModalData = rows.find(
                            (row) => row.id === params.id
                        );
                        setModalData(filterModalData);
                        setViewOnly(isAssignedToUser);
                    }}
                >
                    {displayValue}
                </span>
            </Tooltip>
        );
      },
    },
  ];

  // Fetch unique statuses from the rows
  useEffect(() => {
    const statuses = rows.map((row) => row.status);
    const uniqueStatuses = [...new Set(statuses)];
    setStatusMenu(uniqueStatuses);
  }, [rows]);

  // Reset the status filter when the rows change
  useEffect(() => {
    setStatusFilter([]);
  }, [isAssignedToUser]);


  const handleDeleteClick = (id) => {
    deleteJob.mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["jobs"] });
        setDeleteModal(false);
      },
      onError: () => {
        SHOW_ERROR_NOTIFICATION("An error occurred while deleting the job");
      },
    });
  };

  const handleGroupDelete = () => {
    const payload = selectedRows;
    deleteBatchJob.mutate(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["jobs"] });
      },
    });
  };

  const handleGroupCompleted = () => {
    const payload = {
      jobIds: selectedRows,
      status: "COMPLETED",
    };
    updateBatchJobStatus.mutate(
      { payload },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["jobs"] });
        },
      }
    );
  };

  const handleStatusFilterChange = (selectedValues) => {
    setStatusFilter(selectedValues);
  };

  const handleRowClick = (params, event) => {
    event.stopPropagation();
    setModalTitle("View Job");
    setEditModal(true);
    const filterModalData = rows.find((row) => row.id === params.id);
    setModalData(filterModalData);
    setViewOnly(isAssignedToUser);
  };

  const handleCellClick = (params, event) => {
    if (params.field === "__check__") {
      event.stopPropagation();
      const row = rows.find((row) => row.id === params.id);
      if (row && row.status !== "COMPLETED") {
        setSelectedRows([params.id]);
      }
    } else {
      event.stopPropagation();
      setModalTitle("View Job");
      setEditModal(true);
      const filterModalData = rows.find((row) => row.id === params.id);
      setModalData(filterModalData);
      setViewOnly(isAssignedToUser);
    }
  };

  const handleRowSelectionModelChange = (newSelection) => {
    const selectableRows = newSelection.filter((id) => {
      const row = rows.find((row) => row.id === id);
      return row && row.status !== "COMPLETED";
    });
    setSelectedRows(selectableRows);
  };

  const handleDateRangeChange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
  };



  const filteredRows = rows.filter((row) => {
    const statusMatch = statusFilter.length > 0 ? statusFilter.includes(row.status) : true;
    const dateMatch = (() => {
      const rowDate = new Date(row.dueDate);
      rowDate.setHours(0, 0, 0, 0); // Normalize time to compare only dates

      if (dateRange.startDate && !dateRange.endDate) {
        // Only start date is selected, match exact date
        const start = new Date(dateRange.startDate);
        start.setHours(0, 0, 0, 0);
        return rowDate.getTime() === start.getTime();
      }
      if (!dateRange.startDate && dateRange.endDate) {
        // Only end date is selected, match exact date
        const end = new Date(dateRange.endDate);
        end.setHours(0, 0, 0, 0);
        return rowDate.getTime() === end.getTime();
      }
      if (dateRange.startDate && dateRange.endDate) {
        // Both start and end dates are selected
        const start = new Date(dateRange.startDate);
        const end = new Date(dateRange.endDate);
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
        return rowDate >= start && rowDate <= end;
      }
      return true; // No date filter applied
    })();
    return statusMatch && dateMatch;
  });

  if (!isAssignedToUser) {
    columns.push({
      field: "assignedTo",
      headerName: "Assigned To",
      width: 160,
      flex: 2,
      valueGetter: (params) => params.name,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span
            style={{ cursor: "pointer" }}
            onClick={(event) => {
              event.stopPropagation();
              setModalTitle("View Job");
              setEditModal(true);
              const filterModalData = rows.find((row) => row.id === params.id);
              setModalData(filterModalData);
              setViewOnly(true);
            }}
          >
            {params.value}
          </span>
        </Tooltip>
      ),
    });
    columns = columns.filter((column) => column.field !== "assignedBy");
    let columnsList = [
      "taskName",
      "taskDescription",
      "dueDate",
      "assignedBy",
      "assignedTo",
      "status",
      "actions",
    ];
    columns = columns.sort(
      (a, b) => columnsList.indexOf(a.field) - columnsList.indexOf(b.field)
    );
  }

  return (
    <div>
      <Toolbar
        style={{
          width: "100%",
          padding: "0px",
          alignItems: "flex-end",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <Grid
          item
          className="time-button-group"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label
              style={{
                marginBottom: "8px",
                fontFamily: "inherit",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              Filter by Due Date
            </label>
            <DateRangePicker
              onDateRangeChange={handleDateRangeChange}
              sx={{
                width: "180px",
              }}
              theme={theme}
              resetDate={resetDate}
            />
          </div>
        </Grid>
        <Grid
          item
          container
          className="filter-button-group"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
          spacing={2}
        >
          <Grid item justifyContent={"end"}>
            <MultiSelectWithCheckbox
              labelText="Filter by Status"
              optionsList={statusMenu.map((status) => ({
                id: status,
                value: status,
              }))}
              idKey="id"
              valueKey="value"
              defaultSelected={statusFilter}
              onSelectionChange={handleStatusFilterChange}
              minWidth={150}
              showSelectedLabel={false}
              disabled={statusMenu.length === 0}
              sx={{
                "& .MuiSelect-select": {
                  padding: "8px",
                  fontSize: "12px",
                },
                '& .MuiInputLabel-root': {
                  color: theme.palette.text.secondary,
                  '&.Mui-focused': {
                    color: theme?.palette?.primary?.main,
                  },
                },
                m: 0,
                backgroundColor: "white",
              }}
              theme={theme}
            />
          </Grid>
          <Grid item>
            {!isAssignedToUser && (
              <CustomActionButton
                variant="contained"
                onClick={handleGroupDelete}
                disabled={selectedRows.length === 0}
                sx={{
                  fontSize: "9px",
                  whiteSpace: "nowrap",
                }}
                buttonTitle={"Delete Selected"}
              />
            )}
            {isAssignedToUser && (
              <CustomActionButton
                variant="contained"
                onClick={handleGroupCompleted}
                disabled={selectedRows.length === 0}
                sx={{
                  fontSize: "9px",
                  whiteSpace: "nowrap",
                }}
                buttonTitle={"Mark as Completed"}
              />
            )}
          </Grid>
        </Grid>
      </Toolbar>
      <Grid container>
        <div style={{ width: "100%", overflowX: "auto" }}>
          <div style={{ minWidth: "1200px" }}>
            <DataGrid
              rows={filteredRows}
              columns={columns}
              checkboxSelection
              pageSize={10}
              autoHeight
              isRowSelectable={(params) => params.row.status !== "COMPLETED"}
              getRowClassName={(params) =>
                params.row.status === "COMPLETED" ? "strikethrough-row" : ""
              }
              onRowClick={handleRowClick}
              onCellClick={handleCellClick}
              onRowSelectionModelChange={handleRowSelectionModelChange}
              sx={{
                "& .strikethrough-row": {
                  textDecoration: "line-through",
                },
                "& .MuiDataGrid-footerContainer": {
                  display: "none",
                },
                "& .MuiDataGrid-row": {
                  backgroundColor: "background.paper",
                },
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "action.hover",
                },
              }}
            />
          </div>
        </div>
      </Grid>
      <JobModal
        open={editModal}
        handleClose={() => {
          setEditModal(false);
          setViewOnly(false);
          setModalData(null);
        }}
        title={modalTitle}
        data={modalData}
        viewOnly={viewOnly}
        isAssignedTo={isAssignedToUser}
        newJob={false}
      />
      <DeleteJobModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        onDelete={handleDeleteClick}
        id={modalData}
      />
    </div>
  );
};

export default JobGrid;
