import React, {useEffect} from 'react';
import { Box, Button, Grid, Modal, Select, MenuItem, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Loader from "../../components/misc/loader";
import { Checkbox, FormControlLabel } from '@mui/material';

const StatusChecklistModal = ({
    open,
    setOpen,
    handleClose,
    data,
    classes,
    oldStatus,
    newStatus,
    onSubmitChecklist,
    id
}) => {
    const [checkedItems, setCheckedItems] = React.useState({});
    const [remarks, setRemarks] = React.useState("");

    const isLongData = data.some(item => item.name.length > 100);

    const columns = [];
    for (let i = 0; i < data.length; i += 5) {
        columns.push(data.slice(i, i + 5));
    }

    const handleCheckboxChange = (index) => {
        const key = `${index}`;
        setCheckedItems(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    const handleCloseModal = () => {
        setCheckedItems({});
        setRemarks("");
        handleClose();
    }

    const handleSubmit = () => {
        const checklistData = data.map((item, index) => {
            const key = isLongData ? `${index}` : `${Math.floor(index / 5)}-${index % 5}`;
            return {
                name: item.name,
                status: checkedItems[key] || false
            };
        });
        const payload = {
            oldStatus: oldStatus,
            newStatus: newStatus,
            remarks: remarks,
            checklistData: checklistData
        }
        onSubmitChecklist(payload, id);
        setOpen(false);
        setCheckedItems({});
        setRemarks("");
    }

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={classes.modalStyle}>
                <Box
                    mt="1rem"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography id="modal-modal-title" style={{ fontFamily: "Comfortaa", fontSize: "1.5rem" }} variant="h4" component="h2">
                        Checklist Task
                    </Typography>
                    <CloseIcon onClick={handleCloseModal} style={{ cursor: "pointer" }} />
                </Box>
                <Box mt="1rem" display="flex" flexDirection="row" flexWrap="wrap" style={{ maxHeight: '300px', overflow: 'auto' }}>
                    {isLongData ? (
                        <Box display="flex" flexDirection="column" style={{ overflowY: 'auto', maxHeight: '300px' }}>
                            {data.map((item, index) => {
                                const key = `${index}`;
                                return (
                                    <FormControlLabel
                                        key={key}
                                        control={
                                            <Checkbox
                                                checked={checkedItems[key] || false}
                                                onChange={() => handleCheckboxChange(index)}
                                                name={key}
                                            />
                                        }
                                        label={
                                            <span style={{ textDecoration: checkedItems[key] ? 'line-through' : 'none' }}>
                                                {item.name}
                                            </span>
                                        }
                                    />
                                );
                            })}
                        </Box>
                    ) : (
                        columns.map((column, colIndex) => (
                            <Box key={colIndex} display="flex" flexDirection="column" mr={2}>
                                {column.map((item, index) => {
                                    const key = `${colIndex}-${index}`;
                                    return (
                                        <FormControlLabel
                                            key={key}
                                            control={
                                                <Checkbox
                                                    checked={checkedItems[key] || false}
                                                    onChange={() => handleCheckboxChange(key)}
                                                    name={key}
                                                />
                                            }
                                            label={
                                                <span style={{ textDecoration: checkedItems[key] ? 'line-through' : 'none' }}>
                                                    {item.name}
                                                </span>
                                            }
                                        />
                                    );
                                })}
                            </Box>
                        ))
                    )}
                </Box>
                <Box mt="1rem" display="flex" flexDirection="row" flexWrap="wrap">
                        <TextField
                            label="Remark"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                    />
                </Box>
                <Box mt="1rem" display="flex" justifyContent="flex-end">
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default StatusChecklistModal;