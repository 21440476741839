import {
    Box,
    Button,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { Form, Scope } from "informed";
import { default as React, useEffect, useRef, useState } from "react";
import Header from "../../components/layout/header";
import Api from "../../utils/api";
import Axios from "axios";
import AppConstants from "../../utils/app-constants";
import {
    DEFAULT_ERROR_CALLBACK,
    SHOW_ERROR_NOTIFICATION,
    SHOW_SUCCESS_NOTIFICATION
} from "../../utils/app-util";
import useGlobalStyles from "../../utils/global-style";
import { isCurrentRole } from "../../utils/role-util";
import AuthService from "../../utils/services/auth-service";
import DataService from "../../utils/services/data-service";
import { NotificationManager } from "react-notifications";
import EditIcon from "@material-ui/icons/Edit";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Loader from "../../components/misc/loader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { SettingsAPI } from "../../apis/SettingsAPI";
import DynamicFieldArray from "../../components/common/DynamicFieldArray";
import { Formik, Field } from "formik";
import ChecklistModal from "./ChecklistModal";
import StatusModal from "./StatusModal";

const useStyles = makeStyles((theme) => ({
    appbar: {
        background: theme.palette.bg,
    },

    title: {
        flexGrow: 1,
        color: theme.palette.common.black,
        fontWeight: "bold",
        opacity: "0.9",
    },

    container: {
        marginTop: "1%",
        padding: theme.spacing(10),
    },

    prescriptionTitle: {
        fontSize: "0.8rem",
        fontWeight: "700",
        opacity: "0.7",
        lineHeight: "normal",
        marginBottom: "10px",
        fontFamily: "Comfortaa",
    },
    nameModal: {
        fontSize: "0.5rem",
        fontWeight: "700",
        opacity: "0.7",
        marginBottom: "10px",

    },
    prescriptionSection: {
        marginBottom: "2rem",
    },
    prescriptionmodal: {
        marginBottom: "0rem",
    },
    input: {
        display: "none",
    },
    img: {
        width: theme.spacing(50),
        height: theme.spacing(50),
    },
    imgCaptureButton: {
        position: "absolute",
        bottom: -10,
        right: -10,
        backgroundColor: "white",
        borderRadius: "50%",
        boxShadow: "0px 5px 20px #00000000",
    },
    style_1: {
        width: "100%",
        marginLeft: "2%",
        marginRight: "4%",
        backgroundColor: "white",
        borderRadius: 14,
    },
    imageLoader: {
        position: "absolute",
        top: "40%",
        left: "35%",
    },
    signContainer: {
        minWidth: "30%",
        maxWidth: "35%",

    },
    signatureImage: {
        height: "100px",
        maxWidth: "80%",
        marginLeft: "15px",
    },
    modalStyle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: 500,
        minWidth: 375,
        width: "100%",
        height: "auto",
        background: theme.palette.bg,
        boxShadow: 24,
        padding: "2%",
        borderRadius: "10px",
    },
    picture: {
        maxWidth: "160px",
        width: "100%",
    },

    MuiTypographybody1: {
        fontsize: "0.6428571428571429rem",
        fontFamily: "Comfortaa",
        fontweight: "400",
        lineheight: "1.5",
    },

}));

const IpdStatus = () => {
    const classes = useStyles();
    const globalClass = useGlobalStyles();
    const userObj = isCurrentRole(AppConstants.ROLE.DOCTOR)
        ? AuthService.getUser()
        : {};
    const [doctors, setDoctors] = useState([]);
    const formApi = useRef();

    const [initialValues, setInitialValues] = useState([]);
    const [additionalFields, setAdditionalFields] = useState([""]); 

    useEffect(() => {
        if (userObj.id) {
            Axios.get(
                `${process.env.REACT_APP_API2_HOST}user-settings/${userObj.id}`
            ).then((resp) => {
                const response = resp.data.data;
                const { welcomeEmail } = response;
                formApi.current.setValue("welcomeEmail", welcomeEmail);
            });
        }
    }, [userObj.id]);

    const fetchDoctors = () => {
        if (isCurrentRole(AppConstants.ROLE.ADMIN)) {
            const filter = { roleName: { values: ["DOCTOR"], filterType: "set" } };
            DataService.fetchList("doctor/search", 0, 50, filter).subscribe(
                (resp) => {
                    setDoctors(resp.content);
                },
                (error) => {
                    DEFAULT_ERROR_CALLBACK(error);
                }
            );
        }
    };
    useEffect(fetchDoctors, []);

    const [id, setId] = useState();
    const [open, setOpen] = React.useState(false);
    const [AddModalOpen, setAddModalOpen] = useState(false);
    const [openChecklistModal, setOpenChecklistModal] = useState(false);
    const socialMediaArr = ["Active", "Inactive"];
    const checklistArr = [true, false];

    const [addSpecilityData, setaddSpecilityData] = useState([
        {
            name: "",
            status: "",
            checklist: false,
            checklistItems: []
        },
    ]);

    const handleOpenChecklistModal = (item) => {
        setOpenChecklistModal(true);
        setId(item.id);

        setGetUpdateData(prevState => ({
            ...prevState,
            name: item.name,
            status: item.status,
            checklist: item.checklist,
            checklistItems: item.checklistItems
        }));
    };

    const handleCloseChecklistModal = () => {
        setOpenChecklistModal(false);
    };

    const [getUpdateData, setGetUpdateData] = useState({
        name: "",
        status: "",
        checklist: false,
        checklistItems: []
    });

    const onChangeData = (event) => {
        setaddSpecilityData({
            ...addSpecilityData,
            [event.target.name]: event.target.value,
        });
    };

    const handleUpdateChange = (event) => {
        const { name, value } = event.target;
    
        // Preserve checklist items when changing status
        if (name === "status" && value === "Inactive") {
            setGetUpdateData((prevState) => ({
                ...prevState,
                [name]: value,
                checklistItems: prevState.checklistItems || [], // Preserve existing checklist items
            }));
        } else {
            setGetUpdateData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleAddModalOpen = () => {
        setAddModalOpen(true);
        addSpecilityData.name = "";
        addSpecilityData.description = "";
        addSpecilityData.patientcapacity = "";

    };

    const handleAddModalClose = () => {
        setAddModalOpen(false);
    };

    const handleOpen = (item) => {
        setOpen(true);
        setId(item.id);

        setGetUpdateData(prevState => ({
            ...prevState,
            name: item.name,
            status: item.status,
            checklist: item.checklist,
            checklistItems: item.checklistItems
        }));
    };

    const handleClose = () => setOpen(false);

    const [resp, setResp] = useState(null);

    const [logoStatus, setLogoStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const handleSaveData = async () => {
        setIsLoading(true);
        if (
            addSpecilityData.name === ""
        ) {
            NotificationManager.error(AppConstants.MSG.ALL_FIELDS_REQUIRED);
        } else {
            const payloadData = {
                "name": addSpecilityData.name,
                "status": addSpecilityData.status,
                "checklist": addSpecilityData.checklist
            }
            try {
                await Api.post("admit/status/add", payloadData).subscribe((response) => {
                    if (response) {
                        if (response.data.status) {
                            setOpen(false);
                            setLogoStatus(false);
                            setIsLoading(false);
                            setAddModalOpen(false)
                            NotificationManager.success(response.data.message);
                        } else {
                            setOpen(false);
                            setIsLoading(false);
                            NotificationManager.error(response.data.message);
                        }
                    }
                }
                )
            } catch (error) {
                console.error(error);
            }
        }
    };

    const addChecklistTask = async (id, checklistTasks) => {
        setIsLoading(true);
        if (
            checklistTasks.length === 0
        ) {
            NotificationManager.error(AppConstants.MSG.ALL_FIELDS_REQUIRED);
        } else {
            // remove empty string from checklistTasks
            const checklistItems = checklistTasks.filter(item => item.length > 0);
            const payloadData = {
                "name": getUpdateData.name,
                "status": getUpdateData.status,
                "checklist": getUpdateData.checklist,
                "checklistItems": checklistItems
            }
            try {
                await Api.post(`admit/status/edit/${id}`, payloadData).subscribe((response) => {
                    if (response) {
                        if (response.data.status) {
                            setOpenChecklistModal(false);
                            setLogoStatus(false);
                            setIsLoading(false);
                            setAddModalOpen(false)
                            NotificationManager.success(response.data.message);
                        } else {
                            setOpenChecklistModal(false);
                            setIsLoading(false);
                            NotificationManager.error(response.data.message);
                        }
                    }
                }
                )
            } catch (error) {
                console.error(error);
            }
        }
    };

    const onUpdateSave = async () => {
        setIsLoading(true);
        if (
            getUpdateData.name === "" ||
            getUpdateData.status === "" ||
            getUpdateData.checklist === ""
        ) {
            NotificationManager.error(AppConstants.MSG.ALL_FIELDS_REQUIRED);
            setIsLoading(false);
        } else {
            const payloadData = {
                "name": getUpdateData.name,
                "status": getUpdateData.status,
                "checklist": getUpdateData.checklist,
                "checklistItems": getUpdateData.checklistItems
            }
            try {
                await Api.post(`admit/status/edit/${id}`, payloadData).subscribe((response) => {
                    if (response) {
                        if (response.data.status) {
                            setOpen(false);
                            setLogoStatus(false);
                            setIsLoading(false);
                            NotificationManager.success(response.data.message);
                        } else {
                            setOpen(false);
                            setIsLoading(false);
                            NotificationManager.error(response.data.message);
                        }
                    }
                })

            } catch (error) {
                setOpen(false);
                setIsLoading(false);
                console.error(error);
            }
        }
    };

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        Api.get('admit/status').subscribe((resp) => {
            if (resp) {
                setResp(resp.data?.data);
                setLoading(true);
            }
        })
    }, [isLoading]);

    const [ipdReceiptHeaderUrl, setIpdReceiptHeaderUrl] = useState(null);
    const [ipdReceiptFooterUrl, setIpdReceiptFooterUrl] = useState(null);
    const [isIpdReceiptHeaderUrlEditing, setIsIpdReceiptHeaderUrlEditing] = useState(true);
    const [selectedIpdReceiptHeader, setSelectedIpdReceiptHeader] = useState(null);
    const [selectedIpdReceiptFooter, setSelectedIpdReceiptFooter] = useState(null);
    const [isIpdReceiptFooterUrlEditing, setIsIpdReceiptFooterUrlEditing] = useState(true);
    // fetch hospital config
    useEffect(() => {
        SettingsAPI.getHospitalConfig().then(response => {
            // check if header and footer exists
            setIpdReceiptHeaderUrl(response?.ipdConfig?.receiptHeaderUrl);
            setIpdReceiptFooterUrl(response?.ipdConfig?.receiptFooterUrl);
        })
    }, [])

    const handleFileChange = (key, file) => {
        if (key === "receiptHeader") {
            setSelectedIpdReceiptHeader(file);
            setIpdReceiptHeaderUrl(URL.createObjectURL(file));
            setIsIpdReceiptHeaderUrlEditing(false);
        } else if (key === "receiptFooter") {
            setSelectedIpdReceiptFooter(file);
            setIpdReceiptFooterUrl(URL.createObjectURL(file));
            setIsIpdReceiptFooterUrlEditing(false);
        }
    }

    const handleUpload = async (key, value) => {
        if (value !== null) {
            const formData = new FormData();
            formData.append(key, value);
            try {
                const response = await SettingsAPI.setIPDReceiptHeaderOrFooterImage(formData);
                // Reset states
                if (key === "receiptHeader") {
                    setSelectedIpdReceiptHeader(null);
                    setIsIpdReceiptHeaderUrlEditing(true);
                } else {
                    setSelectedIpdReceiptFooter(null);
                    setIsIpdReceiptFooterUrlEditing(true);
                }
    
                if (response?.data?.data) {
                    SHOW_SUCCESS_NOTIFICATION(response?.data?.message);
                } else {
                    SHOW_ERROR_NOTIFICATION(response?.data?.error);
                }
            } catch (error) {
                console.error("Error during upload:", error);
            }
        }
    }


    return (
        <>
            <div>
                <Header title="IPD Status" />
                {resp === null ? (
                    <Loader />
                ) : (
                    <Form
                        apiRef={formApi}
                        // onSubmit={handleSubmit}
                        initialValues={{
                            availSummary: initialValues,
                        }}
                    >
                        <div style={{ width: "95%", marginLeft: "2%" }}>
                            <Box className={classes.prescriptionSection}>
                                <Paper
                                    className={clsx(classes.container, globalClass.roundWhiteBg)}
                                >
                                    <Box display="flex" justifyContent="flex-end">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            startIcon={<AddIcon />}
                                            onClick={handleAddModalOpen}
                                        >
                                            ADD
                                        </Button>
                                    </Box>
                                    <TableContainer>
                                        <Table
                                            aria-label="simple table"
                                            id="summaryRow"
                                            className={classes.table}
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        <strong>Title</strong>
                                                    </TableCell>

                                                    <TableCell>
                                                        <strong>status</strong>
                                                    </TableCell>


                                                    <TableCell>
                                                        <strong></strong>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {resp?.map((item, i) => {
                                                    return (
                                                        <Scope scope={`availSummary[${i}]`} key={i}>
                                                            <TableRow key={i}>
                                                                <TableCell >
                                                                    <Typography style={{ fontFamily: "Comfortaa", fontSize: "0.8rem" }}>{item.name}</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography style={{ fontFamily: "Comfortaa", fontSize: "0.8rem" }}>{item.status}</Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                {
                                                                    item.edit===true?(
                                                                        <Button
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        className={classes.button}
                                                                        startIcon={<EditIcon />}
                                                                        onClick={() =>
                                                                            handleOpen(
                                                                                item
                                                                            )
                                                                        }
                                                                    >
                                                                        Update
                                                                    </Button>
                                                                    ):("")
                                                                }
                                                                    &nbsp;

                                                                </TableCell>
                                                                <TableCell>

                                                                    <Button
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        className={classes.button}
                                                                        startIcon={<EditIcon />}
                                                                        onClick={() =>
                                                                            handleOpenChecklistModal(
                                                                                item
                                                                            )
                                                                        }
                                                                    >
                                                                        Edit Checklist
                                                                    </Button>

                                                                </TableCell>
                                                            </TableRow>
                                                        </Scope>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Box>


                        </div>
                    </Form>
                )}

                {/* ADD IPD STATUS MODAL */}
                <StatusModal
                    open={AddModalOpen}
                    handleClose={handleAddModalClose}
                    title="Add IPD Status"
                    data={addSpecilityData}
                    onChange={onChangeData}
                    onSave={handleSaveData}
                    isLoading={isLoading.logoisLoading}
                    classes={classes}
                    globalClass={globalClass}
                    socialMediaArr={socialMediaArr}
                />

                {/* UPDATE IPD STATUS MODAL */}
                <StatusModal
                    open={open}
                    handleClose={handleClose}
                    title="Update IPD Status"
                    data={getUpdateData}
                    onChange={handleUpdateChange}
                    onSave={() => onUpdateSave(id)}
                    isLoading={isLoading.logoisLoading}
                    classes={classes}
                    globalClass={globalClass}
                    socialMediaArr={socialMediaArr}
                />

                {/* CHECKLIST MODAL */}
                {openChecklistModal && (
                    <ChecklistModal
                    open={openChecklistModal}
                    handleClose={handleCloseChecklistModal}
                    getUpdateData={getUpdateData}
                    onChange={handleUpdateChange}
                    addChecklistTask={addChecklistTask}
                    id={id}
                    isLoading={isLoading}
                    classes={classes}
                    globalClass={globalClass}
                />
                )}      
            </div>
            <div>
                <Header showMenuIconInMobileView={false} title="IPD Receipt"/>
                <div style={{backgroundColor: "#fff", borderRadius: "14px", width: "95%", marginLeft: "2%", padding: "20px"}}>
                    <div style={{display: "flex", flexDirection: "column", marginBottom: "40px"}}>
                        {/* TITLE */}
                        <Typography variant="h6" style={{
                            fontSize: "0.8rem",
                            fontWeight: "700",
                            opacity: "0.7",
                            lineHeight: "normal",
                            marginBottom: "15px",
                        }}>Receipt Header</Typography>
                        {/* PHOTO PREVIEW */}
                        {ipdReceiptHeaderUrl ? (
                            <img src={ipdReceiptHeaderUrl} alt="Receipt Header" style={{ maxWidth: '100%', marginBottom: '10px' }} />
                        ) : (
                            <Typography variant="body2" style={{ marginBottom: '10px' }}>No image uploaded</Typography>
                        )}
                        {/* BUTTON */}
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            component="label"
                            startIcon={isIpdReceiptHeaderUrlEditing && !selectedIpdReceiptHeader ? <EditIcon /> : null}
                            style={{width: "8%"}}
                            onClick={() => {
                                if(!isIpdReceiptHeaderUrlEditing) {
                                    handleUpload("receiptHeader", selectedIpdReceiptHeader)
                                }
                            }
                            }
                        >
                            {isIpdReceiptHeaderUrlEditing ? 'Edit' : 'Save'}
                            {!selectedIpdReceiptHeader && <input
                                type="file"
                                hidden
                                onChange={(e) => handleFileChange("receiptHeader", e.target.files[0])}
                            />}
                        </Button>
                        {/* make states for these, and handle upload */}
                    </div>
                    <div style={{display: "flex", flexDirection: "column", marginBottom: "25px"}}>
                        {/* TITLE */}
                        <Typography variant="h6" style={{
                            fontSize: "0.8rem",
                            fontWeight: "700",
                            opacity: "0.7",
                            lineHeight: "normal",
                            marginBottom: "15px",
                        }}>Receipt Footer</Typography>
                        {/* PHOTO PREVIEW */}
                        {ipdReceiptFooterUrl ? (
                            <img src={ipdReceiptFooterUrl} alt="Receipt Header" style={{ maxWidth: '100%', marginBottom: '10px' }} />
                        ) : (
                            <Typography variant="body2" style={{ marginBottom: '10px' }}>No image uploaded</Typography>
                        )}
                        {/* BUTTON */}
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            component="label"
                            startIcon={!selectedIpdReceiptFooter ? <EditIcon /> : null}
                            style={{width: "8%"}}
                            onClick={() => {
                                if(!isIpdReceiptFooterUrlEditing) {
                                    handleUpload("receiptFooter", selectedIpdReceiptFooter)
                                }
                            }}
                        >
                            {isIpdReceiptFooterUrlEditing ? 'Edit' : 'Save'}
                            {!selectedIpdReceiptFooter && <input
                                type="file"
                                hidden
                                onChange={(e) => handleFileChange("receiptFooter", e.target.files[0])}
                            />}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IpdStatus;