import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const InfoContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '0px',
  padding: '8px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderRadius: '8px'
}));

const InfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  gap: '4px',
  '& > *:first-of-type': {
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 'auto',
  },
}));

const PatientInfoCard = ({
  patientName,
  roomNumber,
  doctorName,
  patientUhid,
  provisionalDiagnosis,
  plan,
  patientStatus,
  mobileNumber,
}) => {
  return (
    <InfoContainer>
      <InfoItem>
        <Typography variant="body2">Patient Name:</Typography>
        <Typography variant="body2">{patientName}</Typography>
      </InfoItem>
      <InfoItem>
        <Typography variant="body2">UHID:</Typography>
        <Typography variant="body2">{patientUhid}</Typography>
      </InfoItem>
      <InfoItem>
        <Typography variant="body2">Phone:</Typography>
        <Typography variant="body2">{mobileNumber}</Typography>
      </InfoItem>
      <InfoItem>
        <Typography variant="body2">Doctor:</Typography>
        <Typography variant="body2">{doctorName}</Typography>
      </InfoItem>
      <InfoItem>
        <Typography variant="body2">Room:</Typography>
        <Typography variant="body2">{roomNumber}</Typography>
      </InfoItem>
      <InfoItem>
        <Typography variant="body2">Status:</Typography>
        <Typography variant="body2">{patientStatus}</Typography>
      </InfoItem>
      <InfoItem>
        <Typography variant="body2">Diagnosis:</Typography>
        <Typography variant="body2">{provisionalDiagnosis}</Typography>
      </InfoItem>
      <InfoItem>
        <Typography variant="body2">Plan:</Typography>
        <Typography variant="body2">{plan}</Typography>
      </InfoItem>
    </InfoContainer>
  );
};

export default PatientInfoCard;
