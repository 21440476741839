import React from 'react';
import { Box, Typography } from "@mui/material";
import { Checkbox } from '@material-ui/core';

const AdmitDataItem = ({ admitData, isChecked, onCheckboxChange, isPrinting = false }) => {
    if (!admitData) return null;

    const handleChange = (event) => {
        onCheckboxChange(admitData.id, event.target.checked);
    };

    const formatDateTime = (epochTimestamp) => {
        const date = new Date(epochTimestamp);
        return `Date: ${date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}, Time: ${date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' })}`;
    };

    const typographyStyle = {
        fontFamily: 'Comfortaa, sans-serif',
        fontSize: '10px'
    };

    return (
        <>
            <Box display="flex" alignItems="flex-start" mt={2}>
                {!isPrinting && <Checkbox
                    size='meidum'
                    style={{ paddingTop: "0" }}
                    checked={isChecked}
                    onChange={handleChange}
                />}
                <Box flexGrow={1} p={0} borderRadius={4}>
                    <Typography variant="h6" gutterBottom sx={{ ...typographyStyle, fontSize: '12px' }}>Admit Data</Typography>
                    <Typography sx={typographyStyle}><strong>Special Requirements:</strong> {admitData.specialRequirements || 'N/A'}</Typography>
                    <Typography sx={typographyStyle}><strong>Surgery Date/Time:</strong> {admitData.surgeryDateTime || 'N/A'}</Typography>
                    <Typography sx={typographyStyle}><strong>Surgery Type:</strong> {admitData.surgeryType || 'N/A'}</Typography>
                    <Typography sx={typographyStyle}><strong>Provisional Diagnosis:</strong> {admitData.prov || 'N/A'}</Typography>
                    <Typography sx={typographyStyle}><strong>PAC Status:</strong> {admitData.pacStatus || 'N/A'}</Typography>
                    <Typography sx={typographyStyle}><strong>History of Present Illness:</strong> {admitData.hopi || 'N/A'}</Typography>
                    <Typography sx={typographyStyle}><strong>Examination:</strong> {admitData.examination || 'N/A'}</Typography>
                    <Typography sx={typographyStyle}><strong>Chief Complaints:</strong> {admitData.chiefComplaints || 'N/A'}</Typography>
                    <Typography sx={typographyStyle}><strong>Blood Requirements:</strong> {admitData.bloodRequirement || 'N/A'}</Typography>
                    <Typography sx={typographyStyle}><strong>Anesthesia:</strong> {admitData.anesthesia || 'N/A'}</Typography>
                    <Typography sx={typographyStyle}><strong>Allowed Foods:</strong> {admitData.allowedFoods || 'N/A'}</Typography>
                    <Box mt={2}>
                        <Typography sx={typographyStyle}>Created At: {formatDateTime(admitData.createdAt)}</Typography>
                    </Box>
                </Box>
            </Box>
            <hr />
        </>
    );
};  

export default AdmitDataItem;
