import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
} from "@material-ui/core";
import Modal from "@mui/material/Modal";
import Loader from "../../components/misc/loader";
import { NotificationManager } from "react-notifications";

// import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
// import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Bro from "brototype";
import clsx from "clsx";
import { Form, Scope } from "informed";
import { useCallback } from "react";
import { default as React, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
// import { SelectInput, TextAreaInput, TextInput } from "sixsprints-react-ui";
import Header from "../../components/layout/header";
import { CheckedInAudioState } from "../../states";
import Api from "../../utils/api";
import Axios from "axios";
import AppConstants, {
  LOCAL_STORAGE_CONSTANTS,
} from "../../utils/app-constants";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
} from "../../utils/app-util";
import useGlobalStyles from "../../utils/global-style";
import { isCurrentRole } from "../../utils/role-util";
import AuthService from "../../utils/services/auth-service";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import EditIcon from "@material-ui/icons/Edit";
import { Close } from "@material-ui/icons";
import { add } from "date-fns";

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "10px",
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 500,
    minWidth: 375,
    width: "100%",
    height: "auto",
    background: theme.palette.bg,
    boxShadow: 24,
    padding: "2%",
    borderRadius: "10px",
  },
}));

const AppoinmentTypeSetting = (props) => {
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const userObj = isCurrentRole(AppConstants.ROLE.DOCTOR)
    ? AuthService.getUser()
    : {};
  const [doctors, setDoctors] = useState([]);
  const [docData, setDocData] = useState(userObj);
  const [socialMediaInfo, setSocialMediaInfo] = useState(null);
  const formApi = useRef();
  const appoinmenttypeArr = ["online", "offline"];

  const [resp, setResp] = useState(null);
  const [uniqueData, setUniqueData] = useState({
    title: "",
    status: "",
    slug: "",
    id: "",
    appoinmenttype: "",
  });


  const [loading, setLoading] = useState(false);
  const [lodingStatus, setLoadingStatus] = useState({
    logoLodingStatus: false,
    updateLodingStatus: false,
  });
  const socialMediaArr = ["Active", "Inactive"];
  const [addModelOpen, setaddModelOpen] = React.useState(false);
  const [updateModelOpen, setupdateModelOpen] = React.useState(false);

  const [UpdateappoinmentData, setUpdateAppoinmentData] = useState({
    name: "",
    status: "",
    slug: "",
    appoinmenttype: "",
  });

  useEffect(() => {
    setUpdateAppoinmentData({
      name: uniqueData.title,
      status: uniqueData.status,
      slug: uniqueData.slug,
      appoinmenttype: uniqueData.appoinmenttype,
    });
  }, [updateModelOpen]);



  const updatehandleOpen = (item) => (
    setupdateModelOpen(true),
    setUniqueData({
      title: item.title,
      status: item.status,
      slug: item.slug,
      appoinmenttype: item.type,
      id: item._id,
    })
  );

  const updatehandleClose = () => setupdateModelOpen(false);

  const addhandleOpen = () => setaddModelOpen(true);
  const addhandleClose = () => setaddModelOpen(false);
  const option = ["Active", "Inactive"];

  const [appoinmentData, setAppoinmentData] = useState({
    name: "",
    status: "",
    slug: "",
    appoinmenttype: "",
  });


  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getappointmenttype`).then(
      (resp) => {
        if (resp) {
          setResp(resp.data);
          setLoading(true);
        }
      }
    );
  }, [updateModelOpen, addModelOpen]);

  const handelChangeAdd = (e) => {
    if (e.target.name == "slug") {
      let newslug = e.target.value
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
      setAppoinmentData({
        ...appoinmentData,
        [e.target.name]: newslug,
      });
    } else {
      setAppoinmentData({
        ...appoinmentData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSaveAdd = async () => {
    setLoadingStatus({
      logoLodingStatus: true,
    });
    if (
      appoinmentData.name === "" ||
      appoinmentData.status === "" ||
      appoinmentData.slug === ""
    ) {
      NotificationManager.error(AppConstants.MSG.ALL_FIELDS_REQUIRED);
    } else {
      const req = {
        title: appoinmentData.name,
        status: appoinmentData.status,
        slug: appoinmentData.slug,
        type: appoinmentData.appoinmenttype,
      };
      const areq = {
        Appointment: req,
      };
      try {
        const response = await Axios({
          method: "post",
          url: `${process.env.REACT_APP_API2_HOST}addappointmenttype`,
          data: areq,
          headers: { "Content-Type": "application/json" },
        });
        if (response.data.status) {
          setLoading(false);
          setaddModelOpen(false);
          setLoadingStatus({
            logoLodingStatus: false,
          });
          NotificationManager.success(response.data.message);
        } else {
          NotificationManager.error(response.data.message);
          setLoadingStatus({
            logoLodingStatus: false,
          });
        }
      } catch (error) {
        setLoading(false);
        setLoadingStatus({
          logoLodingStatus: false,
        });
      }
    }
  };

  const handleUpdateAdd = (e) => {
    if (e.target.name == "slug") {
      let newSlug = e.target.value
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");

      setUpdateAppoinmentData({
        ...UpdateappoinmentData,
        [e.target.name]: newSlug,
      });
    } else {
      setUpdateAppoinmentData({
        ...UpdateappoinmentData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleUpdateApp = async () => {
    setLoadingStatus({
      updateLodingStatus: true,
    });
    const req = {
      title: UpdateappoinmentData.name,
      status: UpdateappoinmentData.status,
      slug: UpdateappoinmentData.slug,
      id: uniqueData.id,
      type: UpdateappoinmentData.appoinmenttype,
    };

    const areq = {
      Appointment: req,
    };
    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}updateappointmenttype`,
        data: areq,
        headers: { "Content-Type": "application/json" },
      });
      if (response.data.status) {
        setLoading(false);
        setupdateModelOpen(false);
        setLoadingStatus({
          updateLodingStatus: false,
        });
        NotificationManager.success(response.data.message);
      } else {
        NotificationManager.error(response.data.message);
        setLoadingStatus({
          updateLodingStatus: false,
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      <Header title="Appointment Type" />
      {resp === null ? (
        <Loader />
      ) : (
        <>
          
          <Form apiRef={formApi} initialValues={{}}>
            <div
              style={{ width: "95%", marginLeft: "2%", paddingBottom: "3%" }}
            >
              <Box className={classes.prescriptionSection}>
                

                <Paper
                  className={clsx(classes.container, globalClass.roundWhiteBg)}
                >
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      startIcon={<AddIcon />}
                      onClick={addhandleOpen}
                    >
                      ADD
                    </Button>
                  </Box>
                  <TableContainer>
                    <Table
                      aria-label="simple table"
                      id="summaryRow"
                      className={classes.table}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <strong>Name</strong>
                          </TableCell>

                          <TableCell>
                            <strong>Status</strong>
                          </TableCell>

                          <TableCell>
                            <strong>Appointment type</strong>
                          </TableCell>

                          <TableCell>
                            <strong>Slug</strong>
                          </TableCell>

                          <TableCell>
                            <strong>Update</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {resp?.map((item, i) => {
                          return (
                            <Scope scope={`availSummary[${i}]`} key={i}>
                              <TableRow key={i}>
                                <TableCell>
                                   <Typography>{item.title}</Typography>
                                </TableCell>

                                <TableCell>
                                  <Typography>{item.status}</Typography>
                                </TableCell>

                                <TableCell>
                                  <Typography>{item.type}</Typography>
                                </TableCell>

                                <TableCell>
                                  
                                  <Typography>{item.slug}</Typography>
                                </TableCell>

                                <TableCell>
                                  <Box mb={3} style={{ marginTop: "2%" }}>
                                    <Button
                                      type="submit"
                                      variant="contained"
                                      color="primary"
                                      className={globalClass.largeBtnContainer}
                                      onClick={() => {
                                        updatehandleOpen(item)
                                      }}
                                    >
                                      {lodingStatus.logoLodingStatus ? (
                                        <Loader
                                          width="200px"
                                          height="20px"
                                          color="white"
                                        />
                                      ) : (
                                        "Update"
                                      )}
                                    </Button>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            </Scope>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>

             
            </div>
          </Form>
        </>
      )}
      {addModelOpen && (
        <Modal
          open={addModelOpen}
          onClose={addhandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle}>
            <Box
              mt="1rem"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography id="modal-modal-title" variant="h4" component="h2">
                Add Appointment Type
              </Typography>
              <Close onClick={addhandleClose} style={{cursor: "pointer"}}/>
            </Box>
            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Name
                </Typography>
              </Box>
              <Grid>
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  name="name"
                  field="name"
                  placeholder="title"
                  className={classes.style_2}
                  value={appoinmentData.name}
                  onChange={handelChangeAdd}
                />
              </Grid>
            </Box>
            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Status
                </Typography>
              </Box>
              <Grid>
                <Select
                  style={{ width: "100%" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  name="status"
                  value={appoinmentData.status}
                  onChange={handelChangeAdd}
                >
                  {socialMediaArr.map((value) => (
                    <MenuItem value={value}>{value}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </Box>
            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Appointment Type
                </Typography>
              </Box>
              <Grid>
                <Select
                  style={{ width: "100%" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  name="appoinmenttype"
                  value={appoinmentData.appoinmenttype}
                  onChange={handelChangeAdd}
                >
                  {appoinmenttypeArr.map((value) => (
                    <MenuItem value={value}>{value}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Slug
                </Typography>
              </Box>
              <Grid>
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  name="slug"
                  field="Sulg"
                  placeholder="slug"
                  className={classes.style_2}
                  value={appoinmentData.slug}
                  onChange={handelChangeAdd}
                />
              </Grid>
            </Box>
            <Box mb={3} style={{ marginTop: "2%" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={globalClass.largeBtnContainer}
                onClick={handleSaveAdd}
              >
                {lodingStatus.logoLodingStatus ? (
                  <Loader width="200px" height="20px" color="white" />
                ) : (
                  "SAVE"
                )}
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

      {updateModelOpen && (
        <Modal
          open={updateModelOpen}
          onClose={updatehandleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalStyle}>
            <Box
              mt="1rem"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography id="modal-modal-title" variant="h4" component="h2">
                Update Appointment Type
              </Typography>
              <Close onClick={updatehandleClose} style={{cursor: "pointer"}}/>
            </Box>
            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Name
                </Typography>
              </Box>
              <Grid>
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  name="name"
                  field="name"
                  placeholder="title"
                  value={UpdateappoinmentData.name}
                  className={classes.style_2}
                  onChange={handleUpdateAdd}
                />
              </Grid>
            </Box>
            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Status
                </Typography>
              </Box>
              <Grid>
                <Select
                  style={{ width: "100%" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  name="status"
                  value={UpdateappoinmentData.status}
                  onChange={handleUpdateAdd}
                >
                  {socialMediaArr.map((value) => (
                    <MenuItem value={value}>{value}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Appointment Type
                </Typography>
              </Box>
              <Grid>
                <Select
                  style={{ width: "100%" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  name="appoinmenttype"
                  value={UpdateappoinmentData.appoinmenttype  || uniqueData.appoinmenttype}
                  onChange={handleUpdateAdd}
                >
                  {appoinmenttypeArr.map((value) => (
                    <MenuItem value={value}>{value}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </Box>

            <Box>
              <Box mt="0.5rem">
                <Typography >
                  Slug
                </Typography>
              </Box>
              <Grid>
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  name="slug"
                  field="Sulg"
                  placeholder="slug"
                  value={UpdateappoinmentData.slug}
                  className={classes.style_2}
                  onChange={handleUpdateAdd}
                />
              </Grid>
            </Box>
            <Box mb={3} style={{ marginTop: "2%" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={globalClass.largeBtnContainer}
                onClick={handleUpdateApp}
              >
                {lodingStatus.updateLodingStatus ? (
                  <Loader width="200px" height="20px" color="white" />
                ) : (
                  "UPDATE"
                )}
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default AppoinmentTypeSetting;
