import React, { useState, useEffect } from "react";
import { Tab, makeStyles } from "@material-ui/core";
import { Box } from "@material-ui/core";
import TabContext from "@mui/lab/TabContext";
import { TabList } from "@mui/lab";
import TabPanel from "@mui/lab/TabPanel";
import Profile from "../profile/profile";
import AvailabilityType from "../appointment_type/myavailability";
import PaymentProfileTab from "../payment-profile/paymentProfileTab";
import OtherTab from "../otherTab/other-tab";
import MeetingLink from "../otherTab/meetingLink";
import Axios from "axios";
import Unvailibility from "../unavailibility/unavailbility";
import CustomTab from "../customTab/customTab";
import LetterHead from "../letterhead/letterhead";
import { AccessSharing } from "../access-sharing";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const useStyles = makeStyles((theme) => ({
  tab: {
    fontSize: "0.9rem",
    textTransform: "none",
  },
}));

const ProfileTab = () => {
  // set document title
  useDocumentTitle("Profile");

  const classes = useStyles();
  const [value, setValue] = React.useState("1");
  const [status, setStatus] = useState(false);
  const handleChange = (event = React.SyntheticEvent, newValue = Number) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [resp, setResp] = useState(null);
  const [loading, setLoading] = useState(false);

  const userId = JSON.parse(localStorage.getItem("USER"));

  const getUser = JSON.parse(localStorage.getItem("USER"));

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_API2_HOST}getprofile?id=${userId.id}`
    ).then((resp) => {
      if (resp) {
        setResp(resp.data.data);
        setLoading(true);
      }
    });
  }, [value, status]);

  return (
    <>
      {getUser.roleName === "DOCTOR" || getUser.roleName === "DOCTOR_ADMIN" ? (
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  className={classes.tab}
                  label="Profile"
                  value="1"
                  {...a11yProps(1)}
                />

                <Tab
                  className={classes.tab}
                  label="My Availability"
                  value="2"
                  {...a11yProps(2)}
                />
                <Tab
                  className={classes.tab}
                  label=" My Unavailabity"
                  value="3"
                  {...a11yProps(3)}
                />
                <Tab
                  className={classes.tab}
                  label="Payment Option"
                  value="4"
                  {...a11yProps(4)}
                />
                {/* <Tab
                className={classes.tab}
                label="Social Media link"
                value="5"
                {...a11yProps(5)}
              /> */}
                <Tab
                  className={classes.tab}
                  label="Custom Menu"
                  value="6"
                  {...a11yProps(6)}
                />
                <Tab
                  className={classes.tab}
                  label="Meeting Link"
                  value="7"
                  {...a11yProps(7)}
                />
                <Tab
                  className={classes.tab}
                  label="Letter Head"
                  value="8"
                  {...a11yProps(8)}
                />

                <Tab
                  className={classes.tab}
                  label="Other"
                  value="9"
                  {...a11yProps(9)}
                />

                <Tab
                  className={classes.tab}
                  label="Access Sharing"
                  value="10"
                  {...a11yProps(10)}
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Profile respo={resp} />
            </TabPanel>

            <TabPanel value="2">
              <AvailabilityType />
            </TabPanel>
            <TabPanel value="3">
              <Unvailibility />
            </TabPanel>
            <TabPanel value="4">
              <PaymentProfileTab />
            </TabPanel>
            <TabPanel value="6">
              <CustomTab respo={resp} />
            </TabPanel>
            <TabPanel value="7">
              <MeetingLink respo={resp} setStatus={setStatus} status={status} />
            </TabPanel>
            <TabPanel value="8">
              <LetterHead respo={resp} />
            </TabPanel>
            <TabPanel value="9">
              <OtherTab respo={resp} setStatus={setStatus} status={status} />
            </TabPanel>
            <TabPanel value="10" sx={{minHeight: "80vh"}}>
              <AccessSharing />
            </TabPanel>
          </TabContext>
        </Box>
      ) : (
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab
                  className={classes.tab}
                  label="Profile"
                  value="1"
                  {...a11yProps(1)}
                />

                {/* <Tab
                  className={classes.tab}
                  label="Other"
                  value="2"
                  {...a11yProps(2)}
                /> */}
              </TabList>
            </Box>
            <TabPanel value="1">
              <Profile respo={resp} />
            </TabPanel>

            {/* <TabPanel value="7" >
              <OtherTab respo={resp} setStatus={setStatus} status={status}/>
            </TabPanel> */}
          </TabContext>
        </Box>
      )}
    </>
  );
};

export default ProfileTab;
