import React, { useState, useCallback, useRef } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import debounce from 'lodash/debounce';

const InfiniteScrollDropdown = ({ apiCall, label, placeholder, value, onChange }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [totalPages, setTotalPages] = useState(1);

  const listboxRef = useRef(null);

  // Implement debounced search with pagination
  const debouncedFetchOptions = useCallback(
    debounce(async (search, page) => {
      setLoading(true);
      try {
        const { options: newOptions, totalPages: newTotalPages } = await apiCall(search, page);
        setOptions((prevOptions) => 
          page === 0 ? newOptions : [...prevOptions, ...newOptions]
        );
        setHasMore(page < newTotalPages - 1);
        setTotalPages(newTotalPages);
      } catch (error) {
        console.error('Error fetching options:', error);
      } finally {
        setLoading(false);
      }
    }, 300),
    [apiCall]
  );

  // Updated handleScrollOrMove function
  const handleScrollOrMove = useCallback(() => {
    const listboxNode = listboxRef.current;
    if (!listboxNode) return;

    const isAtBottom = 
      listboxNode.scrollTop + listboxNode.clientHeight >=
      listboxNode.scrollHeight - 50;

    if (isAtBottom && !loading && hasMore && page < totalPages - 1) {
      setPage((prevPage) => {
        setLoading(true);
        const nextPage = prevPage + 1;
        debouncedFetchOptions(inputValue, nextPage);
        setLoading(false);
        return nextPage;
      });
    }
  }, [loading, hasMore, inputValue, debouncedFetchOptions, page, totalPages]);

  return (
    <Autocomplete
    forcePopupIcon={false}
      options={options}
      value={value}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          sx={{
            fontFamily: 'Comfortaa',
            '& .MuiInputBase-input': {
              fontFamily: 'Comfortaa',
            },
            '& .MuiInputLabel-root': {
              fontFamily: 'Comfortaa',
              fontWeight: 800,
              fontSize: "1.0rem",
              '&.Mui-focused': {
                color: '#1C2B3B',
              },
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: '#1C2B3B',
            },
          }}
        />
      )}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
        setPage(0);
        debouncedFetchOptions(newInputValue, 0);
      }}
      ListboxProps={{
        ref: listboxRef,
        onScroll: handleScrollOrMove,
        onMouseMove: handleScrollOrMove,
      }}
      loading={loading}
      filterOptions={(x) => x}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value?.value}
    />
  );
};

export default InfiniteScrollDropdown;
