import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfiniteScrollDropdown from "../../components/form/InfiniteScrollDropdown";
import CloseIcon from "@material-ui/icons/Close";
import { SHOW_ERROR_NOTIFICATION } from "../../utils/app-util";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import { useQueryClient } from "@tanstack/react-query";
import { SaveJobComment, UpdateJobStatus, DeleteJob, EditJob } from "../../apis/JobAPI";
import SearchAPI from "../../apis/SearchAPI";

const calculateOverdueDays = (dueDate) => {
  const today = new Date();
  const due = new Date(dueDate);
  const diffTime = Math.max(today - due, 0);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 600,
    minWidth: 400,
    width: "100%",
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
    paddingTop: theme.spacing(4),
    borderRadius: "10px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  button: {
    marginTop: theme.spacing(2),
  },
  header: {
    fontSize: "1.4rem",
    fontWeight: 800,
  },
  textFieldLabel: {
    fontSize: "1.0rem",
    fontWeight: 800,
  },
  textField: {
    fontSize: "0.9rem",
    fontWeight: 400,
  },
  statusText: {
    fontSize: "0.8rem",
  },
}));

const JobModal = ({
  open,
  handleClose,
  title,
  onSubmit,
  data,
  viewOnly,
  isAssignedTo,
  newJob
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [jobName, setJobName] = useState("");
  const [description, setDescription] = useState("");
  const [dueDate, setDueDate] = useState(dayjs());
  const [selectedUser, setSelectedUser] = useState(null);
  const [comment, setComment] = useState("");
  const [status, setStatus] = useState("");

  const overdueDays =
    data?.status === "OVERDUE" ? calculateOverdueDays(data?.dueDate) : null;

  const queryClient = useQueryClient();
  const saveJobComment = SaveJobComment();
  const updateJobStatus = UpdateJobStatus();
  const editJob = EditJob();
  const deleteJob = DeleteJob();

  const handleSaveJobComment = (payload) => {
    saveJobComment.mutate(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["jobs"] });
      },
    });
  };

  const handleMarkAsCompleted = () => {
    const payload = new FormData();
    payload.append("status", "COMPLETED");
    updateJobStatus.mutate(
      { payload, id: data?.id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["jobs"] });
          handleClose();
        },
      }
    );
  };

  const clearState = () => {
    setJobName("");
    setDescription("");
    setDueDate(dayjs());
    setSelectedUser(null);
    setStatus("");
    setComment("");
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    if (!jobName || !dueDate || !selectedUser) {
      SHOW_ERROR_NOTIFICATION("Please fill all the fields");
      return;
    }
    const payload = {
      assignedTo: {
        slug: selectedUser?.slug,
      },
      taskName: jobName,
      taskDescription: description,
      startDate: new Date().toISOString(),
      dueDate: new Date(dueDate).toISOString(),
      isRecurring: false,
      status: "ASSIGNED",
    };
    if (data?.id) {
      onSubmit(payload, data?.id);
    } else {
      onSubmit(payload);
    }
    clearState();
    handleClose();
  };

  const handleEditJob = (id) => {
    const payload = {
        "assignedTo": {
            "slug": selectedUser
        },
        "taskName": jobName,
        "taskDescription": description,
        "startDate": new Date(data?.startDate).toISOString(),
        "dueDate": new Date(dueDate).toISOString(),
        "isRecurring": false,
        "status": status,
        "comment": comment
    }
    editJob.mutate({payload, id}, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["jobs"] });
        handleClose();
      },
    });
  }

  const handleDeleteClick = (id) => {
    deleteJob.mutate(id, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['jobs'] });
            handleClose();
        },
        onError: () => {
            SHOW_ERROR_NOTIFICATION("An error occurred while deleting the job");
        }
    });
}

  useEffect(() => {
    if (data?.id) {
      clearState();
      setJobName(data?.taskName);
      setDescription(data?.taskDescription);
      setDueDate(dayjs(data?.dueDate));
      setSelectedUser(data?.assignedTo?.slug);
      setStatus(data?.status);
      setComment(data?.comment);
    }
  }, [data]);

  const fetchUserOptions = async (inputValue, page) => {
    try {
      const response = await SearchAPI.searchStaff(inputValue, page);
      const users = response.content; // Assuming the API returns a data property with the users
      const totalPages = response.totalPages; // Assuming the API returns a totalPages property

      const options = users.map(user => ({
        label: `${user.name} (${user.roleName})`,
        value: user.slug
      }));

      return {
        options,
        totalPages
      };
    } catch (error) {
      console.error("Error fetching user options:", error);
      return {
        options: [],
        totalPages: 0
      };
    }
  };

  return (
    <Modal open={open} onClose={() => {
      clearState();
      handleClose();
    }}>
      <Box className={classes.modalStyle}>
        <Box
          mt="10px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography className={classes.header} variant="h4" component="h2">
            {title}
          </Typography>
          <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
        </Box>
          {data?.id ? (
            <TextField
              fullWidth
              label="Assigned To"
              value={data?.assignedTo?.name}
              onChange={(e) => setJobName(e.target.value)}
              margin="normal"
              disabled
              InputLabelProps={{ className: classes.textFieldLabel }}
              InputProps={{ className: classes.textField }}
            />
          ) : (
            <Box sx={{marginTop: "10px"}}>
              <InfiniteScrollDropdown
              apiCall={fetchUserOptions}
              label="Search Staff*"
              placeholder="Search Staff*"
              value={selectedUser ? { label: selectedUser.name, value: selectedUser.slug } : null}
              onChange={(option) => {
                setSelectedUser(option ? { name: option.label, slug: option.value } : null);
              }}
            />
            </Box>
          )}
          <TextField
            fullWidth
            label="Job Name*"
            value={jobName}
            onChange={(e) => setJobName(e.target.value)}
            margin="normal"
            disabled={viewOnly}
            InputLabelProps={{ className: classes.textFieldLabel }}
            InputProps={{ className: classes.textField }}
          />
          <TextField
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            margin="normal"
            multiline
            rows={description.length > 50 ? 4 : 2}
            disabled={viewOnly}
            InputLabelProps={{ className: classes.textFieldLabel }}
            InputProps={{ className: classes.textField }}
          />
          <DesktopDatePicker
            label="Due Date*"
            format="DD/MM/YYYY"
            value={dueDate}
            onChange={(newValue) => {
              setDueDate(dayjs(newValue));
            }}
            fullWidth
            sx={{ width: "100%", marginTop: "15px" }}
            disabled={viewOnly}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{ className: classes.textField }}
              />
            )}
            minDate={dayjs()}
            maxDate={dayjs().add(1, "year")}
          />
          {/* Show comment text field which is disabled when the job is not assigned to the user */}
          {!isAssignedTo && title !== "Assign Job" && (
            <div className={classes.formControl}>
              <TextField
                fullWidth
                label="Comment"
                value={data?.comment}
                margin="normal"
                multiline
                disabled
                InputLabelProps={{ className: classes.textFieldLabel }}
                InputProps={{ className: classes.textField }}
              />
            </div>
          )}
          {isAssignedTo && title !== "Assign Job" && viewOnly && (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "4px",
              }}
              marginTop={"10px"}
            >
              <Typography
                className={classes.textFieldLabel}
                variant="h3"
                component="h2"
                disabled
              >
                Status:
              </Typography>
              <Typography
                className={classes.textField}
                sx={{
                  marginLeft: "10px",
                  color: overdueDays ? "red" : "inherit",
                }}
                disabled
              >
                {data?.status}
                {overdueDays ? ` (${overdueDays} days overdue)` : ""}
              </Typography>
            </Box>
          )}
          {!isAssignedTo && title !== "Assign Job" && (
            <FormControl
              className={classes.formControl}
              style={{ marginTop: "15px" }}
            >
              <InputLabel className={classes.textFieldLabel}>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                disabled={viewOnly}
              >
                <MenuItem value="ASSIGNED">ASSIGNED</MenuItem>
                <MenuItem value="COMPLETED">COMPLETED</MenuItem>
                <MenuItem value="OVERDUE">OVERDUE</MenuItem>
                {/* Add more status options as needed */}
              </Select>
            </FormControl>
          )}
          {newJob && (
            <Box display="flex" justifyContent="flex-end" gap="10px">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleSubmit}
              >
                {"Save"}
              </Button>
            </Box>
          )}
          {!viewOnly && !newJob && (
            <Box display="flex" justifyContent="flex-end" gap="10px">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                style={{
                  marginRight: "10px",
                }}
                onClick={() => {
                  handleDeleteClick(data?.id);
                }}
              >
                {"Delete"}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => {
                  handleEditJob(data?.id);
                }}
              >
                {"Update"}
              </Button>
            </Box>
          )}
          {isAssignedTo &&
            data?.status !== "COMPLETED" &&
            data?.status?.length > 0 && (
              <>
                <div className={classes.formControl}>
                  <TextField
                    fullWidth
                    label="Comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    margin="normal"
                    multiline
                    rows={1}
                    disabled={!viewOnly}
                    InputLabelProps={{ className: classes.textFieldLabel }}
                    InputProps={{ className: classes.textField }}
                  />
                </div>
                <Box display="flex" justifyContent="flex-end" gap="10px">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.common.white,
                      marginRight: "10px",
                    }}
                    onClick={() => {
                      handleSaveJobComment({
                        payload: {
                          id: data?.id,
                          comment: comment,
                        },
                      });
                      clearState();
                      handleClose();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.common.white,
                    }}
                    onClick={handleMarkAsCompleted}
                  >
                    Mark as Completed
                  </Button>
                </Box>
              </>
            )}
      </Box>
    </Modal>
  );
};

export default JobModal;
