import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tabs,
  Tab,
  Grid2,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";

import { Typography, Button, useTheme } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import AppConstants from "../../utils/app-constants";
import CenteredTypography from "../../components/common/CenteredTypography";
import CustomCircularLoader from "../../components/common/CustomCircularLoader";
import CustomActionButton from "../../components/common/CustomActionButton";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { AppointmentAPI } from "../../apis/AppointmentAPI";
import {
  SHOW_ERROR_NOTIFICATION,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
} from "../../utils/app-util";
import { addAppointmentPayloadBuilder, fetchAvailableSlotsOfADoctorOnAParticularDate, filterPastAppointmentTimeSlots } from "./appointment.util";
import { DoctorAPI } from "../../apis/DoctorAPI";

const AppointmentReschedulerDialog = ({
  isDialogOpen = false,
  handleDialogClose,
  appointmentObjectToBeRescheduled,
}) => {
  const theme = useTheme();
  const currentLoggedInUser = useSelector((state) => state?.user);
  // ------ local states ------
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [activeTab, setActiveTab] = useState(0);
  const [filteredTimeSlots, setFilteredTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [shouldNotify, setShouldNotify] = useState(true);
  const [areSlotsLoading, setAreSlotsLoading] = useState(true);
  const [customTimeValue, setCustomTimeValue] = useState(null);
  const [showSaveLoader, setShowSaveLoader] = useState(false);
  const [doctorSlotDetails, setDoctorSlotDetails] = useState(null);

  //   ------ side-effects ------
  useEffect(() => {
    DoctorAPI.getSlotDetailOfADoctorWithSlotId(appointmentObjectToBeRescheduled?.doctorSlug, appointmentObjectToBeRescheduled?.slotId).then(response => {
      if(response?.showMessageOnly) {
        SHOW_ERROR_NOTIFICATION(response?.message);
      } else {
        setDoctorSlotDetails(response?.data);
      }
    })
  }, []) // on-initial load, fetch slot details of the selected appointment
  
  useEffect(() => {
    // clear old states
    setSelectedTimeSlot("");
    setCustomTimeValue(null);

    setAreSlotsLoading(true);
    fetchAvailableSlotsOfADoctorOnAParticularDate(
      appointmentObjectToBeRescheduled?.doctorSlug,
      selectedDate.format(AppConstants.DATE_FORMAT_FOR_API),
      appointmentObjectToBeRescheduled?.appointmentTypeId
    ).then((allAvailableSlots) => {
      setFilteredTimeSlots(
        filterPastAppointmentTimeSlots(selectedDate, allAvailableSlots)
      );
      setAreSlotsLoading(false);
    });
  }, [selectedDate]);

  // --- event handlers ------
  const handleSaveChanges = () => {
   // only reschedule if details of the doctor slot is present 
    if (doctorSlotDetails !== null) {
      // check if appointment exists for this time
      setShowSaveLoader(true);
      AppointmentAPI.checkExistingAppointment(
        appointmentObjectToBeRescheduled?.doctorSlug,
        appointmentObjectToBeRescheduled?.patientSlug,
        selectedDate.format(AppConstants.DATE_FORMAT_FOR_API),
        true
      )
        .then((response) => {
          if (response?.showMessageOnly) {
            // Appointment already booked for this date 
            SHOW_INFO_NOTIFICATION(response?.message);
            setShowSaveLoader(false);
          } else {
            // Instead of updating the current appointment -> cancel and create a new appointment with the same details
            AppointmentAPI.updateAppointmentStatus(
              appointmentObjectToBeRescheduled?.slug,
              AppConstants.APPOINTMENT_STATUS.CANCELLED
            ).then((_) => {
              const rescheduledTimeSlot =
                customTimeValue === null
                  ? selectedTimeSlot?.startTimeOnly
                  : customTimeValue?.format("HH:mm");

              const appointmentTypeSlotDuration = doctorSlotDetails?.minutes;
              // create new appointment with the same details
              AppointmentAPI.addNewAppointment(
                addAppointmentPayloadBuilder(
                  selectedDate,
                  rescheduledTimeSlot,
                  appointmentTypeSlotDuration,
                  appointmentObjectToBeRescheduled,
                  currentLoggedInUser
                )
              ).then((_) => {
                if (shouldNotify) {
                  AppointmentAPI.notifyAboutAppointmentReschedule(appointmentObjectToBeRescheduled?.slug,
                    appointmentObjectToBeRescheduled?.dateTime, appointmentObjectToBeRescheduled?.time.split(':').slice(0, 2).join(':'),
                    selectedDate, rescheduledTimeSlot);
                }
                SHOW_SUCCESS_NOTIFICATION("Appointment Rescheduled successfully.");
                setShowSaveLoader(false);
                handleDialogClose(true);
              });

            });
          }
        })
        .catch((err) => {
          setShowSaveLoader(false);
          SHOW_ERROR_NOTIFICATION("Error fetching available time slots.");
        });
    }
  };

  const handleTabChange = (_, newValue) => setActiveTab(newValue);

  return (
    <div>
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 0,
          }}
        >
          <Typography variant="h4">
            <strong>Reschedule Appointment</strong>
          </Typography>
          <IconButton onClick={handleDialogClose} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          {/* Row One */}
          <Typography>
            Choose a new date and time for the appointment
          </Typography>

          {/* Row Two: Date Picker */}
          <DatePicker
            label="Select Date"
            value={selectedDate}
            onChange={(newDate) => setSelectedDate(newDate)}
            fullWidth
            format={AppConstants.DATE_FORMAT}
            minDate={dayjs()}
          />

          {/* Row Three: Tabs */}
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
          >
            <Tab
              label="Time Slots"
              sx={{
                fontFamily: "Comfortaa",
                textTransform: "none",
                fontSize: "12px",
              }}
            />
            <Tab
              label="Custom Time"
              sx={{
                fontFamily: "Comfortaa",
                textTransform: "none",
                fontSize: "12px",
              }}
            />
          </Tabs>

          {/* Row Four: Tab Content */}
          {activeTab === 0 && (
            <Box
              sx={{
                maxHeight: 200,
                overflowY: "auto",
                scrollbarWidth: "thin",
                "&::-webkit-scrollbar": {
                  width: "8px",
                  backgroundColor: "red",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                  borderRadius: "8px",
                  outline: "1px solid slategrey",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#555",
                },
                "&::-webkit-scrollbar-button": {
                  display: "none",
                },
              }}
            >
              <Grid2
                container
                columnSpacing={1}
                rowSpacing={1}
                xs={12}
                sx={{
                  maxHeight: 100,
                }}
              >
                {filteredTimeSlots?.length ? (
                  filteredTimeSlots
                    .filter((slot) => slot?.available)
                    .map((slot, index) => (
                      <Grid2
                        item
                        size={4}
                        key={`${slot?.slotId}-${index}`}
                        alignItems="center"
                        justifyContent="center"
                      >
                        {/* ONLY SHOW EMPTY TIME SLOTS */}
                        {slot?.available && (
                          <Button
                            variant={
                              selectedTimeSlot?.startTimeOnly ===
                                slot?.startTimeOnly
                                ? "contained"
                                : "outlined"
                            }
                            style={{
                              backgroundColor: `${selectedTimeSlot?.startTimeOnly ===
                                slot?.startTimeOnly
                                ? theme?.palette?.primary?.main
                                : "white"
                                }`,
                              color: `${selectedTimeSlot?.startTimeOnly ===
                                slot?.startTimeOnly
                                ? "white"
                                : "black"
                                }`,
                            }}
                            fullWidth
                            onClick={() => {
                              setCustomTimeValue(null);
                              setSelectedTimeSlot(slot);
                            }}
                          >
                            {slot?.startTimeOnly}
                          </Button>
                        )}
                      </Grid2>
                    ))
                ) : (
                  <>
                    {areSlotsLoading === true ? (
                      <CustomCircularLoader />
                    ) : (
                      <CenteredTypography text="No time slots available for this date." />
                    )}
                  </>
                )}
              </Grid2>
            </Box>
          )}
          {activeTab === 1 && (
            <Box>
              <TimePicker
                orientation="landscape"
                label="Select Time"
                value={customTimeValue}
                onChange={(newDayjsTime) => {
                  setSelectedTimeSlot("");
                  setCustomTimeValue(newDayjsTime);
                }}
                sx={{ width: "100%" }}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                ampm={false}
              />
            </Box>
          )}

          {/* Row Five: Notify Checkbox */}
          <FormControlLabel
            control={
              <Checkbox
                checked={shouldNotify}
                onChange={(e) => setShouldNotify(e.target.checked)}
                sx={{
                  "&.Mui-checked": {
                    color: "#3E587A",
                  },
                }}
              />
            }
            sx={{ fontFamily: "Comfortaa", fontSize: "9px" }}
            label={
              <Typography variant="h6">Notify about the reschedule</Typography>
            }
          />
        </DialogContent>

        {/* Row Six: Dialog Actions */}
        <DialogActions sx={{ justifyContent: "flex-end" }}>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <CustomActionButton
            onClick={handleSaveChanges}
            buttonTitle="Save Changes"
            disabled={customTimeValue === null && selectedTimeSlot === ""}
            showLoader={showSaveLoader}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

AppointmentReschedulerDialog.prototype = {
  openDialog: PropTypes.bool.isRequired,
  handleDialogClose: PropTypes.func.isRequired,
  appointmentObjectToBeRescheduled: PropTypes.object.isRequired,
};

export default AppointmentReschedulerDialog;
