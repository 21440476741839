import {
  Box, fade,
  Grid,
  makeStyles, Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AuthService from "../../utils/services/auth-service";
import useGlobalStyles from "../../utils/global-style";
import Axios from "axios";
import Fab from '@material-ui/core/Fab';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import PatientTurn from "../dashboard/patient-turn";
import bg1 from "../../assets/active_patient_background.png";
import soundFileDoorBell from "../../assets/doorbell.wav";
import AppConstants from "../../utils/app-constants";
import {
  SelectInput
} from "sixsprints-react-ui";
import useDocumentTitle from "../../hooks/useDocumentTitle";



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexWrap: "wrap",
    padding: theme.spacing(6),
    width: "100%",
  },

  greetingText: {
    marginTop: theme.spacing(7.5),
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
  greeting: {
    borderRadius: 14,
    height: "72px",
    background: `transparent linear-gradient(90deg, ${theme.palette.primary.main} 0%, #F6F7FC 100%) 0% 0% no-repeat padding-box`,
    boxShadow: "0px 5px 20px #00000008",
  },

  containerBox: {
    marginTop: theme.spacing(6),
    padding: "2rem",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderRadius: 14,
  },

  customWidth: {
    width: "90%",
  },

  chatContainer: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(6),
    padding: theme.spacing(6),
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderRadius: 14,
  },
  chatListContainer: {
    maxHeight: "500px",
    overflowY: "auto",
  },
  chatSearch: {
    position: "relative",
    borderRadius: 25,
    padding: theme.spacing(5, 5, 5, 10),
    border: "1.5px solid #ddd",
    margin: `${theme.spacing(5)}px 0`,
    backgroundColor: fade(theme.palette.common.white, 0.05),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.1),
    },
    width: "auto",
  },
  chatInput: {
    fontSize: 14,
    fontFamily: "Comfortaa",
    border: "hidden",
    "&:focus": {
      outline: "none",
    },
    backgroundColor: "transparent",
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  gridPadding: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(8),
  },
  topPadding: {
    marginTop: theme.spacing(8),
  },
  appointmentContainer: {
    marginTop: "100",
  },
  main: {
    backgroundColor: "white"
  }
}));
const ActivePatientStatus = () => {
  // set document title
  useDocumentTitle("Active Patient");

  const classes = useStyles();
  const globalClass = useGlobalStyles();
  let history = useHistory();
  const [todaysAppntmnt, setTodaysAppntmnt] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const user = AuthService.getUser();
  const [datashow, setDatashow] = useState(false);

  const [doctors, setDoctors] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [datauser, setDataUser] = useState([]);
  const [callNext, setCallNext] = useState(false);
  const [status, setStatus] = useState({});
  const [calledUser, setCalledUser] = useState([]);
  // const [calledPatient,setCallPatient]=useState([])

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    // setDatashow(false);
    setDatashow(true);
  };
  const [data, setData] = useState([]);
  useEffect(() => {
    console.log('test_checking rendeer')
    Axios.get(`${process.env.REACT_APP_API2_HOST}getdoctor?active=true`).then(
      (resp) => {
        if (resp) {
          setDoctors(resp.data);
          setDatashow(false);

          resp.data.map((item, index) => {
            // checkedState.push(true);
            data.push(item.slug);
          });
          console.log('test_slug', data);
        }
      }
    );
  }, [selectedValue]);


  var today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2);
  const playBeepSoundOnStatus = () => {
    let audio = new Audio(soundFileDoorBell);
    audio.play();
  };
  const playBeepSound = (data) => {
    return data?.map((resp) => {
      let key = resp.slug;
      if (resp.called) {
        if (localStorage.getItem(key)) {
          return null;
        } else {
          let audio = new Audio(soundFileDoorBell);
          audio.play();
          localStorage.setItem(key, resp.called);
          return null;
        }
      }

      if (
        resp.status === "CHECKED_IN" &&
        user.roleName === AppConstants.ROLE.DOCTOR &&
        resp.called === false
      ) {
        let doctorCalled = localStorage.getItem(`doctorCalled`);
        if (doctorCalled) {
          doctorCalled = JSON.parse(doctorCalled);
        } else {
          doctorCalled = {};
        }
        if (doctorCalled[key]) {
          return null;
        } else {
          playBeepSoundOnStatus();
          localStorage.setItem(
            "doctorCalled",
            JSON.stringify({ ...doctorCalled, [key]: true })
          );
          return null;
        }
      }
      localStorage.removeItem(key);
      return null;
    });
  };

  const isQueueUser = user.roleName === AppConstants.ROLE.QUEUE;
  // console.log('test_isQueueUser',isQueueUser)

  useEffect(() => {
    async function fetchAppointments() {
      if (selectedValue !== "") {


        const req = {
          filterModel: {

            doctors: data,
            date: {
              filter: date,
              filterType: "date",
              type: "equals",
            },
          },
        };

        try {
          const response = await Axios({
            method: "post",
            url: `${process.env.REACT_APP_API2_HOST}get_appointment`,
            data: req,
            headers: { "Content-Type": "application/json" },
          });
          if (response) {
            console.log('test_getappointmentfromDashboard', response);
            if (response.data.data) {
              playBeepSound(response.data.data);

              if (callNext) {
                setCallNext(false);
              }


              let calledPatient = []
              const array = response?.data?.data;
              for (const item of array) {
                if (item.called === true && item.doctorSlug === selectedValue?.slug) {
                  calledPatient.push(item)
                  // setCallPatient(item)

                  break;
                } else {
                  setDatashow(false)
                }

              }


              // setTodaysAppntmnt(response?.data?.data);
              setTodaysAppntmnt(calledPatient);

            } else if (response.data.data === null) {
              setTodaysAppntmnt([]);
            }
          }
        } catch (error) {
          console.log(error);
        }


      }
      else {
        setDatashow(false);
      }

    };

    fetchAppointments()

  }, [refresh])

  const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }


  const handlePopUp = async (patients) => {
    const patientTurn = patients;
    const localStore = localStorage.getItem('allreadyCalled');
    var updateLocalStore = [];
    if (localStore) {
      updateLocalStore = JSON.parse(localStore);
    }

    for (let i = 0; i < patientTurn.length; i++) {

      setCalledUser(patientTurn[i])
      setDatashow(true);

      updateLocalStore.push(patientTurn[i]?.id);
      localStorage.setItem('allreadyCalled', JSON.stringify(updateLocalStore));

      await timeout(4500)
      setDatashow(false);

      await timeout(500)
    }
  }








  useEffect(() => {
    setTimeout(() => {
      setRefresh(!refresh);
    }, 5000);
  }, [refresh])

  // useEffect(fetchAppointments, [refresh, selectedValue]);
  //rows = todaysAppntmnt
  useEffect(() => {
    setStatus((prev) => {
      const allStatus = JSON.parse(JSON.stringify(prev));
      todaysAppntmnt.forEach((row) => {
        allStatus[row?.slug] = row?.status;
      });
      return allStatus;
    });
  }, [todaysAppntmnt]);


  useEffect(() => {
    if (todaysAppntmnt?.length) {
      console.log('test_rows', todaysAppntmnt);
      const calledUserList = JSON.parse(localStorage.getItem('allreadyCalled'));
      const calledPatient = todaysAppntmnt?.filter(pat => {
        return pat?.called;
      })
      console.log('test_calledPatient', calledPatient);

      const toBeCalled = calledPatient?.filter(el => {
        return !calledUserList?.find(element => {
          return element === el?.id;
        })
      });
      console.log('test_tobecalled', toBeCalled);

      toBeCalled && handlePopUp(toBeCalled);
    }
  }, [todaysAppntmnt])

  return (
    <>
      <div style={{ position: "absolute", top: 0, left: 0, backgroundColor: "#f6f7fc", height: "100vh", width: "95vw" }}>
        {/* <Box>
                 <Header />
               </Box> */}
        <Grid container className={classes.root} spacing={3} width="100%">
          <Grid
            item
            style={{
              padding: "0.8%",
            }}
            md={12}
            sm={12}
            xs={12}
            className={classes.gridPadding}
          >
            <Grid item className={classes.topPadding}>

              <Box display="flex" alignItems="center" justifyContent="center" >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Grid container spacing={3} alignItems="center"  >
                    {/* <Grid item>
                      <Typography variant="h6">{`Doctor Name:`}</Typography>
                    </Grid> */}
                    <Grid item>


                      <SelectInput
                        required={true}
                        options={doctors}
                        onChange={handleChange}
                        value={selectedValue}
                        // valueKey={[]}
                        labelKey="name"
                        field="doctorSlug"
                        placeholder="Select Doctor"
                        label="Doctor"
                      />
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {
        selectedValue !== "" &&
        <>
          {/* appointement list */}
          <div style={{
            width: "100%",
            backgroundImage: `url(${bg1})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'block',
            justifyContent: 'center',
            alignItems: 'center',
            position: "absolute",
            left: "0px",
            height: "100vh",
            padding: "20px"

          }}>
            <center>
              <img src={selectedValue?.profilePic} style={{ height: "170px", width: "170px", borderRadius: "50%", objectFit: "cover" }} />
              <Typography style={{ color: "white", fontFamily: "sans-serif", fontWeight: "700", paddingTop: "20px" }} variant="h2">{`${selectedValue?.name}`}</Typography>
              <Typography style={{ color: "white", fontFamily: "sans-serif", fontWeight: "700", }} variant="h4">{`${selectedValue?.speciality?.name}`}</Typography>
              <Typography style={{ color: "white", fontFamily: "sans-serif", fontWeight: "700", }} variant="h4">{`Room number: ${selectedValue?.roomNumber}`}</Typography>
              {
                // isQueueUser && datashow && <PatientTurn calledPatient={calledUser} />
                // datashow && <PatientTurn calledPatient={calledUser} />
                selectedValue !== "" && <PatientTurn calledPatient={calledUser} />
              }
            </center>
          </div>
        </>
      }
      <Fab color="primary" aria-label="change" style={{ position: "absolute", bottom: 0, left: 0, margin: "20px" }} onClick={() => setDatashow(false)}>
        <DataUsageIcon />
      </Fab>
    </>
  );
};

export default ActivePatientStatus;
