import dayjs from "dayjs";
import AppConstants from "../../utils/app-constants";
import { DoctorAPI } from "../../apis/DoctorAPI";

export const addAppointmentPayloadBuilder = (
  selectedDate,
  selectedTimeStringInHHmm,
  appointmentTypeSlotDuration,
  appointmentObj,
  currentUser
) => {
  return {
    startDateTime: `${selectedDate.format(
      AppConstants.DATE_FORMAT_FOR_API
    )} ${selectedTimeStringInHHmm}`,
    endDateTime: `${selectedDate.format("YYYY-MM-DD")} ${dayjs(
      selectedTimeStringInHHmm,
      "HH:mm"
    )
      .add(appointmentTypeSlotDuration, "minute")
      .format("HH:mm")}`,
    doctorSlug: appointmentObj.doctorSlug,
    patientSlug: appointmentObj.patientSlug,
    minutes: appointmentTypeSlotDuration,
    fees: appointmentObj?.fees,
    discount: appointmentObj.discount,
    creatorName: appointmentObj.creatorName,
    creatorRole: appointmentObj.creatorRole,
    creatorNumber: currentUser?.mobileNumber,
    appointmentTypeId: appointmentObj.appointmentTypeId,
    from: currentUser?.roleName,
    remark: appointmentObj.remarks,
    queue: 0,
    paymentMode: appointmentObj.mode,
    slotId: appointmentObj?.slotId,
    notifyPatient: false,
    isFreeChatActive: appointmentObj.isFreeChatActive,
    isFriends: appointmentObj.isFriends,
    isFamily: appointmentObj.isFamily,
  };
};

export const filterPastAppointmentTimeSlots = (selectedDate, timeSlots) => {
  const isToday = selectedDate.isSame(dayjs(), "day");
  return timeSlots?.filter((slot) => {
    if (!isToday) return true;

    return true;

    // TODO: SOMEBODY HELP ME!!!
  });
};

export const fetchAvailableSlotsOfADoctorOnAParticularDate = (
  doctorSlug,
  dateInYYYYMMDDFormat,
  appointmentTypeId
) => {
  return DoctorAPI.getAvailableSlotsOfADoctorAndAppointmentTypeAndDate(
    doctorSlug,
    dateInYYYYMMDDFormat,
    appointmentTypeId
  );
};

export const notifyAppointmentRescheduleFormBuilder = (appointmentSlug, oldDateStringInEpoch, oldTimeInHHmm, newDate, newTimeStringInHHmm) => {
  const formData = new FormData();
  formData.append("appointmentSlug", appointmentSlug);
  formData.append(
    "oldDate",
    `${dayjs(oldDateStringInEpoch).format(AppConstants.DATE_FORMAT_FOR_API)} ${oldTimeInHHmm}`
  );
  formData.append(
    "newDate",
    `${newDate.format(
      AppConstants.DATE_FORMAT_FOR_API
    )} ${newTimeStringInHHmm}`
  );
  return formData;
}
