import React, { useState, useEffect, useRef, useCallback } from "react";

import { useLocation } from "react-router-dom";
import { useGetAdmitInfo, useGetAdmitHistory } from "../../apis/AdmitAPI";

import { Box, makeStyles, IconButton, Tooltip } from "@material-ui/core";

import { Print } from "@material-ui/icons";
import Routes from "../../routes/routes";
import VisibilitySharp from "@material-ui/icons/VisibilitySharp";
import medicine from "../../assets/medicine.png";
import Loader from "../../components/misc/loader";
import { useReactToPrint } from "react-to-print";
import AuthService from "../../utils/services/auth-service";
import PatientInfoCard from "./ipd/PatientInfoCard";
import AdmitHistoryItem from "./ipd/AdmitHistoryItem";
import MedicineListDialog from "./ipd/MedicineListDialog";
import AdmitDataItem from "./ipd/AdmitDataItem";
import PrintComponent from "../../components/common/PrintComponent";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomActionButton from "../../components/common/CustomActionButton";
import AddIcon from '@mui/icons-material/Add';
import useDocumentTitle from "../../hooks/useDocumentTitle";

const useStyles = makeStyles((theme) => ({
  tab: {
    fontSize: "0.9rem",
    textTransform: "none",
  },
  icon: {
    margin: theme.spacing(0, 4),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    opacity: "0.8",
    padding: theme.spacing(4),
    "&:hover": {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      opacity: "0.6",
    },
    "&:disabled": {
      border: "1px solid rgba(0, 0, 0, 0.26)",
    },
  },
  icon_1: {
    margin: theme.spacing(0, 4),
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
    opacity: "0.8",
    padding: theme.spacing(4),
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.white,
      opacity: "0.6",
    },
    "&:disabled": {
      border: "1px solid rgba(0, 0, 0, 0.26)",
    },
  },
  fileContent: {
    flex: 1,
    fontSize: "10px",
    padding: "20px",
    backgroundColor: "white",
    borderRadius: "14px",
    overflowY: "auto",
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
    '@media print': {
      overflowY: 'visible',
      height: 'auto !important',
    },
  },
}));

const Folder = () => {
  // set document title
  useDocumentTitle("IPD File");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const [patientStatus, setPatientStatus] = useState();
  const [provisionalDiagnosis, setProvisionalDiagnosis] = useState();
  const [plan, setPlan] = useState();
  const [roomNumber, setRoomNumber] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [doctor, setDoctor] = useState();
  const [patientName, setPatientName] = useState();
  const [doctorName, setDoctorName] = useState();
  const [patientUhid, setPatientUhid] = useState();
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const {
    data: admitHistoryData,
    isLoading: isHistoryLoading,
    isFetching: isHistoryFetching,
  } = useGetAdmitHistory(id, page);
  const { data: admitData, isLoading } = useGetAdmitInfo(id);
  const [admitHistory, setAdmitHistory] = useState([]);
  const [medicineslist, setMedicationList] = useState([]);

  const classes = useStyles();

  const [warningOpen, setWarningOpen] = useState(false);

  const [checkedItems, setCheckedItems] = useState({});

  const [selectAll, setSelectAll] = useState(true);

  useEffect(() => {
    if (admitData && admitHistory.length > 0) {
      const allItems = [...admitHistory, { ...admitData, historyType: 'admitData', id: 'admitData' }];
      const newCheckedItems = {};
      allItems.forEach(item => {
        newCheckedItems[item.id] = true;
      });
      setCheckedItems(newCheckedItems);
    }
  }, [admitData, admitHistory]);

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    const allItems = [...admitHistory, { ...admitData, historyType: 'admitData', id: 'admitData' }];
    const newCheckedItems = {};
    allItems.forEach(item => {
      newCheckedItems[item.id] = isChecked;
    });
    setCheckedItems(newCheckedItems);
  };

  useEffect(() => {
    const allItems = [...admitHistory, { ...admitData, historyType: 'admitData', id: 'admitData' }];
    const allChecked = allItems.every(item => checkedItems[item.id]);
    setSelectAll(allChecked);
  }, [checkedItems, admitHistory, admitData]);

  const handleCheckboxChange = useCallback((id, isChecked) => {
    setCheckedItems(prevState => ({
      ...prevState,
      [id]: isChecked
    }));
  }, []);

  const handleScroll = (e) => {
    if (isHistoryFetching || !admitHistory || admitHistory.length === 0 || page >= totalPages - 1) {
      return;
    }
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (Math.floor(scrollTop) + clientHeight + 2 >= scrollHeight) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const onmedicineIconclicked = () => {
    setWarningOpen(true);
  };

  const handleClosee = () => {
    setWarningOpen(false);
  };


  useEffect(() => {
    if (admitData) {
      setDoctorName(admitData?.doctor?.name);
      setPatientName(admitData?.patient?.name);
      setPatientUhid(admitData?.patient?.uhid);
      setRoomNumber(admitData?.roomNumber);
      setProvisionalDiagnosis(admitData?.prov);
      setPlan(admitData?.plan);
      setPatientStatus(admitData?.status);
      setDoctor(admitData?.doctor);
      setMedicationList(admitData?.medications);
      setMobileNumber(admitData?.patient?.mobileNumber);
    }
  }, [admitData]);

  useEffect(() => {
    if (admitHistoryData) {
      setAdmitHistory((prevHistory) => [...prevHistory, ...admitHistoryData?.content]);
      setTotalPages(admitHistoryData?.totalPages);
    }
  }, [admitHistoryData]);

  useEffect(() => {
    setAdmitHistory([]);
    setPage(0);
    setTotalPages(1);
  }, [id]);

  const [rotate, setRotate] = useState(false);
  const [addtoggle, setaddtoggle] = useState(false);
  const addAppointmentItemstoggle = () => {
    setaddtoggle(!addtoggle);
    setRotate(!rotate);
    window.location.href = `#!` + Routes.PRESCRIPTION + `?id=${id}`;
  };

  const oneyebuttonClicked = () => {
    window.open(`#!` + Routes.FILELIST + `?id=${id}`, "_blank");
  };

  // printing functionality
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const PrintContent = () => {
    const allItems = [...admitHistory, { ...admitData, historyType: 'admitData', id: 'admitData', createdAt: admitData?.createdAt || new Date().toISOString() }];
    const checkedItemsList = allItems
      .filter(item => checkedItems[item.id])
      .sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));

    return (
      <div>
        <PatientInfoCard
          patientName={patientName}
          roomNumber={roomNumber}
          doctorName={doctorName}
          patientUhid={patientUhid}
          provisionalDiagnosis={provisionalDiagnosis}
          plan={plan}
          patientStatus={patientStatus}
          mobileNumber={mobileNumber}
        />
        <div>
          {checkedItemsList.map((item, index) => (
            <React.Fragment key={index}>
              {item.historyType === 'admitData' ? (
                <AdmitDataItem admitData={item} isPrinting={true}/>
              ) : (
                <AdmitHistoryItem item={item} fontSize="10px" isPrinting={true}/>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box
            flexGrow="1"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb="1.5rem"
          >
            <div
              style={{
                fontSize: "17px",
                padding: "22px 57px",
                fontFamily: "Comfortaa",
                fontWeight: "bold",
              }}
            >
              File
            </div>
            <div>
              <Tooltip title="Print">
                <span>
                  <IconButton onClick={handlePrint} className={classes.icon}>
                    <Print style={{ fontSize: "15px" }} />
                  </IconButton>
                </span>
              </Tooltip>
              {/* REMOVING FILE LOGS AS DIRECTED BY DOCTOR RAMAN */}
              {/* <Tooltip title="View Logs">
                <span>
                  <IconButton
                    onClick={oneyebuttonClicked}
                    className={classes.icon}
                  >
                    <VisibilitySharp style={{ fontSize: "15px" }} />
                  </IconButton>
                </span>
              </Tooltip> */}
              <Tooltip title="Medicine">
                <span>
                  <IconButton
                    className={classes.icon_1}
                    onClick={onmedicineIconclicked}
                  >
                    <img
                      src={medicine}
                      style={{
                        fontSize: "15px",
                        height: "1em",
                        width: "1em",
                      }}
                      alt=""
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </Box>
        </>
      )}

      <div className={classes.printStyles}>
        <div className="print-content" style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 100px)', padding: "20px", paddingTop: "0px" }}>
          <div className="second_part justify-content-between align-items-center ps-2">
            <PatientInfoCard
              patientName={patientName}
              roomNumber={roomNumber}
              doctorName={doctorName}
              patientUhid={patientUhid}
              provisionalDiagnosis={provisionalDiagnosis}
              plan={plan}
              patientStatus={patientStatus}
              mobileNumber={mobileNumber}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                  color="primary"
                />
              }
              label="Select All"
            />
          </div>
          <div
            className={`${classes.fileContent}`}
            onScroll={handleScroll}
          >
            {isHistoryLoading ? (
              <Loader />
            ) : (
              <>
                {[...admitHistory, { ...admitData, historyType: 'admitData', id: 'admitData', createdAt: admitData?.createdAt || new Date().toISOString() }]
                  .sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))
                  .map((item, index) => (
                    <React.Fragment key={index}>
                      {item.historyType === 'admitData' ? (
                        <AdmitDataItem 
                          admitData={item} 
                          isChecked={checkedItems[item.id] || false}
                          onCheckboxChange={handleCheckboxChange}
                        />
                      ) : (
                        <AdmitHistoryItem 
                          item={item} 
                          fontSize="10px" 
                          isChecked={checkedItems[item.id] || false}
                          onCheckboxChange={handleCheckboxChange}
                        />
                      )}
                    </React.Fragment>
                  ))}
                {isHistoryFetching && <Loader />}
              </>
            )}
          </div>
        </div>
      </div>

      {/* new section */}
      <div
        style={{
          margin: "2px",
          bottom: " 40px",
          position: "fixed",
          right: "26px",
        }}
      >
        <CustomActionButton
          onClick={addAppointmentItemstoggle}
          buttonTitle="Add EMR"
          icon={<AddIcon />}
        />
      </div>
      {/* medicines list */}
      <MedicineListDialog
        open={warningOpen}
        handleClose={handleClosee}
        medicinesList={medicineslist}
      />

      <PrintComponent Header={PrintHeader} Footer={PrintFooter} 
        Content={PrintContent} reference={componentRef} 
      />
    </>
  );
};

const PrintHeader = () => (
  <img src={AuthService.getConfig().ipdConfig?.receiptHeaderUrl} alt="Header" />
);

const PrintFooter = () => (
  <img src={AuthService.getConfig().ipdConfig?.receiptFooterUrl} alt="Footer" />
);

export default Folder;