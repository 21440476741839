import React from "react";
import PropTypes from "prop-types";
import { Box, Typography} from "@material-ui/core";

const CenteredTypography = ({ text, sx }) => {

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        textAlign: "center",
        ...sx,
      }}
    >
      <Typography variant="h6" component="div">
        {text}
      </Typography>
    </Box>
  );
};

CenteredTypography.propTypes = {
  text: PropTypes.string.isRequired,
  sx: PropTypes.object,
};

CenteredTypography.defaultProps = {
  sx: {},
};

export default CenteredTypography;
