import Api from "../utils/api";
import Api2 from "../utils/api2";
import { firstValueFrom } from "rxjs";

export const SettingsAPI = {
  getWaitingScreenSettingsInfo: async function () {
    try {
      const response = await firstValueFrom(
        Api2.get(`${process.env.REACT_APP_API2_HOST}get_que`)
      );
      return response.data.data.que;
    } catch (error) {
      console.error("Error fetching waiting screen settings info:", error);
    }
  },

  setWaitingScreenSettingsInfo: async function (requestBody) {
    try {
      const response = await firstValueFrom(
        Api2.post(`${process.env.REACT_APP_API2_HOST}add_que`, requestBody, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      );
      return response.data;
    } catch (error) {
      console.error("Error setting waiting screen settings: ", error);
    }
  },

  getHospitalConfig: async function () {
    try {
      const response = await firstValueFrom(Api.get(`/config`));
      return response.data?.data;
    } catch (error) {
      console.error("Error fetching hospital config: ", error);
    }
  },

  setIPDReceiptHeaderOrFooterImage: async function (formData) {
    try {
      const response = await firstValueFrom(
        Api.put(`/config/ipd-receipt`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      );
      return response;
    } catch (error) {
      console.error("Error setting IPD receipt header/footer: ", error);
      return error?.response;
    }
  },
};
