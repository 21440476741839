import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import { ArrowBack, ArrowDropDown } from "@mui/icons-material";
import PropTypes from "prop-types";
import SidebarContent from "../../screens/chat/sidebar-content";
import CustomActionButton from "../common/CustomActionButton";

const ChatScreenHeader = ({
  patient,
  totalQuestions,
  totalDays,
  setShowChatScreen,
  toggleDialog,
  showResolveButton,
  handleAddQuestion,
}) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  useEffect(() => {
    setIsSidebarVisible(false);
  }, [patient]);

  const handleGoBack = () => {
    setShowChatScreen(false);
  };

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <Box>
      <Box
        sx={{
          py: "15px",
          px: { md: "20px", xs: "10px" },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          backgroundColor: "#e3e4e6",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { md: "10px", xs: "3px" },
          }}
        >
          {setShowChatScreen && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleGoBack}
            >
              <ArrowBack />
            </IconButton>
          )}

          <Typography
            sx={{
              fontSize: { md: "16px", xs: "13px" },
              fontWeight: 500,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: { md: "300px", xs: "150px" },
            }}
          >
            {patient?.patientDetails?.name}
          </Typography>
          {showResolveButton && (
            <CustomActionButton
              color="primary"
              buttonTitle="Resolve"
              onClick={toggleDialog}
              variant="outlined"
              size="small"
              sx={{
                color: "#fff",
                borderRadius: "10px",
                fontFamily: "inherit",
                textTransform: "none",
                fontSize: { md: "12px", xs: "10px" },
                padding: { md: "6px 16px", xs: "4px 8px" },
                minWidth: { md: "auto", xs: "60px" },
                height: { md: "32px", xs: "24px" },
              }}
            />
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { md: "15px", xs: "10px" },
          }}
        >
          <CustomActionButton
            color="primary"
            buttonTitle="Add Question"
            onClick={handleAddQuestion}
            variant="outlined"
            size="small"
            sx={{
              color: "#fff",
              borderRadius: "10px",
              fontFamily: "inherit",
              textTransform: "none",
              fontSize: { md: "12px", xs: "10px" },
              padding: { md: "6px 16px", xs: "4px 8px" },
              minWidth: { md: "auto", xs: "85px" },
              height: { md: "32px", xs: "24px" },
              whiteSpace: "nowrap",
            }}
          />
          <Box
            sx={{
              textAlign: "right",
            }}
          >
            <Typography
              sx={{
                fontSize: { md: "13px", xs: "11px" },
                fontWeight: 500,
              }}
            >
              Questions Remaining: {totalQuestions}
            </Typography>
            <Typography
              sx={{
                fontSize: { md: "11px", xs: "11px" },
                fontWeight: 500,
              }}
            >
              Total Days: {totalDays}
            </Typography>
          </Box>
          <Tooltip title="Show Patient Details">
            <IconButton sx={{ p: 0, m: 0 }} onClick={toggleSidebar}>
              <ArrowDropDown
                sx={{
                  transform: isSidebarVisible
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                  transition: "transform 0.3s ease",
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      {isSidebarVisible && (
        <Box
          sx={{
            borderTop: "1px solid #ccc",
            padding: "15px",
            backgroundColor: "#f9f9f9",
          }}
        >
          <SidebarContent data={patient} />
        </Box>
      )}
    </Box>
  );
};

ChatScreenHeader.propTypes = {
  patient: PropTypes.object,
  totalQuestions: PropTypes.number,
  totalDays: PropTypes.number,
  setShowChatScreen: PropTypes.func,
  toggleDialog: PropTypes.func,
  showResolveButton: PropTypes.bool,
  handleAddQuestion: PropTypes.func,
};

export default ChatScreenHeader;
